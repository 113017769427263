import { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import AppUserContext from "../../context/app-user-context";
import { motion } from "framer-motion";
import Utils from "../../utils/utils";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";

export default function DefaultPasswordCheck() {
  const user = useContext(AppUserContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (user.isDefaultPassword && !user.isAdfsUser) {
      Utils.sleep(1000).then(() => {
        setShow(true);
      });
    } else {
      setShow(false);
    }
  }, [user]);

  return <>
    {show && <div className="cp-info">
      <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}>
        <Alert variant="warning" onClose={() => setShow(false)} dismissible>
          <p className="text-center">
            You are still using default password. <Link to={ROUTES.PORTAL_PROFILE_CHANGE_PASSWORD}>Please change your password here</Link>
            {" "} and make sure to use strong password.
          </p>
        </Alert>
      </motion.div>
    </div>}
  </>;
}
