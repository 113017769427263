import {
  HiOutlineDocumentDuplicate,
  HiOutlineUpload,
  HiOutlineX,
} from "react-icons/hi";
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import { ClipLoader } from "react-spinners";
import ApiUser from "../../modules/api/user";
import { Alert, Modal } from "react-bootstrap";
import Notifier from "../../modules/notifier/notifier";
import Debouncer from "../../modules/debouncer/debouncer";

export type TPopupImportMembersRef = {
  show: () => void;
};

export type TPopupImportMembersProps = {
  groupId: string;
  title?: string;
  labelOK?: string;
  backdrop?: boolean;
  keyboard?: boolean;
  onClose?: () => void;
  onOK?: () => void;
  onImport?: () => void;
};

const PopupImportMembers = forwardRef<
  TPopupImportMembersRef,
  TPopupImportMembersProps
>((props, ref) => {
  const fileRef = useRef<HTMLInputElement>();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [sending, setSending] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    if (!results.length) {
      return;
    }
    if (results.some((t) => t.success)) {
      props.onImport();
    }
  }, [results]);

  useEffect(() => {
    setResults([]);
  }, [uploadedFile]);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
  }));

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];

    if (validateFileType(file)) {
      setError("");
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError("Invalid file type. Please upload a CSV file.");
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (validateFileType(file)) {
      setError("");
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError("Invalid file type. Please upload a CSV file.");
    }
  };

  const handleUploadAreaClick = () => {
    resetFileValue(false);
    document.getElementById("member-form-upload-file-input")?.click();
  };

  const handleRemoveFile = () => {
    resetFileValue();
    setUploadedFile(null);
    setError("");
  };

  const handleSubmit = () => {
    Debouncer.execute("IMPORT_MEMBERS", async () => {
      setSending(true);
      setResults([]);
      await ApiUser.importByCsv(uploadedFile, props.groupId)
        .then(({ success, results, message }) =>
          success ? setResults(results) : setError(message)
        )
        .catch((error) => {
          Notifier.error(error.message);
        });
      setSending(false);
    });
  };

  const validateFileType = (file: File) => {
    const allowedTypes = ["text/csv"];
    return allowedTypes.includes(file.type);
  };

  const resetFileValue = (resetUploadedFile = true) => {
    try {
      fileRef.current.value = "";
      if (resetUploadedFile) {
        setUploadedFile(null);
      }
    } catch (ignore) {}
  };

  const onHide = () => {
    setShow(false);
    handleRemoveFile();
    props.onClose();
  };

  return (
    <Modal
      className="modal-generic modal-import"
      show={show}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title ?? "Confirmation"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <a
            href="/assets/sample/sample-user-for-program-admin.csv?t=20230529"
            target="_blank"
          >
            Click here to download the sample (
            <strong>sample-user-for-program-admin.csv</strong>)
          </a>
          .
        </p>
        <div
          className={`import-upload-area ${
            isDragging ? "dragging" : ""
          } border rounded col-12 text-center`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleUploadAreaClick}
        >
          <HiOutlineUpload className="upload-icon mb-3 text-secondary" />
          <p className="mb-1 fs-5">Drag & Drop or Choose file to upload</p>
          <p className="text-secondary fs-5">JSON file</p>
          <input
            ref={fileRef}
            type="file"
            accept=".csv"
            onChange={handleFileInputChange}
            id="member-form-upload-file-input"
            className="d-none"
          />
        </div>
        {error && error !== "" && (
          <div className="alert alert-danger mt-3 mb-0" role="alert">
            {error}
          </div>
        )}
        {uploadedFile && (
          <div className="uploaded-file-container mt-3 p-2 rounded">
            <div className="uploaded-file d-flex justify-content-between align-items-center p-2 bg-white rounded">
              <p className="mb-0">
                <span>
                  <HiOutlineDocumentDuplicate className="fs-4 me-2" />
                </span>
                {uploadedFile.name}
              </p>
              <HiOutlineX className="cancel-icon" onClick={handleRemoveFile} />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-mw btn-primary"
          onClick={handleSubmit}
          disabled={!uploadedFile || sending}
        >
          <ClipLoader color="#FFFFFF" size={16} loading={sending} />
          {!sending && (props.labelOK ?? "OK")}
        </button>
        {results.length > 0 && (
          <Alert
            variant="info"
            onClose={() => setResults([])}
            dismissible
            className="modal-import-status import-csv-email-notify-status"
          >
            <h4>Email Notification Status</h4>
            <p>
              Below is the list of notifications of each imported user(s).
              Failed means system was unable to create/update or notify the user
              via email.
            </p>
            {results.map((item, index) => (
              <Alert
                key={`email-notify-${index}`}
                variant={item.success ? "success" : "danger"}
              >
                <strong>Row {`#${index}`}</strong> ({item.email}):
                {item.messages?.length > 0 && (
                  <ul className="mb-0 import-csv-email-notify-messages">
                    {item.messages.map((message, i) => (
                      <li key={`notify-message-${i}`}>{message}</li>
                    ))}
                  </ul>
                )}
              </Alert>
            ))}
          </Alert>
        )}
      </Modal.Footer>
    </Modal>
  );
});

PopupImportMembers.defaultProps = {
  title: "Import Member(s)",
  backdrop: true,
  keyboard: false,
  onClose: () => {},
  onOK: () => {},
  onImport: () => {},
};

export default PopupImportMembers;
