import { HiArrowLeft, HiExclamationCircle } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom'
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import FGRichTextEditor from '../../../components/common/FGRichTextEditor';
import { ROUTES } from '../../../routes';
import { useCallback, useContext, useEffect, useState } from 'react';
import ApiSubmissionDate from '../../../modules/api/submission-date';
import { SUBMISSION_STATUS_DRAFT, SUBMISSION_STATUS_PUBLISH, SUBMISSION_WL } from '../../../config/constants';
import Debouncer from '../../../modules/debouncer/debouncer';
import ModuleValidation from '../../../modules/validation';
import Notifier from '../../../modules/notifier/notifier';
import AppUserContext from '../../../context/app-user-context';
import ApiSubmission from '../../../modules/api/submission';
import ModulePopup from '../../../modules/popup/popup';
import Utils from '../../../utils/utils';
import FGDropdownApi from '../../../components/form/FGDropdownApi';

export default function PageReportWL() {
  const title = "Workplace Learning Report";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [dateList, setDateList] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    submissionDateId: "",
    summaryKeyActivities: "",
    detailsLearning: "",
    reflection: ""
  });

  const handleFormChange = useCallback((e) => {
    setFormData(f => ({
      ...f,
      [e.target.name]: e.target.value
    }));
  }, []);

  const handleFormSubmit = (submissionStatus: string) => {
    if (submitting) return;
    setSubmitting(true);

    Debouncer.execute("SUBMIT_WL", async () => {
      const validation = ModuleValidation.isSubmissionWLValid(formData);

      if (!validation.valid) {
        setSubmitting(false);
        return ModulePopup.showAlert({
          title: <><HiExclamationCircle /> Complete your form</>,
          description: validation.message
        });
      }

      const result = await ApiSubmission.wlAdd({
        createdUserId: user.id,
        status: submissionStatus,
        studentId: user.referenceUserId,
        ...formData
      });

      if (result.status) {
        Notifier.success(result.message);
        await Utils.sleep(2000);

        if (submissionStatus === SUBMISSION_STATUS_DRAFT) {
          navigate(ROUTES.PORTAL_REPORT_WL + `/edit/${result.data._id}`);
        } else {
          navigate(ROUTES.PORTAL_REPORT);
        }
      } else {
        Notifier.error(result.message);
      }

      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (!user.activeCohort._id || !user.activeCohort._id.trim()) return;

    ApiSubmissionDate.getSubmissionDateListForSubmission(user.activeCohort._id, SUBMISSION_WL).then(async dateList => {
      setDateList(dateList);
      dateList.some((() => {
        const today = new Date().getTime();
        return (date => {
          const matchesToday = today >= date.fromMs && today <= date.toMs;
          if (matchesToday) setFormData(f => ({ ...f, submissionDateId: date.value }));
          return matchesToday;
        });
      })());
      setLoading(false);
    });
  }, []);

  return <main className="content-generic content-generic--fluid" data-comp='PageReportWL'>
    <div className='d-flex gap-2 hide-mobile-down'>
      <h1 className='fs-title'>
        <Link to={ROUTES.PORTAL_REPORT} className="text-primary p-0 me-2" title="Go back">
          <HiArrowLeft />
        </Link>
        {title}
      </h1>
    </div>

    {loading && <p className="alert alert-info">
      Loading. Please wait...
    </p>}

    {!loading && <form onSubmit={(e) => e.preventDefault()}>
      <FGDropdownApi
        label="Select submission date"
        name="submissionDateId"
        variant="normal"
        options={dateList}
        defaultValue={formData.submissionDateId}
        onChange={handleFormChange}
      />

      {!loading && formData.submissionDateId === "" && <p className="alert alert-warning">
        Please select the submission date.
      </p>}

      {!loading && formData.submissionDateId !== "" && <>
        <div className='form-report mt-3'>
          <FGRichTextEditor
            variant="normal"
            label={<strong>Summary of Key Activities</strong>}
            name="summaryKeyActivities"
            onChange={handleFormChange}
          />

          <hr />

          <FGRichTextEditor
            variant="normal"
            label={<strong>Details of Learning</strong>}
            name="detailsLearning"
            onChange={handleFormChange}
          />

          <hr />

          <FGRichTextEditor
            variant="normal"
            label={<strong>Reflection</strong>}
            name="reflection"
            onChange={handleFormChange}
          />
        </div>

        <div className='my-3'>
          <button type="button" className='text-uppercase mt-1 btn btn-primary btn-mw-centered'
            disabled={submitting}
            onClick={(e) => {
              e.preventDefault();
              ModulePopup.showAlertConfirm({
                title: "Confirmation",
                description: <>
                  <p>Are you sure to submit the report?</p>
                </>,
                onYes() {
                  handleFormSubmit(SUBMISSION_STATUS_PUBLISH);
                }
              });
            }}
          >Submit Report</button> {" "}
          <button type="button" className='text-uppercase mt-1 btn btn-outline-primary btn-mw-centered'
            disabled={submitting}
            onClick={(e) => {
              e.preventDefault();
              handleFormSubmit(SUBMISSION_STATUS_DRAFT);
            }}
          >Save as Draft</button>
        </div>
      </>}

    </form>}
  </main>;
}
