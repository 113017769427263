import { useEffect, useRef, useState, useContext } from "react";
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";
import { Link, useNavigate, useParams } from "react-router-dom";
import Debouncer from "../../../modules/debouncer/debouncer";
import ApiUser from "../../../modules/api/user";
import Notifier from "../../../modules/notifier/notifier";
import ROLE from "../../../modules/role";
import { ROUTES } from "../../../routes";
import FGInput from "../../../components/dumb/FGInput";
import FGTextarea from "../../../components/dumb/FGTextarea";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import {
  CONST_SELECT_ROLES,
  CONST_SELECT_USER_STATUS,
  DEFAULT_SELECT_OPTION,
  USER_STATUS_ACTIVE,
} from "../../../config/constants";
import ApiGenericData from "../../../modules/api/generic-data";
import AppUserContext from "../../../context/app-user-context";
import { HiArrowLeft } from "react-icons/hi";
import Multilingual from "../../../modules/multilingual/multilingual";
import ApiProgramme from "../../../modules/api/programme";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";

const UPDATED_FIELDS = [
  "name",
  "email",
  "role",
  "organization",
  "jobDesk",
  "studentId",
  "programme",
  "status",
  "managedProgrammeNames",
] as const;
const FIELDS: { [key in (typeof UPDATED_FIELDS)[number]]: string } =
  UPDATED_FIELDS.reduce((curr, item) => ({ ...curr, [item]: "" }), {}) as any;

export default function PageUserEdit() {
  const title = "Edit User";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const user = useContext(AppUserContext);
  const navigate = useNavigate();
  const { _id } = useParams();
  const refDataLoaded = useRef(false);
  const [show, setShow] = useState(false);
  const [contentChanged, setContentChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({ ...FIELDS });
  const [groupHash, setGroupHash] = useState({});

  const onChange = (e: any) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((data) => ({ ...data, [e.target.name]: value }));
    if (refDataLoaded.current) {
      setContentChanged(true);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_UPDATE_USER", async () => {
      setSending(true);
      const res = await ApiUser.update(
        _id,
        Object.keys(formData)
          .filter((i) => UPDATED_FIELDS.some((j) => j === i))
          .map((i) => ({
            key: i,
            value: formData[i],
          }))
          .reduce((curr, item) => ({ ...curr, [item.key]: item.value }), {})
      );

      if (res.status) {
        Notifier.success(res.message);
        setSending(false);
        setContentChanged(false);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };

  const roleOptions = () => {
    let roles = CONST_SELECT_ROLES;
    if (user.role === ROLE.ADMIN) {
      return roles.filter((role) =>
        [ROLE.ADMIN, ROLE.PROGRAM_ADMIN].some((value) => value === role.value)
      );
    } else {
      return roles.filter((role) =>
        [ROLE.STUDENT, ROLE.SUPERVISOR, ROLE.INDUSTRY_SUPERVISOR].some(
          (value) => value === role.value
        )
      );
    }
  };

  const checkStatusValue = (obj: any) => {
    return {
      ...obj,
      status: obj.status ?? USER_STATUS_ACTIVE,
    };
  };

  const getUserGroups = () => {
    const groupIds = Object.keys(
      typeof groupHash === "object" ? groupHash : {}
    );
    return groupIds.map((groupId) => groupHash[groupId]);
  };

  useEffect(() => {
    setLoading(true);
    ApiUser.get(_id).then((res) => {
      if (res.status) {
        setFound(true);
        setFormData(checkStatusValue(res.data));
        setGroupHash(
          typeof res.data.groupHash === "object" ? res.data.groupHash : {}
        );
        setTimeout(() => {
          refDataLoaded.current = true;
        }, 250);
      } else {
        setFound(false);
      }

      setLoading(false);
    });
  }, []);

  return (
    <div data-comp="PageUserRegist">
      <h2 className="fs-title mb-3 hide-mobile-down">{title}</h2>

      <div className="cg__actions hide-mobile-down">
        <Link
          to={ROUTES.PORTAL_USERS + `?role=${formData.role}`}
          className="btn btn-primary"
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      {loading && <p className="alert alert-info">Loading data...</p>}

      {!loading && !found && (
        <p className="alert alert-info">User not found.</p>
      )}

      {!loading && found && (
        <Modal
          className="modal-generic"
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assigned Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataTable
              data={getUserGroups()}
              columns={[
                {
                  id: "programme",
                  name: "Programme",
                  cell: (row: any) => row.cohort?.programme?.name ?? ``,
                },
                {
                  id: "cohort",
                  name: "Cohort",
                  cell: (row: any) => row.cohort?.name ?? ``,
                },
                {
                  id: "group",
                  name: "Group",
                  cell: (row: any) => row.name,
                },
              ]}
            ></DataTable>
          </Modal.Body>
        </Modal>
      )}

      {!loading && found && (
        <form className="form-register" onSubmit={handleSubmit}>
          <div className="row" data-comp="PageuserRegistManual">
            <div className="col-md-6">
              <FGInput
                label="Name"
                name="name"
                value={formData.name}
                onChange={onChange}
                required
                autoFocus
              />
            </div>
            <div className="col-md-6">
              <FGInput
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={onChange}
                required
              />
            </div>
            {user.role !== ROLE.ADMIN && (
              <div className="col-md-6">
                <FGInput
                  label="Student ID"
                  name="studentId"
                  value={formData.studentId}
                  onChange={onChange}
                />
              </div>
            )}
            <div className="col-md-6">
              <FGInput
                label="Job title"
                name="jobDesk"
                value={formData.jobDesk}
                onChange={onChange}
              />
            </div>
            <div className="col-md-6">
              <FGDropdownApi
                label="Role"
                name="role"
                options={roleOptions()}
                defaultValue={formData.role}
                onChange={onChange}
              />
            </div>
            {user.role !== ROLE.ADMIN && (
              <div className="col-md-6">
                <FGDropdownApi
                  label="Organization"
                  name="organization"
                  defaultValue={formData.organization}
                  api={ApiGenericData.selectQuery(
                    "Organization",
                    "organization",
                    {
                      ...DEFAULT_SELECT_OPTION,
                    }
                  )}
                  onChange={onChange}
                />
              </div>
            )}
            <div className="col-md-6">
              <FGDropdownApi
                label="Programme"
                name="programme"
                defaultValue={formData.programme}
                api={ApiProgramme.getOptions}
                onChange={onChange}
              />
            </div>
            {found && formData.role === ROLE.PROGRAM_ADMIN && (
              <div className="col-md-6">
                <FGTextarea
                  label="Managed Programme(s)"
                  rows={2}
                  readOnly
                  value={formData.managedProgrammeNames}
                />
              </div>
            )}
            <hr />
            <div className="col-md-12">
              <FGDropdownApi
                variant="normal"
                label="Status"
                desc={`Set to "Inactive" if we'd like to disable this user.`}
                name="status"
                defaultValue={formData.status}
                options={CONST_SELECT_USER_STATUS}
                onChange={onChange}
              />
            </div>
            <hr />
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShow((_) => !_)}
              >
                View Assigned Group
              </button>
            </div>
            <hr />
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={sending}
            >
              Submit Data
            </button>{" "}
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault();
                if (window.history.length === 1) {
                  navigate(ROUTES.PORTAL_USERS + `?role=${formData.role}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
