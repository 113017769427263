export type TParsedTraitConfig = {
  description: string;
  // color for the chart
  color: string;
  // scale 1-10
  scales: Array<{
    level: number;
    color: string;
    title: string;
    description: string;
  }>;
  // parsed description for popup (possibly merging same title+description)
  legends: Array<{
    color: string;
    title: string;
    description: string;
  }>;
};

const sampleTextConfig = JSON.stringify({
  "description": "Trait's description",
  "color": "#FC8F00",
  "scales": {
    "1-4": {
      "color": "#F04C50",
      "title": "Beginner",
      "description": `Demonstrated early developments of professional traits, with some inconsistencies in application, and sometimes lacking in confidence.`
    },
    "5-7": {
      "color": "#FDD500",
      "title": "Competent",
      "description": `Demonstrated increasing competence in professional traits with steady confidence and consistent accomplishments, with the ability to perform and adapt to most situations.`
    },
    "8-10": {
      "color": "#6CE74E",
      "title": "Proficient",
      "description": `Demonstrate consistent and outstanding competency in professional traits at work. Highly adaptable whilst maintaining high standards with ease.   A person others look up to as an exemplary leader.`
    }
  }
});

const defaultConfig = parse(sampleTextConfig);

/**
 * Scale of trait is exactly 1 to 10.
 * If the `config` is empty, then we fallback to default trait config.
 */
function parse(config: string = "") {
  let parsedConfig;

  try {
    parsedConfig = JSON.parse(config);

    if (!parsedConfig.scales) {
      throw new Error("");
    }
  } catch (err) {
    return defaultConfig;
  }

  const scales = parsedConfig.scales;
  const parsed: TParsedTraitConfig = {
    color: parsedConfig.color ?? "#FC8F00",
    description: parsedConfig.description ?? "",
    scales: [],
    legends: []
  };
  const rScale = /^\d+$/;
  const rScaleRange = /^\d+\-\d+$/;

  Object.keys(scales).forEach(scale => {
    const value = scales[scale];

    if (rScale.test(scale)) {
      // just "push" it
      parsed.scales.push({
        level: parseInt(scale),
        ...value
      });
    } else if (rScaleRange.test(scale)) {
      // loop over it
      const split = scale.split("-");
      let from = parseInt(split[0]);
      let to = parseInt(split[1]);

      for (; from <= to; from += 1) {
        parsed.scales.push({
          level: from,
          ...value
        });
      }
    }
  });

  // sort `parsed.scales` first based on level's order
  parsed.scales.sort((a, b) => {
    return a["level"] - b["level"];
  });

  // then loop thru `parsed.scales` to construct the legends
  let lastIndex = parsed.scales.length - 1;
  parsed.scales.forEach((item, currIndex) => {
    const nextIndex = currIndex + 1;
    const currItem = parsed.scales[currIndex];
    const nextItem = parsed.scales[nextIndex];

    if (nextIndex <= lastIndex) {
      if (
        currItem.title === nextItem.title &&
        currItem.description === nextItem.description
      ) {
        // skip it
      } else {
        // else push it
        parsed.legends.push(currItem);
      }
    }

    if (nextIndex > lastIndex && currIndex === lastIndex) {
      parsed.legends.push(currItem);
    }
  });

  return parsed;
}

function isJSONConfigCorrect(jsonConfig: string) {
  const res = {
    correct: false,
    message: "The Trait Configuration is incorrect, please check again the configuration."
  };

  try {
    const parsed = JSON.parse(jsonConfig);

    if (!$.isPlainObject(parsed)) {
      throw new Error();
    }

    if (!(typeof parsed.color === "string" && parsed.color.trim() !== "")) {
      res.message = "Trait Color is required.";
    }

    if (
      typeof parsed.color === "string" && parsed.color.trim() !== "" &&
      typeof parsed.description === "string" &&
      $.isPlainObject(parsed.scales)
    ) {
      const scales = parsed.scales;
      let allCorrect = true;
      let scalesNum = [];

      Object.keys(scales).forEach(scale => {
        var scaleInfo = scales[scale];

        if (!(typeof scaleInfo.color === "string" && scaleInfo.color.trim() !== "")) {
          allCorrect = false;
          res.message = "Scale Color is required.";
        }

        if (!(typeof scaleInfo.title === "string" && scaleInfo.title.trim() !== "")) {
          allCorrect = false;
          res.message = "Scale Name is required.";
        }

        if (/\d{1,2}(?:-\d{1,2})/.test(scale)) {
          var arrScale = scale.split("-");
          var from = parseInt(arrScale[0]);
          var to = parseInt(arrScale[1]);

          if (
            typeof from === "number" && from > 0 &&
            typeof to === "number" && to > 0
          ) {
            if (from === to) {
              scalesNum.push(from);
            } else {
              for (; from <= to; from += 1) {
                scalesNum.push(from);
              }
            }
          }
        }
      });

      if (allCorrect) {
        // range-checker from 1 to 10, any number fails, trait is incorrect
        for (let i = 1, j = 10; i <= j; i += 1) {
          if (scalesNum.indexOf(i) === -1) {
            res.correct = false;
            allCorrect = false;
            res.message = `Make sure the scales from 1 to 10 are set.`;
            break;
          }
        }
      }

      if (allCorrect) {
        res.correct = true;
        res.message = "Trait Configuration is correct.";
      }
    }
  } catch (ignore) {}

  return res;
}

const TraitConfig = {
  parse,
  defaultConfig,
  sampleTextConfig,
  isJSONConfigCorrect
};

export default TraitConfig;
