import { MdSave } from "react-icons/md";
import { ROUTES } from "../../../routes";
import Utils from "../../../utils/utils";
import { useState, useContext } from "react";
import { HiArrowLeft, HiX } from "react-icons/hi";
import ApiCohort from "../../../modules/api/cohort";
import FGField from "../../../components/dumb/FGField";
import FGInput from "../../../components/dumb/FGInput";
import FGToggle from "../../../components/dumb/FGToggle";
import usePageTitle from "../../../hooks/use-page-title";
import Notifier from "../../../modules/notifier/notifier";
import DateRange from "../../../components/form/DateRange";
import FGTextarea from "../../../components/dumb/FGTextarea";
import Debouncer from "../../../modules/debouncer/debouncer";
import AppUserContext from "../../../context/app-user-context";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";

export default function PageCohortAdd() {
  const title = "Add New Cohort";
  const navigate = useNavigate();
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const user = useContext(AppUserContext);
  const { programmeId } = useParams();
  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    startDate: null,
    endDate: null,
    description: "",
    singleGroupMode: true,
    programme: programmeId,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const handleOnChange = (e: any) => {
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]:
        e.target.name === "singleGroupMode" ? e.target.checked : e.target.value,
    }));
  };

  const validateBeforeSubmit = () => {
    const errors = [];
    if (!formData.startDate || !formData.endDate) {
      errors.push("Start/End dates are required");
    } else {
      if (formData.endDate <= formData.startDate) {
        errors.push("End date must be greater than start date");
      }
    }
    return errors;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validateBeforeSubmit();
    if (errors.length > 0) {
      errors.forEach((e) => Notifier.error(e));
      return;
    }
    Debouncer.execute("SUBMIT_DATA", async () => {
      setSending(true);
      const payload = {
        ...formData,
        startDate: formData.startDate.getTime(),
        endDate: formData.endDate.getTime(),
      };
      await ApiCohort.create(payload)
        .then(async (res) => {
          setSending(false);
          if (res.result === "OK") {
            Notifier.success(res.message);
            await Utils.sleep(2000);
            navigate(`${ROUTES.PORTAL_COHORTS}/${programmeId}`);
          } else {
            Notifier.error(res.message);
          }
        })
        .catch((error) => {
          setSending(false);
          Notifier.error(error.message);
        });
    });
  };

  if (!user.programmeIds.includes(programmeId)) {
    return <PagePortalNotFound />;
  }
  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions hide-mobile-down">
        <Link
          to={`${ROUTES.PORTAL_COHORTS}/${programmeId}`}
          className="btn btn-primary"
          state={location.state}
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      <form onSubmit={handleSubmit}>
        <FGInput
          name="name"
          id="name"
          type="text"
          label="Name*"
          required
          autoFocus
          onChange={handleOnChange}
        />

        <FGField variant="normal">
          <DateRange
            title="Select Date*"
            onStartEndDateSelected={(start, end) => {
              setContentChanged(true);
              end.setHours(23, 59, 59, 999);
              setFormData((formData) => ({
                ...formData,
                startDate: start,
                endDate: end,
              }));
            }}
          />
        </FGField>

        <FGTextarea
          name="description"
          id="description"
          label="Description"
          onChange={handleOnChange}
        ></FGTextarea>

        <FGToggle
          label="Single Group Mode"
          sublabel='When enabled, this cohort will have single group named "Default"'
          name="singleGroupMode"
          onChange={handleOnChange}
          checked={formData.singleGroupMode}
        ></FGToggle>

        <div>
          <button type="submit" className="btn btn-success" disabled={sending}>
            <MdSave /> Add
          </button>{" "}
          <Link
            to={`${ROUTES.PORTAL_COHORTS}/${programmeId}`}
            className="btn btn-primary"
            state={location.state}
            onClick={(e) => {
              if (
                contentChanged &&
                !window.confirm(
                  Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                )
              ) {
                e.preventDefault();
              }
            }}
          >
            <HiX /> Cancel
          </Link>
        </div>
      </form>
    </main>
  );
}
