import { MdSave } from "react-icons/md";
import { ROUTES } from "../../../routes";
import { HiArrowLeft, HiX } from "react-icons/hi";
import ApiCohort from "../../../modules/api/cohort";
import FGField from "../../../components/dumb/FGField";
import FGInput from "../../../components/dumb/FGInput";
import { useEffect, useState, useContext } from "react";
import usePageTitle from "../../../hooks/use-page-title";
import FGToggle from "../../../components/dumb/FGToggle";
import Notifier from "../../../modules/notifier/notifier";
import DateRange from "../../../components/form/DateRange";
import Debouncer from "../../../modules/debouncer/debouncer";
import FGTextarea from "../../../components/dumb/FGTextarea";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import AppUserContext from "../../../context/app-user-context";
import { Link, useParams, useLocation } from "react-router-dom";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";

export default function PageCohortEdit() {
  const title = "Edit Cohort";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const user = useContext(AppUserContext);
  const { programmeId, _id } = useParams();
  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    startDate: null,
    endDate: null,
    description: "",
    singleGroupMode: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const isValidProgrammeId = user.programmeIds.includes(programmeId);

  useEffect(() => {
    if (!isValidProgrammeId) {
      return;
    }
    ApiCohort.get(_id)
      .then((res) => {
        setLoading(false);
        if (res.result === "OK") {
          if (res.data.programme === programmeId) {
            setFound(true);
            setFormData((formData) => ({
              ...formData,
              name: res.data.name,
              startDate: new Date(res.data.startDate),
              endDate: new Date(res.data.endDate),
              description: res.data.description,
              singleGroupMode: res.data.singleGroupMode,
            }));
          }
        } else {
          Notifier.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifier.error(error.message);
      });
  }, []);

  const handleOnChange = (e: any) => {
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]:
        e.target.name === "singleGroupMode" ? e.target.checked : e.target.value,
    }));
  };

  const validateBeforeSubmit = () => {
    const errors = [];
    if (formData.endDate <= formData.startDate) {
      errors.push("End date must be greater than start date");
    }
    return errors;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const errors = validateBeforeSubmit();
    if (errors.length > 0) {
      errors.forEach((e) => Notifier.error(e));
      return;
    }
    const payload = {
      ...formData,
      startDate: formData.startDate.getTime(),
      endDate: formData.endDate.getTime(),
    };
    Debouncer.execute("UPDATE_DATA", async () => {
      setSending(true);
      ApiCohort.update(_id, payload)
        .then((res) => {
          setSending(false);
          if (res.result === "OK") {
            setContentChanged(false);
            Notifier.success(res.message);
          } else {
            Notifier.error(res.message);
          }
        })
        .catch((error) => {
          setSending(false);
          Notifier.error(error.message);
        });
    });
  };

  if (!isValidProgrammeId) {
    return <PagePortalNotFound />;
  }
  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions hide-mobile-down">
        <Link
          to={`${ROUTES.PORTAL_COHORTS}/${programmeId}`}
          className="btn btn-primary"
          state={location.state}
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      {loading && <p className="alert alert-info">Loading data...</p>}

      {!loading && !found && (
        <p className="alert alert-info">Cohort not found.</p>
      )}

      {!loading && found && (
        <form onSubmit={handleSubmit}>
          <div
            className="alert alert-warning"
            style={{ marginBottom: `1.5rem` }}
          >
            <BsFillExclamationTriangleFill
              size={20}
              color="#FF991F"
              style={{ marginRight: 7 }}
            />{" "}
            Updating Cohort Start and End Date after it’s being used by Students
            can make unintended behaviour. Please be careful.
          </div>
          <FGInput
            name="name"
            id="name"
            type="text"
            label="Name*"
            required
            autoFocus
            value={formData.name}
            onChange={handleOnChange}
          />

          <FGField variant="normal">
            <DateRange
              title="Select Date*"
              start_date={formData.startDate}
              end_date={formData.endDate}
              onStartEndDateSelected={(start, end) => {
                if (start !== formData.startDate || end !== formData.endDate) {
                  setContentChanged(true);
                }
                end.setHours(23, 59, 59, 999);
                setFormData((formData) => ({
                  ...formData,
                  startDate: start,
                  endDate: end,
                }));
              }}
            />
          </FGField>

          <FGTextarea
            name="description"
            id="description"
            label="Description"
            value={formData.description}
            onChange={handleOnChange}
          ></FGTextarea>

          <FGToggle
            label="Single Group Mode"
            sublabel='When enabled, this cohort will have single group named "Default"'
            name="singleGroupMode"
            onChange={handleOnChange}
            checked={formData.singleGroupMode}
          ></FGToggle>

          <div>
            <button
              type="submit"
              className="btn btn-success"
              disabled={sending}
            >
              <MdSave /> Update
            </button>{" "}
            <Link
              to={`${ROUTES.PORTAL_COHORTS}/${programmeId}`}
              className="btn btn-primary"
              state={location.state}
              onClick={(e) => {
                if (
                  contentChanged &&
                  !window.confirm(
                    Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                  )
                ) {
                  e.preventDefault();
                }
              }}
            >
              <HiX /> Cancel
            </Link>
          </div>
        </form>
      )}
    </main>
  );
}
