import React, { Dispatch, SetStateAction, useContext, useMemo, useRef } from "react";
import TraitScale from "../../../components/portal/TraitScale";
import ApiMessage, { TMessageItem } from "../../../modules/api/message";
import FeedbackItems from "../../../components/portal/FeedbackItems";
import AppUserContext from "../../../context/app-user-context";
import PopupPeerInviteFeedback, { TPopupPeerInviteFeedbackRef } from "../../../components/popup/PopupPeerInviteFeedback";
import { HiOutlinePlusCircle, HiRefresh } from "react-icons/hi";
import ROLE from "../../../modules/role";
import PopupSupervisorFeedback, { TPopupSupervisorFeedbackRef } from "../../../components/popup/PopupSupervisorFeedback";
import Notifier from "../../../modules/notifier/notifier";
import Utils from "../../../utils/utils";
import Debouncer from "../../../modules/debouncer/debouncer";

interface PageProgressTabReportPDProps {
  submission: any;
  /** this is the report's data */
  data: any;
  trait: any;
  messages?: TMessageItem[];
  isRefreshingMessages?: boolean;
  refreshMessages?: Dispatch<SetStateAction<boolean>>;
  setMessages?: Dispatch<SetStateAction<TMessageItem[]>>;
}

const PageProgressTabReportPD: React.FC<PageProgressTabReportPDProps> = ({ submission, data, trait, messages, setMessages, isRefreshingMessages, refreshMessages }) => {
  const user = useContext(AppUserContext);
  const isOwner = useMemo(() => user.referenceUserId === submission.studentId, [user, submission]);
  const isIndustry = useMemo(() => user.role === ROLE.INDUSTRY_SUPERVISOR, [user]);
  const isSupervisor = useMemo(() => user.role === ROLE.SUPERVISOR, [user]);
  const traitPaused = data.paused;
  const traitFeedbacks = messages.filter(m => m?.trait?._id === data.traitId);

  const refPopupPeerInviteFeedback = useRef<TPopupPeerInviteFeedbackRef>();
  const refPopupFeedback = useRef<TPopupSupervisorFeedbackRef>();

  const handleWriteFeedbackClick = (e) => {
    e.preventDefault();
    let opts: any;
    const existingMessage = traitFeedbacks.filter(f => f.senderId === user.referenceUserId);
    if (existingMessage.length > 0) {
      opts = {
        messageId: existingMessage[0]._id,
        feedback: existingMessage[0].message
      };
    }
    refPopupFeedback.current.show(opts);
  };

  const supervisorActions = useMemo(() => {
    if (!user || !refPopupFeedback) return <></>;

    return <div className="tf-actions">
      <button className="btn btn-icon btn-secondary" onClick={handleWriteFeedbackClick}>
        Write Feedback {" "}
        <HiOutlinePlusCircle />
      </button>
    </div>;
  }, [refPopupFeedback, user, submission, traitFeedbacks]);

  return <>
    {(isIndustry || isSupervisor) && <PopupSupervisorFeedback ref={refPopupFeedback}
      submission={submission}
      onSubmit={(formData, setDisabled, isDraft) => {
        Debouncer.execute("SUBMIT_FEEDBACK", async () => {
          const isEditMode = formData.messageId !== "";
          setDisabled(true);
          let res;

          if (!isEditMode) {
            res = await ApiMessage.submit({
              createdUserId: user.id,
              messageType: isSupervisor ? "SIT SUPERVISOR" : "INDUSTRY SUPERVISOR",
              message: formData.feedback,
              traitId: data.traitId,
              receiverId: submission.studentId,
              senderId: user.referenceUserId,
              status: isDraft ? "Draft" : "Unread",
              submissionId: submission._id
            });
          } else {
            res = await ApiMessage.update(formData.messageId, {
              message: formData.feedback,
              status: isDraft ? "Draft" : "Unread",
            });
          }

          if (res.status) {
            if (isEditMode) {
              if (isDraft) {
                Notifier.success("Feedback saved");
              } else {
                Notifier.success("Feedback updated");
              }

              setMessages(messages => {
                return messages.map(message => {
                  if (message._id === formData.messageId) {
                    message.message = formData.feedback;
                    message.status = isDraft ? "Draft" : "Unread";
                  }

                  return message;
                })
              });
            } else {
              if (isDraft) {
                Notifier.success("Feedback saved");
              } else {
                Notifier.success("Feedback submitted");
              }

              Utils.sleep(1000).then(() => {
                refreshMessages(v => !v);
              });
            }

            refPopupFeedback.current.hide();
          } else {
            Notifier.error(res.message);
          }

          setDisabled(false);
        });
      }}
    />}

    {traitPaused && <div className="report-info-wrap report-info-wrap--gap">
      <p className="alert alert-warning">This trait is paused.</p>
    </div>}

    {!traitPaused && <div className="report-info-wrap report-info-wrap--gap">

      <div className="form-report__box">
        <p className="mb-2">
          <strong>By the end of IWSP:</strong> Where you're hoping to achieve at the end of programme
        </p>
        <TraitScale name="scaleEndGoal"
          value={data.scaleEndGoal}
          trait={trait}
          readonly
        />
      </div>

      <div className="form-report__box">
        <p className="mb-1"><strong>My current Reality for this month</strong></p>
        <p className="mb-2">On a scale of 1 (poor) to 10 (excellent), this is where I rate myself?</p>
        <TraitScale name="scale"
          value={data.scale}
          trait={trait}
          readonly
        />
        <p className="mb-2 mt-2">Describe my current reality?</p>
        <div className="report-info">
          <div className="report-info__content" dangerouslySetInnerHTML={{__html: data.reality ?? ""}}></div>
        </div>
      </div>

      <div className="form-report__box">
        <p className="mb-1"><strong>My Goal for next month</strong></p>
        <p className="mb-2">On a scale of 1 (poor) to 10 (excellent), this is where I want to progress to</p>
        <TraitScale name="scaleNextMonth"
          value={data.scaleNextMonth}
          trait={trait}
          readonly
        />
        <p className="mb-2 mt-2">Describe what it would be like to achieve this goal</p>
        <div className="report-info">
          <div className="report-info__content" dangerouslySetInnerHTML={{__html: data.goal ?? ""}}></div>
        </div>
      </div>

      <div className="form-report__box">
        <p className="mb-1"><strong>Options</strong></p>
        <p className="mb-2">What would I do differently to progress to the goal I am committing to? What support and/or resources would I need to achieve this progress</p>
        <div className="report-info">
          <div className="report-info__content" dangerouslySetInnerHTML={{__html: data.options ?? ""}}></div>
        </div>
      </div>

      <div className="form-report__box">
        <p className="mb-1"><strong>Way forward</strong></p>
        <p className="mb-2">Summarize the actions you are committed to take to achieve your goal?</p>
        <div className="report-info">
          <div className="report-info__content" dangerouslySetInnerHTML={{__html: data.wayForward ?? ""}}></div>
        </div>
      </div>
    </div>}

    {!traitPaused && <div className="trait-feedback" id={`feedback-${data.traitId}`}>
      <h1>Trait's feedback(s)</h1>
      <div className="tf__actions">
        <button className="btn btn-primary"
          disabled={isRefreshingMessages}
          onClick={(e) => {
            e.preventDefault();
            refreshMessages(v => !v);
          }}
        ><HiRefresh/> Refresh feedback</button>
      </div>

      {<div className={`feedback-list-wrapper ${isRefreshingMessages ? "flw__loading" : ""}`}>
        {!isIndustry && <>
          <h2>SIT Supervisor</h2>
          {isSupervisor && supervisorActions}
          <FeedbackItems messages={traitFeedbacks.filter(i => i.messageType === "SIT SUPERVISOR")}
            labelUnavailable="Feedback not available from SIT Supervisor"
            refreshMessages={refreshMessages}
            onClickEdit={handleWriteFeedbackClick}
          />

          <div aria-hidden="true" className="tf-separator"></div>
        </>}

        <h2>Industry Supervisor</h2>
        {isIndustry && supervisorActions}
        <FeedbackItems messages={traitFeedbacks.filter(i => i.messageType === "INDUSTRY SUPERVISOR")}
          labelUnavailable="Feedback not available from Industry Supervisor"
          refreshMessages={refreshMessages}
          onClickEdit={handleWriteFeedbackClick}
        />

        {!isIndustry && <>
          <div aria-hidden="true" className="tf-separator"></div>

          <h2>Peer(s)</h2>
          {isOwner && <div className="tf-actions">
            <PopupPeerInviteFeedback ref={refPopupPeerInviteFeedback}
              user={user}
              studentId={submission.studentId}
              submissionId={submission._id}
              traitId={data.traitId}
            />
            <button className="btn btn-icon btn-secondary" onClick={(e) => {
              e.preventDefault();
              refPopupPeerInviteFeedback.current.show();
            }}>
              Invite Peers for Feedback {" "}
              <HiOutlinePlusCircle />
            </button>
          </div>}
          <FeedbackItems messages={traitFeedbacks.filter(i => i.messageType === "STUDENT")}
            labelUnavailable="Feedback not available from peer(s)"
            refreshMessages={refreshMessages} />
        </>}
      </div>}
    </div>}
  </>;
}

PageProgressTabReportPD.defaultProps = {
  messages: [],
  refreshMessages: () => {}
};

export default PageProgressTabReportPD;
