import { SUBMISSION_PD } from '../../config/constants';
import { ROUTES } from '../../routes';
import UserImage from './UserImage';
import { Link } from 'react-router-dom';

interface SubmissionCardProps {
  user: string;
  status?: string;
  to: string;
  avatar?: string;
  groupId?: string;
  userId?: string;
  submissionType?: string;
  state?: any;
}

const SubmissionCard: React.FC<SubmissionCardProps> = ({ user, status, to, avatar, groupId, userId, submissionType, state = null }) => {
  return (
    <div className='d-flex flex-shrink-0 flex-grow-1 gap-2 bg-white p-3 latest-submission'>
      <div className='latest-submission__user flex-grow-1 d-flex flex-column'>
        <UserImage width={64} url={avatar} />
        {user}
      </div>
      <div className='latest-submission__info d-flex flex-column gap-3'>
        <div className='d-flex justify-content-center align-items-center gap-3'>
          {typeof status === "string" && status !== "" && (
          <span className={`latest-submission__status lss--${status.toLowerCase() === 'not yet' ? 'not-yet' : status?.toLowerCase()}`}>
            {status}
          </span>)}
        </div>
        <div className='d-flex justify-content-center align-items-center gap-3'>
          <Link to={ROUTES.PORTAL_MY_GROUP_DETAILS + `/${groupId}/${userId}?type=${submissionType}`} state={state} className='btn btn-primary text-nowrap px-4 py-2'>View Details</Link>
        </div>
      </div>
    </div>
  );
};

SubmissionCard.defaultProps = {
  submissionType: SUBMISSION_PD
};

export default SubmissionCard;
