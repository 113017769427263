import { Link, useParams } from "react-router-dom";
import FGInput from "../../../components/dumb/FGInput";
import usePageTitle from "../../../hooks/use-page-title";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { ROUTES } from "../../../routes";
import Notifier from "../../../modules/notifier/notifier";
import { MdSave } from "react-icons/md";
import { useEffect, useState } from "react";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";
import Debouncer from "../../../modules/debouncer/debouncer";
import FGTextarea from "../../../components/dumb/FGTextarea";
import ApiOrganization from "../../../modules/api/organization";

export default function PageOrganizationEdit() {
  const title = "Edit Organization";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const { _id } = useParams();
  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    ApiOrganization.get(_id).then((res) => {
      if (res.status) {
        setFound(true);
        setFormData(res.data);
      } else {
        setFound(false);
        Notifier.error(res.message);
      }

      setLoading(false);
    });
  }, []);

  const handleOnChange = (e) => {
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Debouncer.execute("UPDATE_DATA", async () => {
      setSending(true);
      const res = await ApiOrganization.update(_id, {
        name: formData.name,
        description: formData.description,
      });

      if (res.status) {
        setContentChanged(false);
        Notifier.success(res.message);
      } else {
        Notifier.error(res.message);
      }

      setSending(false);
    });
  };

  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions hide-mobile-down">
        <Link
          to={ROUTES.PORTAL_ORGANIZATION}
          className="btn btn-primary"
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      {loading && <p className="alert alert-info">Loading data...</p>}

      {!loading && !found && (
        <p className="alert alert-info">Mail template not found.</p>
      )}

      {!loading && found && (
        <form onSubmit={handleSubmit}>
          <FGInput
            name="name"
            id="name"
            type="text"
            label="Name*"
            required
            autoFocus
            value={formData.name}
            onChange={handleOnChange}
          />

          <FGTextarea
            name="description"
            id="description"
            label="Description"
            value={formData.description}
            onChange={handleOnChange}
          />

          <div>
            <button
              type="submit"
              className="btn btn-success"
              disabled={sending}
            >
              <MdSave /> Update
            </button>{" "}
            <Link
              to={ROUTES.PORTAL_ORGANIZATION}
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  contentChanged &&
                  !window.confirm(
                    Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                  )
                ) {
                  e.preventDefault();
                }
              }}
            >
              <HiX /> Cancel
            </Link>
          </div>
        </form>
      )}
    </main>
  );
}
