/**
 * This API route used for:
 * 1. Notification
 * 2. Submission feedback
 */

import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import { IGNORE_PARAM_VALUE } from "../../config/constants";

export const MESSAGE_STATUS_READ = "Read";
export const MESSAGE_STATUS_UNREAD = "Unread";
export const MESSAGE_STATUS_DRAFT = "Draft";

export type MESSAGE_STATUS =
  | typeof MESSAGE_STATUS_READ
  | typeof MESSAGE_STATUS_UNREAD
  | typeof MESSAGE_STATUS_DRAFT;

/**
 * Notes:
 * 1) First Time Submit: This is the notification intended for supervisors
 *                       (SIT/Industry)
 */
export type MESSAGE_TYPE = "" | "__" | "INDUSTRY SUPERVISOR" | "SIT SUPERVISOR" | "STUDENT" | "Invite Peer" | "Reminder" | "First Time Submit";

export type TMessageItem = {
  /** This is the message/feedback/notification's owner */
  receiverId: string;
  senderId: string;
  message: string;
  messageType: MESSAGE_TYPE;
  anonymous: boolean;
  status: string;
  _id: string;
  createdDate: string;
  senderName: string;
  senderAvatar: string;
  receiverName: string;
  receiverAvatar: string;
  action: string;
  trait: {
    _id: string;
    name: string;
  };
};

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-50?focusedCommentId=26471
 * @param {string} userId is the `user.referenceUserId`
 * @param {string} type is the notification type `MESSAGE_TYPE`
 * @param {number} skip Start from zero
 * @param {number} take Enter zero if we want to query all
 */
async function getMessages(userId: string, type: MESSAGE_TYPE = "__", skip = 0, take = 10) {
  const info = {
    messages: [] as TMessageItem[],
    total: 0,
    totalUnread: 0
  };

  if (type === "") {
    type = "__";
  }

  try {
    const req = await axios.get(apiUrl(`/apimessage/mymessages/${userId}/${type}/${skip}/${take}`), {
      ...await getAuthHeader()
    });

    if (req.data.result !== "OK") throw new Error("Error getting message(s)");

    info.messages = req.data.data;
    info.total = req.data.total;
    info.totalUnread = req.data.totalUnread ?? 0;
  } catch (ignore) {}

  return info;
}

async function getMessagesBySubmission(submissionId: string) {
  const info = {
    messages: [] as TMessageItem[]
  };

  try {
    const req = await axios.get(apiUrl(`/apimessage/bysubmissionId/${submissionId}`), {
      params: {
        ignoreDraft: true
      },
      ...await getAuthHeader()
    });

    if (req.data.result !== "OK") throw new Error("Error getting submission's feedback(s)");

    info.messages = req.data.data;
  } catch (ignore) {}

  return info;
}

export type TSubmitData = {
  /** who send this message? */
  senderId: string;
  /** who receive the message? */
  receiverId: string;
  messageType: MESSAGE_TYPE;
  submissionId: string;
  message: string;
  traitId: string;
  status: MESSAGE_STATUS;
  /** what is the `user.id` of the sender? (NOT `user.referenceUserId`) */
  createdUserId: string;
  /** usually sender (peer) that shy to reveal themselves */
  anonymous?: boolean;
};

async function submit(data: TSubmitData) {
  try {
    const req = await axios.post(apiUrl("/apimessage"), {
      anonymous: false,
      ...data
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to submit feedback");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function update(messageId: string, payload: { message: string; status: MESSAGE_STATUS }) {
  try {
    const req = await axios.patch(apiUrl(`/apimessage/updatemessage/${messageId}`), {
      anonymous: false,
      ...payload
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update feedback");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

export type TQueryPeerItem = {
  _id: string;
  avatar: string;
  name: string;
  email: string;
  role: string;
  /** "Request" means peer hasn't been invited, "Sent" otherwise. */
  status: "Request" | "Sent";
};

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-61?focusedCommentId=26403
 */
async function querySubmissionPeers(studentId: string, activeGroupId: string, submissionId: string, traitId: string = "") {
  let data: TQueryPeerItem[] = [];

  if (traitId === "") {
    traitId = IGNORE_PARAM_VALUE;
  }
  if (!activeGroupId) {
    activeGroupId = IGNORE_PARAM_VALUE;
  }

  try {
    const req = await axios.get(apiUrl(`/apiuser/mypeers/STUDENT/${studentId}/${activeGroupId}/${submissionId}/${traitId}`), {
      ...await getAuthHeader()
    });

    if (req.data.result !== "OK") throw new Error("Error getting peers");

    data = req.data.data;
  } catch (ignore) {}

  return data;
}

export type TInvitePeerParam = {
  /** `user.referenceUserId` */
  senderId: string;
  /** `_id` of user from `TQueryPeerItem` */
  receiverId: string;
  submissionId: string;
  /** `user.id` */
  createdUserId: string;
  message: string;
  traitId: string;
};

async function invitePeer(param: TInvitePeerParam) {
  try {
    const req = await axios.post(apiUrl("/apimessage/invitepeer"), {
      messageType: "Invite Peer",
      ...param
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to invite peer");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

export type TMyPeerDetailInvitationItem = {
  _id: string;
  submissionDate: {
    _id: string;
    from: string;
    to: string;
    type: string;
    month: string;
    year: string;
  };
  traits: Array<{
    traitId: string;
    feedbackSent: boolean;
  }>;
};

export type TMyPeerDetail = {
  peerId: string;
  peerStudentId: string;
  peerName: string;
  peerEmail: string;
  peerAvatar: string;
  invitations: TMyPeerDetailInvitationItem[];
};

/**
 * @param peerId is the one who sent the invitation
 * @param userId is the `user.referenceUserId`
 */
async function myPeerDetail(peerId: string, userId: string) {
  try {
    const req = await axios.get(apiUrl(`/apisubmission/invitations/${peerId}/${userId}`), await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to get peer detail");
    return {
      status: true as true,
      message: req.data.message ?? "OK",
      data: req.data.data as TMyPeerDetail
    };
  } catch (err) {
    return {
      status: false as false,
      message: err.message
    };
  }
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-72
 * This API is to set notification status to "Read" for specific message ID
 * or several message IDs.
 *
 * @update
 * Now we can update multiple messages by passing their ids with comma between
 * Example: https://iwsp.fxwebapps.com:9087/apimessage/updatestatus/6454d4fc0332770db47a02e1,6454d4fc0332770db47a02e1/Read
 */
async function setReadStatus(messageIds: string) {
  try {
    const req = await axios.patch(apiUrl(`/apimessage/updatestatus/${messageIds}/Read`), {}, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to set read status");
    return {
      status: true,
      message: req.data.message ?? "OK"
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-72?focusedCommentId=26547
 * Similar to `setReadStatus` above, except this will set all notification
 * status to "Read" for specific user (`user.referenceUserId`);
 */
async function setReadStatusByUser(userId: string) {
  try {
    const req = await axios.patch(apiUrl(`/apimessage/updatestatusall/${userId}/Read`), {}, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to set read status");
    return {
      status: true,
      message: req.data.message ?? "OK"
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

export type TNotificationItem = {
  _id: string;
  senderId: string;
  receiverId: string;
  message: string;
  messageType: MESSAGE_TYPE;
  anonymous: boolean;
  status: string;
  createdDate: string;
  modifiedDate: string;
  senderName: string;
  senderAvatar: string;
  receiverName: string;
  receiverAvatar: string;
  action: string;
  feedbackSent: any;
  submissionType: string;
  submissionId: string;
  traitId: string;
  /** submission's period (from) in ISO time */
  from: string;
  /** submission's period (to) in ISO time */
  to: string;
};

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-50?focusedCommentId=26534
 * @param {string} studentId is the `user.referenceUserId`
 * @param {number} skip Start from zero
 * @param {number} take Enter zero if we want to query all
 * @param {MESSAGE_TYPE} type We can filter notif by `MESSAGE_TYPE`
 */
async function getNotifications(studentId: string, skip = 0, take = 0, type: MESSAGE_TYPE = "") {
  const info = {
    data: [] as TNotificationItem[],
    total: 0,
    totalUnread: 0
  };

  try {
    if (type === "") {
      type = IGNORE_PARAM_VALUE;
    }

    const req = await axios.get(apiUrl(`/apimessage/mymessages/${studentId}/${type}/${skip}/${take}`), {
      params: {
        ignoreDraft: true
      },
      ...await getAuthHeader()
    });

    if (req.data.result !== "OK") throw new Error("Error getting submissions");

    info.data = req.data.data ?? [];
    info.total = req.data.total ?? 0;
    info.totalUnread = req.data.totalUnread ?? 0;
  } catch (ignore) {}

  return info;
}

/**
 * This is the method to check every some interval on how many unread notification.
 */
async function getNotificationsUnreadCount(studentId: string) {
  let unreadCount = 0;

  try {
    const req = await getNotifications(studentId, 0, 0);
    unreadCount = req.totalUnread ?? 0;
  } catch (ignore) {}

  return unreadCount;
}

const ApiMessage = {
  getMessages,
  getMessagesBySubmission,
  submit,
  update,
  querySubmissionPeers,
  invitePeer,
  myPeerDetail,
  setReadStatus,
  setReadStatusByUser,
  getNotifications,
  getNotificationsUnreadCount
};

export default ApiMessage;
