import React, { useState, useRef, useContext } from "react";
import {
  HiOutlineUpload,
  HiOutlineX,
  HiOutlineDocumentDuplicate,
} from "react-icons/hi";
import Notifier from "../../../modules/notifier/notifier";
import ApiUser from "../../../modules/api/user";
import Debouncer from "../../../modules/debouncer/debouncer";
import { Alert } from "react-bootstrap";
import AppUserContext from "../../../context/app-user-context";
import ROLE from "../../../modules/role";

export default function PageUserRegistCSV() {
  const user = useContext(AppUserContext);
  const fileRef = useRef<HTMLInputElement>();
  const [sending, setSending] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [error, setError] = useState("");
  const [results, setResults] = useState<
    Array<{
      email: string;
      success: boolean;
      messages?: string[];
      warnings?: string[];
    }>
  >([]);

  const resetFileValue = (resetUploadedFile = true) => {
    try {
      fileRef.current.value = "";
      setUploadProgress(-1);

      if (resetUploadedFile) {
        setUploadedFile(null);
      }
    } catch (ignore) {}
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  // Reference only for now
  const intervalRef = useRef<NodeJS.Timeout>();
  const progressLogic = () => {
    setUploadProgress(0);
    intervalRef.current = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(intervalRef.current);
          Notifier.success("File uploaded successfully.");
          return 0;
        } else {
          return prevProgress + 10;
        }
      });
    }, 500);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];

    if (validateFileType(file)) {
      setError("");
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError("Invalid file type. Please upload a CSV file.");
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file && validateFileType(file)) {
      setError("");
      setResults([]);
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError("Invalid file type. Please upload a CSV file.");
    }
  };

  const handleUploadAreaClick = () => {
    resetFileValue(false);
    document.getElementById("user-form-upload-file-input")?.click();
  };

  const handleRemoveFile = () => {
    resetFileValue();
    setUploadedFile(null);
    setError("");
    setResults([]);
  };

  const validateFileType = (file: File) => {
    const allowedTypes = ["text/csv"];
    return allowedTypes.includes(file.type);
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    Debouncer.execute("SUBMIT_CSV", async () => {
      setSending(true);
      const { success, message, results, failCount, passCount } =
        await ApiUser.importByCsv(uploadedFile);
      const notify = success ? Notifier.success : Notifier.error;
      message && notify(message);
      success && handleRemoveFile();
      setResults(Array.isArray(results) ? results : []);
      setSending(false);
    });
  };

  return (
    <div data-comp="PageUserRegistCSV">
      <form className="form-register">
        <div className="user-form-upload">
          <h3 className="fs-5">Add new CSV File</h3>
          {user.role === ROLE.ADMIN && (
            <p>
              <a
                href="/assets/sample/sample-user-for-administrator.csv?t=20230529"
                target="_blank"
              >
                Click here to download the sample (
                <strong>sample-user-for-administrator.csv</strong>)
              </a>
              .
            </p>
          )}
          {user.role === ROLE.PROGRAM_ADMIN && (
            <p>
              <a
                href="/assets/sample/sample-user-for-program-admin.csv?t=20230529"
                target="_blank"
              >
                Click here to download the sample (
                <strong>sample-user-for-program-admin.csv</strong>)
              </a>
              .
            </p>
          )}
          <div
            className={`upload-area ${
              isDragging ? "dragging" : ""
            } border rounded col-12 text-center`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleUploadAreaClick}
          >
            <HiOutlineUpload className="upload-icon mb-3 text-secondary" />
            <p className="mb-1 fs-5">Drag & Drop or Choose file to upload</p>
            <p className="text-secondary fs-5">CSV file</p>
            <input
              ref={fileRef}
              type="file"
              accept=".csv"
              onChange={handleFileInputChange}
              id="user-form-upload-file-input"
              className="d-none"
            />
          </div>
        </div>
        {error && error !== "" && (
          <div className="alert alert-danger mt-3 mb-0" role="alert">
            {error}
          </div>
        )}
      </form>

      {uploadedFile && (
        <div className="uploaded-file-container mt-3 p-2 rounded">
          <div className="uploaded-file d-flex justify-content-between align-items-center p-2 bg-white rounded">
            <p className="mb-0">
              <span>
                <HiOutlineDocumentDuplicate className="fs-4 me-2" />
              </span>
              {uploadedFile.name}
            </p>
            <HiOutlineX className="cancel-icon" onClick={handleRemoveFile} />
          </div>
          {uploadProgress > -1 && (
            <div className="upload-progress-bar-container">
              <div className="">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress}%` }}
                  aria-valuenow={uploadProgress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {results.length > 0 && (
        <Alert
          variant="info"
          onClose={() => setResults([])}
          dismissible
          className="import-csv-email-notify-status"
        >
          <h4>Email Notification Status</h4>
          <p>
            Below is the list of notifications of each imported user(s). Failed
            means system was unable to create/update or notify the user via
            email.
          </p>
          {results.map((item, index) => (
            <Alert
              key={`email-notify-${index}`}
              variant={item.success ? "success" : "danger"}
            >
              <strong>Row {`#${index}`}</strong> ({item.email}):
              {item.messages?.length > 0 && (
                <ul className="mb-0 import-csv-email-notify-messages">
                  {item.messages.map((m, i) => (
                    <li key={`notify-message-${i}`}>{m}</li>
                  ))}
                </ul>
              )}
            </Alert>
          ))}
        </Alert>
      )}

      <div className="mb-3">
        <button
          type="button"
          className="btn btn-primary mt-3"
          disabled={!uploadedFile || sending}
          onClick={handleSubmit}
        >
          Submit Data
        </button>
      </div>
    </div>
  );
}
