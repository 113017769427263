import { ROUTES } from "../../../routes";
import { MdSearch } from "react-icons/md";
import usePageTitle from "../../../hooks/use-page-title";
import ApiProgramme from "../../../modules/api/programme";
import { Link } from "react-router-dom";
import AppUserContext from "../../../context/app-user-context";
import { useState, useContext } from "react";
import { usePortalSettingSet } from "../../../context/portal-context";
import ServerSideProgrammeDataTable from "../../../components/common/ServerSideProgrammeDataTable";

type TPageSelectProgrammeProps = {
  isDashboard?: boolean;
};

export default function PageSelectProgramme(props: TPageSelectProgrammeProps) {
  const title = "Select Programme";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const user = useContext(AppUserContext);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  return (
    <main className="form-generic content-generic content-generic--fluid">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions">
        <div className="cg__action-buttons">
          <button
            className="btn btn-outline-primary"
            onClick={() => setRefresh((i) => !i)}
          >
            Refresh
          </button>
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <ServerSideProgrammeDataTable
        api={ApiProgramme.pagingSearch}
        search={search}
        columns={[
          {
            id: "name",
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
          },
          {
            id: "description",
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
          },
          {
            id: "action",
            name: "Action",
            cell: (row) => {
              const from = props.isDashboard
                ? ROUTES.PORTAL_DASHBOARD
                : ROUTES.PORTAL_SELECT_PROGRAMME;
              return (
                <div>
                  <Link
                    to={`${ROUTES.PORTAL_COHORTS}/${row._id}`}
                    className="manage-cohorts-link"
                    state={{ from: from }}
                  >
                    Manage Cohorts
                  </Link>
                </div>
              );
            },
          },
        ]}
        refresh={refresh}
        programAdminId={user.referenceUserId}
      />
    </main>
  );
}
