import axios from "axios";
import TraitConfig, { TParsedTraitConfig } from "../../config/trait-config";
import ApiGenericData, { TGenericItem } from "./generic-data";
import { apiUrl, getAuthHeader } from "./base";

export type TTraitItem = TGenericItem & {
  config: string;
  parsedConfig?: TParsedTraitConfig;
};

export type TTraitData = {
  traitId: string;
  reality: string;
  goal: string;
  options: string;
  wayForward: string;
  scale: number;
  scaleNextMonth: number;
  scaleEndGoal: number;
  scaleEndGoalChangeReason: string;
  paused: boolean;
  scaleEndGoalPrevious?: number;
};

export type THashTraitData = {
  /** the key is Trait's ID */
  [key: string]: TTraitData;
};

/**
 * This one we assumed we have parsed config, while `TTraitItem`, the parsed
 * config may be optional.
 */
export type TGetAllTraitItem = TTraitItem & {
  parsedConfig: TParsedTraitConfig;
};

export type TGetAllTraits = Array<TGetAllTraitItem>;

export function getTraitDataPlaceholder(): TTraitData {
  return {
    traitId: "",
    reality: "",
    goal: "",
    options: "",
    wayForward: "",
    scale: 0,
    scaleNextMonth: 0,
    scaleEndGoal: 0,
    scaleEndGoalChangeReason: "",
    scaleEndGoalPrevious: 0,
    paused: false,
  };
}

/**
 * Since this method such an expensive computation, we implement caching. As
 * long as user doesn't refresh the page, user will retrieve the cached data
 * for subsequent requests (2nd onwards).
 */
const getAll = (cohortId: string = ``) => (() => {
  let loaded = false;
  let isFetching = false;
  let cached: TGetAllTraits = [];
  let callbackStack: any[] = [];

  return async (): Promise<TGetAllTraits> => {
    return new Promise(async (res, rej) => {
      if (loaded) {
        return res(cached);
      }

      if (isFetching) {
        return callbackStack.push(res);
      }

      isFetching = true;

      // fetch and parse new
      const apiurl = `/apigenericdata/queryall/Trait${cohortId ? `?cohortId=${cohortId}` : ``}`;
      const req = await axios.get(apiUrl(apiurl), {
        ...await getAuthHeader()
      });

      if (req.data && Array.isArray(req.data.data)) {
        cached = req.data.data.map(item => ({
          _id: item._id,
          name: item.name,
          config: "",
          parsedConfig: TraitConfig.parse(item.config)
        }));
      }

      loaded = true;
      isFetching = false;
      callbackStack.forEach(cb => cb(cached));
      callbackStack = [];

      res(cached);
    });
  };
})()();

const addByJson = async (cohortId: string, file: File) => {
  const formData = new FormData();
  formData.append("json", file);
  const req = await axios.post(
    apiUrl(`/apigenericdata/import-traits/${cohortId}`),
    formData,
    await getAuthHeader()
  );
  return req.data;
};

const ApiTrait = {
  pagingSearch: ApiGenericData.pagingSearch("Trait"),
  add: ApiGenericData.add<Omit<TTraitItem, "_id">>("Trait"),
  addByJson: addByJson,
  get: ApiGenericData.get,
  getCached: ApiGenericData.getCached,
  getAll,
  getTraitDataPlaceholder,
  update: ApiGenericData.update<Omit<TTraitItem, "_id">>("Trait")
};

export default ApiTrait;
