import { Link } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { ROUTES } from "../../../routes";
import { useContext, useEffect, useState } from "react";
import AppUserContext from "../../../context/app-user-context";
import ApiSubmissionDate from "../../../modules/api/submission-date";
import { showLabelInfo } from "../../../config/methods";
import AppUser from "../../../modules/app-user/app-user";
import ReportItemListing from "../../../components/portal/ReportItemListing";

export default function PageReport() {
  usePageTitle("My Reports");
  usePortalSettingSet("label_header", "My Reports");
  usePortalSettingSet("is_homepage", false);

  const user = useContext(AppUserContext);
  const [loading, setLoading] = useState(true);
  const [nextDeadline, setNextDeadline] = useState({
    wl: "-",
    pd: "-"
  });

  useEffect(() => {
    (async () => {
      await AppUser.refreshUser(["avatar"]);

      if (user.activeCohort._id && user.activeCohort._id.trim()) {
        const resNextDeadlineApi = await ApiSubmissionDate.getSubmissionReminder(user.activeCohort._id, user.referenceUserId);
        setNextDeadline(resNextDeadlineApi);
      }

      setLoading(false);
    })();
  }, []);

  return (
    <div data-comp="PageReport">
      <h1 className="fs-title mb-3 d-none d-md-block">My Reports</h1>

      <div className="d-flex flex-column flex-xl-row gap-3 w-100 my-reports-action">
        <div className="w-100 d-flex flex-row bg-white gap-3 p-3 rounded-4 report__button-container">
          <Link to={ROUTES.PORTAL_REPORT_WL} className='btn report__add-report-button d-flex flex-column justify-content-center align-items-center h-100 w-50'>
            <HiPlus className='fs-1'/>
            <p>Add Workplace <br /> Learning Report</p>
            <span className='mt-3'>{nextDeadline.wl}</span>
          </Link>

          <Link to={ROUTES.PORTAL_REPORT_PD} className='btn report__add-report-button d-flex flex-column justify-content-center align-items-center h-100 w-50'>
            <HiPlus className='fs-1'/>
            <p>Add Professional <br /> Development Report</p>
            <span className='mt-3'>{nextDeadline.pd}</span>
          </Link>
        </div>
      </div>

      <ReportItemListing
        className="mt-3"
        userId={user.referenceUserId}
        cohortId={user.activeCohort._id}
        itemsPerPage={10}
        pagination={true}
        isReportPage={true}
      />
    </div>
  );
}
