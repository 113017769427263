import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import { IGNORE_PARAM_VALUE } from "../../config/constants";
import ROLE from "../role";
import { getDefaultEmptyApiParam } from "../../config/methods";

export type TUserPagingSearchParam = {
  role: string;
  page: number;
  itemPerPage: number;
  name?: string;
  email?: string;
  filterBy?: string;
};

async function pagingSearch(param: TUserPagingSearchParam) {
  const ret = {
    data: [],
    total: 0
  };

  try {
    const filterBy = param.filterBy;
    let name = param.filterBy === "name" ? getDefaultEmptyApiParam(param.name) : IGNORE_PARAM_VALUE;
    let email = param.filterBy === "email" ? getDefaultEmptyApiParam(param.email) : IGNORE_PARAM_VALUE;

    const role = getDefaultEmptyApiParam(param.role);
    const targetUrl = `/apiuser/search/${role}/${name}/${email}/${filterBy}/${param.page}/${param.itemPerPage}`;
    const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
    const res = req.data;

    if (!(
      res.result === "OK" &&
      Array.isArray(res.data) &&
      typeof res.totalCount === "number"
    )) throw new Error();

    ret.data = res.data;
    ret.total = res.totalCount;
  } catch (ignore) {}

  return ret;
}

async function add(formData: any) {
  let status = false;
  let message = "";

  try {
    const req = await axios.post(apiUrl(`/apiuser/register`), formData, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update data");
    status = true;
    message = "User has been registered";
  } catch (err) {
    message = err.message;
  }

  return {
    status,
    message
  };
}

async function importByCsv(file: File, groupId: string = ``) {
  let success = true;
  let message = ``;
  let results = [];
  let failCount = 0;
  let passCount = 0;
  try {
    const formData = new FormData();
    formData.append("csv", file);
    formData.append("url", `${process.env.REACT_APP_WEB_URL}`);
    const req = await axios.post(
      apiUrl(`/apiuser/import-csv/${groupId}`),
      formData,
      await getAuthHeader()
    );
    if (req.status !== 200) {
      success = false;
      message = `Unable to import. An error orcured`;
    } else {
      const responseData = req.data;
      if (responseData?.result === `OK`) {
        message =
          typeof responseData?.message === "string"
            ? responseData.message ??
              `Import process has been done. Please check the status below`
            : ``;
        results = Array.isArray(responseData.data?.results)
          ? responseData.data.results
          : [];
        failCount =
          typeof responseData.data?.failCount === "number"
            ? responseData.data.failCount
            : 0;
        passCount =
          typeof responseData.data?.passCount === "number"
            ? responseData.data.passCount
            : 0;
      } else {
        success = false;
        message = responseData?.message ?? `Unable to import. An error orcured`;
      }
    }
  } catch (err) {
    success = false;
    message = err.message;
  }
  return {
    success,
    message,
    results,
    failCount,
    passCount,
  };
}

async function addByCsv(file: File) {
  let status = false;
  let message = "";
  let errorList = [];
  let emailSendingStatus = [];

  try {
    const formData = new FormData();
    formData.append("csv", file);
    formData.append("url", `${process.env.REACT_APP_WEB_URL}`);
    const req = await axios.post(apiUrl(`/apiuser/import-csv`), formData, await getAuthHeader());

    if (req.data.result !== "OK") {
      if (Array.isArray(req.data.errors)) {
        errorList = req.data.errors;
      }

      throw new Error(req.data.message ?? "Unable to update data");
    }

    if (Array.isArray(req.data.emailSendingStatus)) {
      emailSendingStatus = req.data.emailSendingStatus;
    }

    status = true;
    message = "User(s) successfully imported";
  } catch (err) {
    message = err.message;
  }

  return {
    status,
    message,
    errorList,
    emailSendingStatus
  };
}

async function remove(_ids: string[]) {
  let status = false;
  let message = "";

  try {
    const req = await axios.delete(apiUrl(`/apiuser`), {
      ...await getAuthHeader(),
      data: _ids
    });
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to delete user(s)");
    status = true;
    message = req.data.message;
  } catch (err) {
    message = err.message;
  }

  return {
    status,
    message
  };
}

async function get<T = any>(referenceUserId: string): Promise<{ status: boolean; message: string; data: T; }> {
  try {
    const req = await axios.get(apiUrl(`/apiuser/${referenceUserId}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK" || req.data.data === null) throw new Error(req.data.message ?? "Unable to get user data");
    return {
      status: true,
      message: "OK",
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message,
      data: null
    };
  }
}

async function getDetailByGroup<T = any>(groupId: string, referenceUserId: string): Promise<{ status: boolean; message: string; data: T; }> {
  try {
    const req = await axios.get(apiUrl(`/apiuser/detailByGroup/${groupId}/${referenceUserId}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK" || req.data.data === null) throw new Error(req.data.message ?? "Unable to get user detail by group");
    return {
      status: true,
      message: "OK",
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message,
      data: null
    };
  }
}

async function update(_id: string, formData: any) {
  try {
    const req = await axios.patch(apiUrl(`/apiuser/update/${_id}`), formData, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update user");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

type TBasicUpdateParams = {
  /** user.referenceUserId */
  referenceUserId: string;
  /** user.id */
  modifiedUserId: string;
};

type TUpdateAvatarParams = TBasicUpdateParams & {
  /** base64 encoded string of image JPG/PNG */
  avatar: string;
};

async function updateAvatar(params: TUpdateAvatarParams) {
  try {
    const req = await axios.patch(apiUrl(`/apiuser/updateavatar/${params.referenceUserId}`), {
      modifiedUserId: params.modifiedUserId,
      avatar: params.avatar
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update avatar");
    return {
      status: true,
      message: "OK"
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

type TUpdateAppSettingParam = TBasicUpdateParams & {
  key: string;
  value: any;
};

async function updateAppSetting(params: TUpdateAppSettingParam) {
  try {
    const req = await axios.patch(apiUrl(`/apiuser/updatefield/${params.referenceUserId}`), {
      modifiedUserId: params.modifiedUserId,
      fieldName: params.key,
      fieldValue: params.value
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update app setting");
    return {
      status: true,
      message: req.data.message ?? "OK"
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function searchByName(keywords: string, limit: string|number = 20) {
  let ret = [];

  try {
    if (keywords.trim() === "") {
      keywords = IGNORE_PARAM_VALUE;
    }

    if (
      (typeof limit === "string" && limit.trim() === "") ||
      (typeof limit === "number" && limit <= 0)
    ) {
      limit = IGNORE_PARAM_VALUE;
    }

    const req = await axios.get(apiUrl(`/apiuser/searchKeyword/${limit}/${keywords}`), await getAuthHeader());

    if (req.data.result === "OK") {
      ret = req.data.data;
    }
  } catch (ignore) {}

  return ret;
}

export type TMyPeersItem = {
  avatar: string;
  _id: string;
  name: string;
  email: string;
  role: string;
  groupId: string;
};

/**
 * IS-13
 * Role can be single (STUDENT) and multiple roles seperated by comma
 * (STUDENT, SIT SUPERVISOR) and also can be `__` if we don’t want to filter by role.
 *
 * `referenceUserId` is the `user.referenceUserId`
 * `activeGroupId` is the `user.activeGroup._id`
 */
async function myPeers(referenceUserId: string, activeGroupId: string, role: string = ROLE.STUDENT) {
  let users: TMyPeersItem[] = [];

  try {
    if (!activeGroupId) {
      activeGroupId = IGNORE_PARAM_VALUE;
    }
    const req = await axios.get(apiUrl(`/apiuser/mypeers/${role}/${referenceUserId}/${activeGroupId}`), await getAuthHeader());

    if (req.data.result === "OK" && Array.isArray(req.data.data)) {
      users = req.data.data;
    }
  } catch (ignore) {}

  return users;
}

async function getProgramAdmins() {
  let users = [];

  try {
    const req = await axios.get(apiUrl(`/apiuser/programadmins`), await getAuthHeader());
    if (req.data.result === "OK" && Array.isArray(req.data.data)) {
      users = req.data.data;
    }
  } catch (ignore) {}

  return users;
}

const ApiUser = {
  pagingSearch,
  add,
  importByCsv,
  addByCsv,
  get,
  getDetailByGroup,
  remove,
  update,
  updateAvatar,
  updateAppSetting,
  searchByName,
  myPeers,
  getProgramAdmins
};

export default ApiUser;
