import { useEffect, useState } from "react";
import DataTable, {TableColumn} from 'react-data-table-component'
import { TProgrammePagingSearchParam } from "../../modules/api/programme";

export type TServerSideProgrammeDTApiResult = { data: any[]; total: number; };

export type TServerSideProgrammeDataTableProps = {
    api: (opts: TProgrammePagingSearchParam) => Promise<TServerSideProgrammeDTApiResult>
    columns: TableColumn<any>[];
    refresh?: boolean;
    search?: string;
    searchDelay?: number;
    selectableRows?: boolean;
    onSelectedRowsChange?: (selected: {
      allSelected: boolean;
      selectedCount: number;
      selectedRows: any[];
    }) => void;
    programAdminId?: string;
    mapper?: (item: any) => any;
}

export default function ServerSideProgrammeDataTable(props: TServerSideProgrammeDataTableProps) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    let timers = setTimeout(() => {
    setCurrentPage(1);
      setKeywords(props.search);
    }, typeof props.searchDelay !== "number" ? 500 : props.searchDelay);
    return () => clearTimeout(timers);
  }, [props.search]);
    
  useEffect(() => {
    setLoading(true);
    props.api({
        programAdminId: props.programAdminId,
        name: props.search,
        page: currentPage,
        perPage: rowPerPage
    })
    .then(res => {
        setLoading(false);
        setTotalRows(res.total);
        setItems(props.mapper ? res.data.map(props.mapper) : res.data)
    })
    .catch(exception => {
        setLoading(false);
        console.log(exception);
    })
  }, [keywords, currentPage, rowPerPage , props.refresh]);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (rowPerPage: number, page: number) => {
    setRowPerPage(rowPerPage);
    setCurrentPage(page);
  };

  return (
    <div className="basic-rdt" data-comp="ServerSideProgrammeDataTable">
      <DataTable
        data={items}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowPerPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        columns={props.columns}
        selectableRows={props.selectableRows}
        onSelectedRowsChange={props.onSelectedRowsChange}
      />
      {loading && <div className="ssd__loading">
        <div className="ssd__loading__text">Loading...</div>
    </div>}
    </div>
  )
}
