import { useState } from "react";
import { MdSave } from "react-icons/md";
import { ROUTES } from "../../../routes";
import Utils from '../../../utils/utils';
import ApiUser from "../../../modules/api/user";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import FGInput from "../../../components/dumb/FGInput";
import usePageTitle from "../../../hooks/use-page-title";
import Notifier from '../../../modules/notifier/notifier';
import ApiProgramme from "../../../modules/api/programme";
import FGTextarea from "../../../components/dumb/FGTextarea";
import Debouncer from "../../../modules/debouncer/debouncer";
import FGDropdownApi from '../../../components/form/FGDropdownApi';
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";

export default function PageProgrammeAdd() {
  const title = "Add New Programme";
  const navigate = useNavigate();
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    programAdminIds: ""
  });

  const handleOnChange = (e: any) => {
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_DATA", async () => {
      setSending(true);
      await ApiProgramme.create({...formData})
        .then(async res => {
            setSending(false);
            if (res.result === "OK") {
              Notifier.success(res.message);
              await Utils.sleep(2000);
              navigate(ROUTES.PORTAL_PROGRAMMES);
            } else {
              Notifier.error(res.message);
            }
        })
        .catch(error => {
          setSending(false);
          Notifier.error(error.message);
        });
    });
  };

  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">{title}</h1>
      
      <div className="cg__actions hide-mobile-down">
        <Link
          to={ROUTES.PORTAL_PROGRAMMES}
          className="btn btn-primary"
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      <form onSubmit={handleSubmit}>
        <FGInput
          name="name"
          id="name"
          type="text"
          label="Name*"
          required
          autoFocus
          onChange={handleOnChange}
        />

        <FGDropdownApi
          label="Programme Manager"
          name="programAdminIds"
          closeMenuOnSelect={false}
          defaultValue={formData.programAdminIds}
          api={ApiUser.getProgramAdmins}
          mapper={(programAdmins) => programAdmins.map(pa => {
            return {
              label : pa.name,
              value : pa._id
            }
          })}
          onChange={handleOnChange}
          multiple
        />

        <FGTextarea
          name="description"
          id="description"
          label="Description"
          onChange={handleOnChange}
        />

        <div>
          <button type="submit" className="btn btn-success" disabled={sending}>
            <MdSave /> Add
          </button>{" "}
          <Link
            to={ROUTES.PORTAL_PROGRAMMES}
            className="btn btn-primary"
            onClick={(e) => {
              if (
                contentChanged &&
                !window.confirm(
                  Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                )
              ) {
                e.preventDefault();
              }
            }}
          >
            <HiX /> Cancel
          </Link>
        </div>
      </form>
    </main>
  )
}
