import { Generics } from "./generics";
import { ObjectQuery } from "./object-query";
import { QueryStrings } from "./query-strings";
import { Security } from "./security";
import { Strings } from "./strings";
import { Times } from "./times";
import { Regexes } from "./regexes";
import { UtilsCookies } from "./cookies";
import { Navigator } from "./navigator";

export default class Utils {

  // @namespace Generics
  static createTemplate = Generics.createTemplate;
  static createTemplateCache = Generics.createTemplateCache;
  static toId = Generics.toId;
  static ifEmptyOrUndefinedThen = Generics.ifEmptyOrUndefinedThen;
  static ifNotStringThen = Generics.ifNotStringThen;
  static ifUndefinedThen = Generics.ifUndefinedThen;
  static loadImage = Generics.loadImage;
  static pad = Generics.pad;
  static sleep = Generics.sleep;
  static timeoutPromise = Generics.timeoutPromise;
  static valueBetween = Generics.valueBetween;
  static isEmptyString = Generics.isEmptyString;
  static nl2br = Generics.nl2br;
  static deepCopy = Generics.deepCopy;
  static getWindowSize  = Generics.getWindowSize;
  static escapeRegExp  = Generics.escapeRegExp;
  static parseJson  = Generics.parseJson;
  static loop  = Generics.loop;
  static ucwords  = Generics.ucwords;
  static isMatch  = Generics.isMatch;
  static redirect  = Generics.redirect;
  static timeToSeconds  = Generics.timeToSeconds;
  static intToTime  = Generics.intToTime;
  static promisify  = Generics.promisify;
  static throttle = Generics.throttle;
  static getUrlPathAndParams = Generics.getUrlPathAndParams;
  static ellipsis = Generics.ellipsis;
  static sortByObjectPropNatural = Generics.sortByObjectPropNatural;
  static sortByObjectPropNumber = Generics.sortByObjectPropNumber;
  static getBase64FromFile = Generics.getBase64FromFile;
  static html2Text = Generics.html2Text;

  // @namespace Times
  static isoTimeToDefault = Times.isoTimeToDefault;
  static toIsoString = Times.toIsoString;
  static getTimezone = Times.getTimezone;
  static getCurrentDateYmd = Times.getCurrentDateYmd;
  static getCurrentDateHis = Times.getCurrentDateHis;

  // @namespace Strings
  static removeInitialSlash = Strings.removeInitialSlash;
  static interpolate = Strings.interpolate;
  static trimString = Strings.trimString;
  static stringFormat = Strings.stringFormat;
  static stringToArray = Strings.stringToArray;
  static trimExceedChars = Strings.trimExceedChars;
  static pluralize = Strings.pluralize;

  // @namespace QueryStrings
  static parseQueryString = QueryStrings.parseQueryString;
  static stripQueryString = QueryStrings.stripQueryString;
  static addQueryString = QueryStrings.addQueryString;
  static existQueryString = QueryStrings.existQueryString;

  // @namespace ObjectQuery
  static ObjectQuery = ObjectQuery;

  // @namespace Security
  static sanitizeHTML = Security.sanitizeHTML;

  // @namespace Regexes
  static R = Regexes;

  // @namespace UtilsCookies
  static cookies = UtilsCookies;

  // @namespace Navigator
  static isMobileAndTablet = Navigator.isMobileAndTablet;

}
