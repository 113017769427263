import { ROUTES } from "../../../routes";
import { AiOutlineTags } from "react-icons/ai";
import ApiCohort from "../../../modules/api/cohort";
import { HiArrowSmLeft, HiPlus } from "react-icons/hi";
import { useState, useEffect, useContext } from "react";
import usePageTitle from "../../../hooks/use-page-title";
import Notifier from "../../../modules/notifier/notifier";
import { FaRegCalendarAlt, FaUsers } from "react-icons/fa";
import AppUserContext from "../../../context/app-user-context";
import { Link, useParams, useLocation } from "react-router-dom";
import { usePortalSettingSet } from "../../../context/portal-context";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";

export default function PageCohortManage() {
  const title = "Manage Cohort";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const user = useContext(AppUserContext);
  const { programmeId, _id } = useParams();
  const isValidProgrammeId = user.programmeIds.includes(programmeId);
  const [found, setFound] = useState(false);
  const [message, setMessage] = useState("Cohort not found");
  const [loading, setLoading] = useState(true);
  const [cohort, setCohort] = useState({
    name: "",
    singleGroupMode: false,
    singleGroupId: "",
    programme: {
      name: "",
    },
  });

  useEffect(() => {
    if (!isValidProgrammeId) {
      return;
    }
    ApiCohort.get(_id, true)
      .then((res) => {
        setLoading(false);
        if (res.result === "OK") {
          if (!res.data.programme || res.data.programme._id !== programmeId) {
            setMessage(`Cohort does not belong the current programme`);
          } else {
            setFound(true);
            setCohort(res.data);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifier.error(error.message);
      });
  }, []);

  if (!isValidProgrammeId) {
    return <PagePortalNotFound />;
  }
  return (
    <main className="content-generic--fluid cohort-manage-wrapper">
      <h1 className="fs-title hide-mobile-down" style={{ marginBottom: 7 }}>
        {title}
      </h1>

      {loading && <p className="alert alert-info">Loading data...</p>}

      {!loading && !found && <p className="alert alert-info">{message}.</p>}

      {!loading && found && (
        <div className="row">
          <div className="col-lg-12">
            <div className="alert alert-info cohort-info">
              <label>
                <strong>Cohort Name:</strong> <span>{cohort.name}</span>
              </label>
              <br />
              <label>
                <strong>Programme:</strong> <span>{cohort.programme.name}</span>
              </label>
            </div>
            <Link
              to={`${ROUTES.PORTAL_COHORTS}/${programmeId}`}
              className="manage-cohort-back"
              state={location.state}
            >
              <HiArrowSmLeft size={20} style={{ marginRight: 3 }} />
              Back
            </Link>
            <div className="row manage-cohort-portals">
              <div className="col-lg-4">
                {cohort.singleGroupMode ? (
                  cohort.singleGroupId && (
                    <Link
                      to={`${ROUTES.PORTAL_GROUPS}/manage-member/${programmeId}/${_id}/${cohort.singleGroupId}`}
                      className="btn report__add-report-button d-flex flex-column justify-content-center align-items-center flex-fill"
                      state={{
                        ...location.state,
                        previous: window.location.pathname,
                      }}
                    >
                      <FaUsers className="fs-1" />
                      <p>Manage Users</p>
                    </Link>
                  )
                ) : (
                  <Link
                    to={`${ROUTES.PORTAL_GROUPS}/${programmeId}/${_id}`}
                    className="btn report__add-report-button d-flex flex-column justify-content-center align-items-center flex-fill"
                    state={location.state}
                  >
                    <FaUsers className="fs-1" />
                    <p>Manage Groups</p>
                  </Link>
                )}
              </div>
              <div className="col-lg-4">
                {" "}
                <Link
                  to={`${ROUTES.PORTAL_TRAIT}/${programmeId}/${_id}`}
                  className="btn report__add-report-button d-flex flex-column justify-content-center align-items-center flex-fill"
                  state={location.state}
                >
                  <AiOutlineTags className="fs-1" />
                  <p>Manage Traits</p>
                </Link>
              </div>
              <div className="col-lg-4">
                <Link
                  to={`${ROUTES.PORTAL_SUBMISSION_DATE}/${programmeId}/${_id}`}
                  className="btn report__add-report-button d-flex flex-column justify-content-center align-items-center flex-fill"
                  state={location.state}
                >
                  <FaRegCalendarAlt className="fs-1" />
                  <p>Manage Submission Dates</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
