import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FGInput from "../../../components/dumb/FGInput";
import usePageTitle from "../../../hooks/use-page-title";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { ROUTES } from "../../../routes";
import Notifier from "../../../modules/notifier/notifier";
import { MdSave } from "react-icons/md";
import { useContext, useRef, useState } from "react";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";
import Debouncer from "../../../modules/debouncer/debouncer";
import Utils from "../../../utils/utils";
import FGTextarea from "../../../components/dumb/FGTextarea";
import ApiTrait from "../../../modules/api/trait";
import AppUserContext from "../../../context/app-user-context";
import PopupConfigureTrait, { TPopupConfigureTraitRef } from "../../../components/popup/PopupConfigureTrait";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";
import TraitHelper from "../../../modules/trait-helpers";
import { FaCog, FaEye } from "react-icons/fa";
import PopupPreviewTrait, { TPopupPreviewTraitRef } from "../../../components/popup/PopupPreviewTrait";
import ModulePopup from "../../../modules/popup/popup";
import TraitConfig from "../../../config/trait-config";

export default function PageTraitAdd() {
  const title = "Add New Trait";
  const navigate = useNavigate();
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const user = useContext(AppUserContext);
  const { programmeId, cohortId } = useParams();
  const isValidProgrammeId = user.programmeIds.includes(programmeId);
  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    config: "",
    cohortId: cohortId,
  });
  const popupConfigureTrait = useRef<TPopupConfigureTraitRef>();
  const popupPreviewTraitRef = useRef<TPopupPreviewTraitRef>();

  const handleOnChange = (e) => {
    setContentChanged(true);
    setFormData(d => ({
      ...d,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_DATA", async () => {
      const validation = TraitConfig.isJSONConfigCorrect(formData.config);

      if (!validation.correct) {
        ModulePopup.showAlert({
          title: "Unable to Add",
          description: <>
            <p>Trait Configuration is not configured properly, please rectify and retry again.</p>
            <p>Additional info: <em>{validation.message}</em></p>
          </>
        });
        return;
      }

      setSending(true);
      const res = await ApiTrait.add(formData);

      if (res.status) {
        Notifier.success(res.message);
        await Utils.sleep(2000);
        setSending(false);
        navigate(`${ROUTES.PORTAL_TRAIT}/${programmeId}/${cohortId}`);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };

  if (!isValidProgrammeId) {
    return <PagePortalNotFound />;
  }

  return <main className="form-generic content-generic">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    <PopupConfigureTrait ref={popupConfigureTrait}
      onSubmit={(config) => {
        setFormData(d => ({
          ...d,
          config: TraitHelper.convertDataToJson(config)
        }));
      }}
      scaleJson={formData.config}
    />
    <PopupPreviewTrait ref={popupPreviewTraitRef} traitConfig={formData.config} />

    <div className="cg__actions hide-mobile-down">
      <Link to={`${ROUTES.PORTAL_TRAIT}/${programmeId}/${cohortId}`} className="btn btn-primary" state={location.state} onClick={(e) => {
        if (contentChanged && !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))) {
          e.preventDefault();
        }
      }}>
        <HiArrowLeft/>{" "}Back
      </Link>
    </div>

    <form onSubmit={handleSubmit}>

      <FGInput name="name" id="name" type="text" label="Name*" required autoFocus
        onChange={handleOnChange}
      />

      <FGTextarea name="description" id="description" label="Description"
        onChange={handleOnChange}
      />

      <FGTextarea name="config" id="config" label="Trait Configuration*"
        desc={`This is JSON configuration. Click on "Configure Trait" button below if we want to do it using UI-based configuration instead.`}
        onChange={handleOnChange}
        value={formData.config ? formData.config : ""}
        required
      />

      <div className="mb-4">
        <button type='button' className='btn btn-secondary' onClick={() => {
          popupConfigureTrait.current.show();
        }}>
          <FaCog/>{" "}Configure Trait
        </button>
        {" "}
        <button type='button' className='btn btn-outline-secondary' onClick={(e) => {
          e.preventDefault();
          popupPreviewTraitRef.current.open();
        }}>
          <FaEye/>{" "}Preview Trait
        </button>
      </div>

      <div>
        <button type="submit" className="btn btn-success" disabled={sending}>
          <MdSave/>{" "}Add
        </button>
        {" "}
        <button className="btn btn-primary" onClick={(e) => {
          e.preventDefault();
          ModulePopup.showAlertConfirm({
            title: "Cancel Confirmation",
            description: "Are you sure to cancel? New changes will be discarded.",
            onYes() {
              navigate(`${ROUTES.PORTAL_TRAIT}/${programmeId}/${cohortId}`);
            },
          });
        }}>
          <HiX/>{" "}Cancel
        </button>
      </div>

    </form>

  </main>;
}
