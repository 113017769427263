import FGInput from "../../../components/dumb/FGInput";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import {
  CONST_SELECT_ROLES,
  CONST_SELECT_USER_STATUS,
  DEFAULT_SELECT_OPTION,
  USER_STATUS_ACTIVE,
} from "../../../config/constants";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import AppUserContext from "../../../context/app-user-context";
import ApiGenericData from "../../../modules/api/generic-data";
import Debouncer from "../../../modules/debouncer/debouncer";
import ApiUser from "../../../modules/api/user";
import Notifier from "../../../modules/notifier/notifier";
import Utils from "../../../utils/utils";
import ROLE from "../../../modules/role";
import ApiProgramme from "../../../modules/api/programme";

export default function PageuserRegistManual() {
  const navigate = useNavigate();
  const user = useContext(AppUserContext);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: user.role === ROLE.ADMIN ? ROLE.PROGRAM_ADMIN : ROLE.STUDENT,
    status: USER_STATUS_ACTIVE,
    organization: "",
    studentId: "",
    programme: "",
    avatar: "",
    jobDesk: "",
  });

  const onChange = (e: any) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((data) => ({ ...data, [e.target.name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_DATA", async () => {
      setSending(true);
      const res = await ApiUser.add(formData);

      if (res.status) {
        Notifier.success(res.message);
        await Utils.sleep(2000);
        setSending(false);
        navigate(ROUTES.PORTAL_USERS);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };

  const roleOptions = () => {
    let roles = CONST_SELECT_ROLES;
    if (user.role === ROLE.ADMIN) {
      return roles.filter((role) =>
        [ROLE.ADMIN, ROLE.PROGRAM_ADMIN].some((value) => value === role.value)
      );
    } else {
      return roles.filter((role) =>
        [ROLE.STUDENT, ROLE.SUPERVISOR, ROLE.INDUSTRY_SUPERVISOR].some(
          (value) => value === role.value
        )
      );
    }
  };

  return (
    <div>
      <form className="form-register" onSubmit={handleSubmit}>
        <div className="row" data-comp="PageuserRegistManual">
          <div className="col-md-6">
            <FGInput
              label="Name*"
              name="name"
              value={formData.name}
              onChange={onChange}
              required
              autoFocus
            />
          </div>
          <div className="col-md-6">
            <FGInput
              label="Email*"
              name="email"
              type="email"
              value={formData.email}
              onChange={onChange}
              required
            />
          </div>
          {user.role !== ROLE.ADMIN && (
            <div className="col-md-6">
              <FGInput
                label="Student ID"
                name="studentId"
                value={formData.studentId}
                onChange={onChange}
              />
            </div>
          )}
          <div className="col-md-6">
            <FGInput
              label="Job title"
              name="jobDesk"
              value={formData.jobDesk}
              onChange={onChange}
            />
          </div>
          <div className="col-md-6">
            <FGDropdownApi
              label="Role"
              name="role"
              options={roleOptions()}
              defaultValue={formData.role}
              onChange={onChange}
            />
          </div>

          {user.role !== ROLE.ADMIN && (
            <div className="col-md-6">
              <FGDropdownApi
                label="Organization"
                name="organization"
                defaultValue={formData.organization}
                api={ApiGenericData.selectQuery(
                  "Organization",
                  "organization",
                  {
                    ...DEFAULT_SELECT_OPTION,
                  }
                )}
                onChange={onChange}
              />
            </div>
          )}
          <div className="col-md-6">
            <FGDropdownApi
              label="Programme"
              name="programme"
              defaultValue={formData.programme}
              api={ApiProgramme.getOptions}
              onChange={onChange}
            />
          </div>
          <hr />
          <div className="col-md-12">
            <FGDropdownApi
              variant="normal"
              label="Status"
              desc={`Set to "Inactive" if we'd like to disable this user.`}
              name="status"
              defaultValue={formData.status}
              options={CONST_SELECT_USER_STATUS}
              onChange={onChange}
            />
          </div>
        </div>
        <div>
          <button type="submit" className="btn btn-primary" disabled={sending}>
            Submit Data
          </button>{" "}
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={(e) => {
              e.preventDefault();
              if (window.history.length === 1) {
                navigate(ROUTES.PORTAL_DASHBOARD);
              } else {
                navigate(-1);
              }
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
