/**
 * WARNING!
 * This component exclusively used on <PageProfile />. Check again if we'd like
 * to implement this on other page(s).
 */

import { Link } from "react-router-dom";
import FGToggle from "../dumb/FGToggle";
import { HiOutlineChevronRight } from "react-icons/hi";
import { ROUTES } from "../../routes";
import { useCallback, useContext, useEffect, useState } from "react";
import AppUserContext from "../../context/app-user-context";
import Debouncer from "../../modules/debouncer/debouncer";
import ApiUser from "../../modules/api/user";
import Notifier from "../../modules/notifier/notifier";
import AppUser from "../../modules/app-user/app-user";
import Utils from "../../utils/utils";
import ROLE from "../../modules/role";

export default function AppSettingsList() {
  const user = useContext(AppUserContext);
  const [loading, setLoading] = useState(true);
  const updateSetting = useCallback((e) => {
    Debouncer.execute("UPDATE_SETTING", async () => {
      setLoading(true);

      const name = e.target.name;
      const currentValue = user[name];
      const res = await ApiUser.updateAppSetting({
        modifiedUserId: user.id,
        referenceUserId: user.referenceUserId,
        key: name,
        value: !currentValue
      });

      if (res.status) {
        await Utils.sleep(500);
        await AppUser.refreshUser(["avatar"]);
        Notifier.success(res.message);
      } else {
        Notifier.error(res.message);
      }

      setLoading(false);
    });
  }, [user]);

  // refresh user data when checking notif page
  useEffect(() => {
    AppUser.refreshUser(["avatar"]).then(() => {
      setLoading(false);
    });
  }, []);

  return <ol className={`app-settings-list ${loading ? "asl--loading" : ""}`}>
    {!(user.role === ROLE.ADMIN || user.role === ROLE.PROGRAM_ADMIN) && (
        <li>
          <FGToggle
            label="Notification"
            desc={"When enabled, the system will make a sound if there's new notification."}
            name="notification"
            checked={user.notification}
            onClick={(e) => updateSetting(e)}
            readOnly
          />
        </li>
      )}
    {user.role === ROLE.STUDENT && (
        <li>
          <FGToggle
            label="Submission Reminder"
            desc={"When enabled, the system will send a submission reminder periodically."}
            name="submissionReminder"
            checked={user.submissionReminder}
            onClick={(e) => updateSetting(e)}
            readOnly
          />
        </li>
      )}
    <li>
      <Link to={ROUTES.PORTAL_PROFILE_CHANGE_PASSWORD} className="asl__button">
        <div className="asl__label">Change Password</div>
        <div className="asl__action">
          <HiOutlineChevronRight/>
        </div>
      </Link>
    </li>
  </ol>;
}
