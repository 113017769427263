import { MdSave } from "react-icons/md";
import { ROUTES } from "../../../routes";
import { useEffect, useState } from "react";
import ApiUser from "../../../modules/api/user";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import FGInput from "../../../components/dumb/FGInput";
import usePageTitle from "../../../hooks/use-page-title";
import ApiProgramme from "../../../modules/api/programme";
import Notifier from "../../../modules/notifier/notifier";
import Debouncer from "../../../modules/debouncer/debouncer";
import FGTextarea from "../../../components/dumb/FGTextarea";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";

export default function PageProgrammeEdit() {
  const title = "Edit Programme";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const { _id } = useParams();
  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    programAdminIds: "",
  });

  useEffect(() => {
    ApiProgramme.getById(_id)
      .then((res) => {
        setLoading(false);
        if (res.result === "OK") {
          setFound(true);
          setFormData(res.data);
        } else {
          Notifier.error(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifier.error(error.message);
      });
  }, []);

  const handleOnChange = (e: any) => {
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    Debouncer.execute("UPDATE_DATA", async () => {
      setSending(true);
      ApiProgramme.update(_id, formData)
        .then((res) => {
          setSending(false);
          if (res.result === "OK") {
            setContentChanged(false);
            Notifier.success(res.message);
          } else {
            Notifier.error(res.message);
          }
        })
        .catch((error) => {
          setSending(false);
          Notifier.error(error.message);
        });
    });
  };

  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions hide-mobile-down">
        <Link
          to={ROUTES.PORTAL_PROGRAMMES}
          className="btn btn-primary"
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      {loading && <p className="alert alert-info">Loading data...</p>}

      {!loading && !found && (
        <p className="alert alert-info">Programme not found.</p>
      )}

      {!loading && found && (
        <form onSubmit={handleSubmit}>
          <FGInput
            name="name"
            id="name"
            type="text"
            label="Name*"
            required
            autoFocus
            value={formData.name}
            onChange={handleOnChange}
          />

          <FGDropdownApi
            label="Programme Manager"
            name="programAdminIds"
            closeMenuOnSelect={false}
            defaultValue={formData.programAdminIds}
            api={ApiUser.getProgramAdmins}
            mapper={(programAdmins) =>
              programAdmins.map((pa) => {
                return {
                  label: pa.name,
                  value: pa._id,
                };
              })
            }
            onChange={handleOnChange}
            multiple
          />

          <FGTextarea
            name="description"
            id="description"
            label="Description"
            value={formData.description}
            onChange={handleOnChange}
          />

          <div>
            <button
              type="submit"
              className="btn btn-success"
              disabled={sending}
            >
              <MdSave /> Update
            </button>{" "}
            <Link
              to={ROUTES.PORTAL_PROGRAMMES}
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  contentChanged &&
                  !window.confirm(
                    Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                  )
                ) {
                  e.preventDefault();
                }
              }}
            >
              <HiX /> Cancel
            </Link>
          </div>
        </form>
      )}
    </main>
  );
}
