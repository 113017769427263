import React, { useContext, useEffect, useMemo } from "react";
import { MdLogout, MdOutlineCategory } from "react-icons/md";
import {
  HiOutlineHome,
  HiOutlineUserGroup,
  HiOutlineDocumentText,
  HiOutlineUsers,
  HiOutlineBell,
  HiOutlineUser,
  HiOutlineTrendingUp,
  HiOutlineClock,
  HiOutlineAcademicCap,
  HiOutlineOfficeBuilding,
  HiOutlineMail,
  HiOutlineDocumentDuplicate,
  HiOutlineNewspaper,
} from "react-icons/hi";
import { AiOutlineSchedule, AiOutlineSelect } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import AppUserContext from "../../context/app-user-context";
import { filterNav } from "../../roles";
import { ROUTES } from "../../routes";
import { PortalContext } from "../../context/portal-context";
import classNames from "classnames";
import SFX from "../../modules/sfx";
import ModulePopup from "../../modules/popup/popup";

export type TNavItem = {
  title?: string;
  info?: string;
  url?: string;
  icon?: any;
  children?: TNavItem[];
  separator_top?: boolean;
  separator_bottom?: boolean;
  show?: boolean;
  is_notif?: boolean;
  /** this is used for showing bottom fixed nav on mobile for quick access link */
  is_bottom_nav?: boolean;
  /** no need to check role in order to show this nav item */
  nocheck?: boolean;
};

export function getNavList(): TNavItem[] {
  return [
    {
      title: "Dashboard",
      info: "Click to open dashboard page",
      url: ROUTES.PORTAL_DASHBOARD,
      icon: <HiOutlineHome />,
      is_bottom_nav: true,
    },

    {
      nocheck: true,
      separator_bottom: true,
    },

    // Student nav
    {
      title: "My Profile",
      info: "Click to open profile page",
      url: ROUTES.PORTAL_PROFILE,
      icon: <HiOutlineUser />,
      is_bottom_nav: true,
    },
    {
      title: "My Reports",
      info: "Click to open my reports page",
      url: ROUTES.PORTAL_REPORT,
      icon: <HiOutlineDocumentText />,
      is_bottom_nav: true,
    },
    {
      title: "My Progress",
      info: "Click to open my progress page",
      url: ROUTES.PORTAL_PROGRESS,
      icon: <HiOutlineTrendingUp />,
      is_bottom_nav: true,
    },
    {
      title: "Peers",
      info: "Click to open peers page",
      url: ROUTES.PORTAL_PEERS,
      icon: <HiOutlineUserGroup />,
    },
    {
      title: "My Group",
      info: "Click to open my group page",
      url: ROUTES.PORTAL_MY_GROUP,
      is_bottom_nav: true,
      icon: <HiOutlineUserGroup />,
    },
    {
      title: "Notifications",
      info: "Click to open notifications page",
      url: ROUTES.PORTAL_NOTIFICATION,
      icon: <HiOutlineBell />,
      is_notif: true,
      is_bottom_nav: true,
    },

    // Admin nav
    {
      title: "Manage Users",
      info: "Click to open manage users page",
      url: ROUTES.PORTAL_USERS,
      icon: <HiOutlineUsers />,
      is_bottom_nav: true,
      separator_top: true,
    },
    {
      title: "Manage Programmes",
      info: "Click to open manage programmes page",
      url: ROUTES.PORTAL_PROGRAMMES,
      icon: <HiOutlineNewspaper />,
      is_bottom_nav: true,
      separator_top: true,
    },
    {
      title: "Select Programme",
      info: "Click to open select programme page",
      url: ROUTES.PORTAL_SELECT_PROGRAMME,
      icon: <AiOutlineSelect />,
      is_bottom_nav: true,
      separator_top: true,
    },
    {
      title: "Manage Group",
      info: "Click to open manage groups page",
      url: ROUTES.PORTAL_GROUPS,
      icon: <HiOutlineUserGroup />,
      is_bottom_nav: true,
    },
    {
      title: "Manage Year",
      info: "Click to open manage year page",
      url: ROUTES.PORTAL_YEARS,
      icon: <AiOutlineSchedule />,
      is_bottom_nav: true,
    },
    {
      title: "Manage Organizations",
      info: "Click to open manage organizations page",
      url: ROUTES.PORTAL_ORGANIZATION,
      icon: <HiOutlineOfficeBuilding />,
    },
    // {
    //   title: "Manage Trait",
    //   info: "Click to open manage trait page",
    //   url: ROUTES.PORTAL_TRAIT,
    //   icon: <MdOutlineCategory />
    // },
    {
      title: "Manage Submission",
      info: "Click to open manage submission",
      url: ROUTES.PORTAL_SUBMISSION,
      icon: <HiOutlineDocumentDuplicate />,
    },
    {
      title: "Submission Date",
      info: "Click to open manage submission date",
      url: ROUTES.PORTAL_SUBMISSION_DATE,
      icon: <HiOutlineClock />,
    },
    {
      title: "Schedule Reminder",
      info: "Click to open manage schedule reminder",
      url: ROUTES.PORTAL_SCHEDULE_REMINDER,
      icon: <AiOutlineSchedule />,
    },
    {
      title: "Mail Templates",
      info: "Click to manage mail templates",
      url: ROUTES.PORTAL_MAIL_TEMPLATE,
      icon: <HiOutlineMail />,
      separator_top: true,
    },

    {
      nocheck: true,
      separator_bottom: true,
    },

    // All roles have this...
    {
      title: "Logout",
      info: "Click to logout",
      url: ROUTES.AUTH_LOGOUT,
      icon: <MdLogout />,
      nocheck: true,
    },
  ];
}

export type TPortalNavProps = {
  expanded?: boolean;
  isBottomNav?: boolean;
};

export default function PortalNav(props: TPortalNavProps) {
  const user = useContext(AppUserContext);
  const navigate = useNavigate();
  const { setPortalSetting, notifCount } = useContext(PortalContext);

  useEffect(() => {
    if (props.isBottomNav) return;
    const timer = setTimeout(() => {
      const element = document.querySelector(".menu-link.active");
      element?.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }, 250);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (props.isBottomNav) return;
    if (notifCount === 0) return;
    if (user.notification) {
      SFX.play("notif");
    }
  }, [notifCount]);

  const currNav = useMemo(() => {
    const navList = getNavList().filter((item) => {
      return (
        filterNav(user, item) &&
        item.show !== false &&
        (props.isBottomNav ? item.is_bottom_nav : true)
      );
    });
    const hasNotification = navList.some((item) => item.is_notif);

    return navList;
  }, []);

  useEffect(() => {
    setPortalSetting((d) => ({
      ...d,
      bottom_nav: currNav.length > 0,
    }));
  }, []);

  return (
    <div
      className={classNames([
        "portal-nav",
        { "portal-nav-bottom": props.isBottomNav },
        {
          "portal-nav-bottom--hide": props.isBottomNav && currNav.length === 0,
        },
      ])}
      data-comp="PortalNav"
    >
      {currNav.map((item, i) => (
        <React.Fragment key={i}>
          {item.separator_top && <div className="menu-sep menu-sep--top"></div>}

          {item.title && (
            <NavLink
              title={item.info}
              to={item.url}
              onClick={(e) => {
                if (props.isBottomNav) return;
                if (item.url === ROUTES.AUTH_LOGOUT) {
                  e.preventDefault();
                  ModulePopup.showAlertConfirm({
                    title: "Confirm Logout",
                    description: "Are you sure you want to logout?",
                    onYes() {
                      setPortalSetting((d) => ({
                        ...d,
                        mobile_nav_open: false,
                      }));
                      navigate(ROUTES.AUTH_LOGOUT);
                    },
                  });
                  return;
                }
                setPortalSetting((d) => ({ ...d, mobile_nav_open: false }));
              }}
              className={({ isActive }) => {
                return classNames([
                  "menu-link",
                  { "menu-link--notif": item.is_notif },
                  { "menu-link--has-notif": item.is_notif && notifCount > 0 },
                  { active: isActive && item.url !== "" },
                ]);
              }}
            >
              {item.icon}
              <span
                className={classNames([
                  "menu-link__name",
                  { "menu-link__name--collapsed": !props.expanded },
                ])}
              >
                {item.title}
                {item.is_notif && notifCount > 0 && (
                  <span className="notif-counter">{notifCount}</span>
                )}
              </span>
            </NavLink>
          )}

          {Array.isArray(item.children) &&
            item.children.map((childItem, j) => (
              <React.Fragment key={`${i}-${j}`}></React.Fragment>
            ))}

          {item.separator_bottom && (
            <div className="menu-sep menu-sep--bottom"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
