import { ROUTES } from "../../../routes";
import { Dropdown } from "react-bootstrap";
import { HiArrowLeft } from "react-icons/hi";
import { useContext, useState } from "react";
import ApiCohort from "../../../modules/api/cohort";
import ModulePopup from "../../../modules/popup/popup";
import usePageTitle from "../../../hooks/use-page-title";
import Notifier from "../../../modules/notifier/notifier";
import Debouncer from "../../../modules/debouncer/debouncer";
import AppUserContext from "../../../context/app-user-context";
import { usePortalSettingSet } from "../../../context/portal-context";
import { MdSearch, MdDelete, MdModeEditOutline } from "react-icons/md";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import ServerSideCohortDataTable from "../../../components/common/ServerSideCohortDataTable";

export default function PageCohortList() {
  const title = "Manage Cohorts";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const user = useContext(AppUserContext);
  const { programmeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [programme, setProgramme] = useState<any>(null);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [orderBy, setOrderBy] = useState("endDate");
  const [orderDirection, setOrderDirection] = useState("desc");
  const [selectedCohorts, setSelectedCohorts] = useState<any[]>([]);

  const back = () => {
    navigate(location.state?.from ?? ROUTES.PORTAL_DASHBOARD);
  };

  const onSort = (column: any, sortDirection: string, _) => {
    setOrderBy(column.id);
    setOrderDirection(sortDirection);
  };

  if (!user.programmeIds.includes(programmeId)) {
    return <PagePortalNotFound />;
  }
  return (
    <main className="form-generic content-generic content-generic--fluid">
      <h1 className="fs-title hide-mobile-down" style={{ marginBottom: 7 }}>
        {title}
      </h1>
      <div className="programme-title" style={{ marginBottom: 15 }}>
        Programme: {programme && <span>{programme.name}</span>}
      </div>
      <div className="cg__actions">
        <div className="cg__action-buttons">
          <button className="btn btn-primary" onClick={back}>
            <HiArrowLeft /> Back
          </button>
          <Link
            to={`${ROUTES.PORTAL_COHORT_ADD}/${programmeId}`}
            className="btn btn-primary"
            state={location.state}
          >
            Add New Cohort
          </Link>
          <button
            className="btn btn-outline-primary"
            onClick={() => setRefresh((i) => !i)}
          >
            Refresh
          </button>{" "}
          {selectedCohorts.length > 0 && (
            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                const deletedIds = selectedCohorts
                  .filter((i) => typeof i._id !== "undefined")
                  .map((i) => i._id);
                ModulePopup.showAlertConfirm({
                  title: "Delete Confirmation",
                  description: (
                    <>
                      <p>
                        Are you sure to delete {deletedIds.length} cohorts?
                        <br /> This action can't be undone.
                      </p>
                    </>
                  ),
                  onYes: () =>
                    Debouncer.execute("DELETE_COHORTS", async () => {
                      await ApiCohort.deleteByIds(deletedIds)
                        .then((res) => {
                          if (res.result === "OK") {
                            setRefresh((i) => !i);
                            setSelectedCohorts([]);
                            Notifier.success(res.message);
                          } else {
                            Notifier.error(res.message);
                          }
                        })
                        .catch((error) => {
                          Notifier.error(error.message);
                        });
                    }),
                });
              }}
            >
              Delete Selected
            </button>
          )}
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <ServerSideCohortDataTable
        api={ApiCohort.pagingSearch}
        search={search}
        selectableRows
        onSelectedRowsChange={({ selectedRows }) =>
          setSelectedCohorts(selectedRows)
        }
        orderBy={orderBy}
        orderDirection={orderDirection}
        onSort={onSort}
        columns={[
          {
            id: "name",
            name: "Name",
            sortable: true,
            cell: (row) => (
              <Link
                className="title-column"
                to={`${ROUTES.PORTAL_COHORTS}/manage/${programmeId}/${row._id}`}
                state={location.state}
              >
                {" "}
                {row.name}
              </Link>
            ),
          },
          {
            id: "startDate",
            name: "Start Date",
            sortable: true,
            cell: (row) =>
              new Date(row.startDate).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              }),
          },
          {
            id: "endDate",
            name: "End Date",
            sortable: true,
            cell: (row) =>
              new Date(row.endDate).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              }),
          },
          {
            id: "description",
            name: "Description",
            sortable: true,
            cell: (row) => row.description,
          },
          {
            id: "action",
            name: "Action",
            button: true,
            cell: (row) => (
              <div>
                <Dropdown align={"end"} drop={"down"}>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className="btn-sm"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link
                      className="dropdown-item"
                      to={`${ROUTES.PORTAL_COHORTS}/manage/${programmeId}/${row._id}`}
                      state={location.state}
                    >
                      <MdModeEditOutline /> Manage
                    </Link>

                    <Dropdown.Divider />

                    <Link
                      className="dropdown-item"
                      to={`${ROUTES.PORTAL_COHORTS}/edit/${programmeId}/${row._id}`}
                      state={location.state}
                    >
                      <MdModeEditOutline /> Edit
                    </Link>

                    <Dropdown.Divider />

                    <button
                      className="dropdown-item btn-danger"
                      onClick={() => {
                        ModulePopup.showAlertConfirm({
                          title: "Delete Confirmation",
                          description: (
                            <>
                              <p>
                                Are you sure to delete this cohort (
                                <strong>{row.name}</strong>)?
                                <br /> This action can't be undone.
                              </p>
                            </>
                          ),
                          onYes: () =>
                            Debouncer.execute("DELETE_PROGRAMME", () =>
                              ApiCohort.deleteById(row._id)
                                .then((res) => {
                                  if (res.result === "OK") {
                                    setRefresh((i) => !i);
                                    Notifier.success(res.message);
                                  } else {
                                    Notifier.error(res.message);
                                  }
                                })
                                .catch((error) => Notifier.error(error.message))
                            ),
                        });
                      }}
                    >
                      <MdDelete /> Delete
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ),
          },
        ]}
        refresh={refresh}
        programmeId={programmeId}
        setProgramme={setProgramme}
      />
    </main>
  );
}
