import { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { Dropdown } from "react-bootstrap";
import ModulePopup from "../../../modules/popup/popup";
import usePageTitle from "../../../hooks/use-page-title";
import ApiProgramme from "../../../modules/api/programme";
import Notifier from "../../../modules/notifier/notifier";
import Debouncer from "../../../modules/debouncer/debouncer";
import { usePortalSettingSet } from "../../../context/portal-context";
import { MdSearch, MdDelete, MdModeEditOutline } from "react-icons/md";
import ServerSideProgrammeDataTable from "../../../components/common/ServerSideProgrammeDataTable";

export default function PageProgrammeList() {
  const title = "Manage Programmes";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedProgrammes, setSelectedProgrammes] = useState<any[]>([]);

  return (
    <main className="form-generic content-generic content-generic--fluid">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions">
        <div className="cg__action-buttons">
          <Link to={ROUTES.PORTAL_PROGRAMMES_ADD} className="btn btn-primary">
            Add New Programme
          </Link>
          <button
            className="btn btn-outline-primary"
            onClick={() => setRefresh((i) => !i)}
          >
            Refresh
          </button>{" "}
          {selectedProgrammes.length > 0 && (
            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                const deletedIds = selectedProgrammes
                  .filter((i) => typeof i._id !== "undefined")
                  .map((i) => i._id);
                ModulePopup.showAlertConfirm({
                  title: "Delete Confirmation",
                  description: (
                    <>
                      <p>
                        Are you sure to delete {deletedIds.length} programmes?
                        <br /> This action can't be undone.
                      </p>
                    </>
                  ),
                  onYes: () =>
                    Debouncer.execute("DELETE_PROGRAMMES", async () => {
                      await ApiProgramme.deleteByIds(deletedIds)
                        .then((res) => {
                          if (res.result === "OK") {
                            setRefresh((i) => !i);
                            setSelectedProgrammes([]);
                            Notifier.success(res.message);
                          } else {
                            Notifier.error(res.message);
                          }
                        })
                        .catch((error) => {
                          Notifier.error(error.message);
                        });
                    }),
                });
              }}
            >
              Delete Selected
            </button>
          )}
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <ServerSideProgrammeDataTable
        api={ApiProgramme.pagingSearch}
        search={search}
        selectableRows
        onSelectedRowsChange={({ selectedRows }) =>
          setSelectedProgrammes(selectedRows)
        }
        columns={[
          {
            id: "name",
            name: "Name",
            selector: (row) => (
              <Link className="title-column" to={`edit/${row._id}`}>
                {row.name}
              </Link>
            ),
            sortable: true,
          },
          {
            id: "description",
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
          },
          {
            id: "action",
            name: "Action",
            button: true,
            cell: (row) => (
              <div>
                <Dropdown align={"end"} drop={"down"}>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className="btn-sm"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link className="dropdown-item" to={`edit/${row._id}`}>
                      <MdModeEditOutline /> Edit
                    </Link>

                    <Dropdown.Divider />

                    <button
                      className="dropdown-item btn-danger"
                      onClick={() => {
                        ModulePopup.showAlertConfirm({
                          title: "Delete Confirmation",
                          description: (
                            <>
                              <p>
                                Are you sure to delete this programme (
                                <strong>{row.name}</strong>)?
                                <br /> This action can't be undone.
                              </p>
                            </>
                          ),
                          onYes: () =>
                            Debouncer.execute("DELETE_PROGRAMME", () =>
                              ApiProgramme.deleteById(row._id)
                                .then((res) => {
                                  if (res.result === "OK") {
                                    setRefresh((i) => !i);
                                    Notifier.success(res.message);
                                  } else {
                                    Notifier.error(res.message);
                                  }
                                })
                                .catch((error) => Notifier.error(error.message))
                            ),
                        });
                      }}
                    >
                      <MdDelete /> Delete
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ),
          },
        ]}
        refresh={refresh}
        programAdminId={null}
      />
    </main>
  );
}
