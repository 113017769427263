import { useEffect, useState } from "react";
import Notifier from "../../modules/notifier/notifier";
import DataTable, { TableColumn } from "react-data-table-component";
import { TCohortPagingSearchParam } from "../../modules/api/cohort";

export type TServerSideCohortDTApiResult = {
  data: any[];
  total: number;
  programme: any;
};

export type TServerSideCohortDataTableProps = {
  api: (
    opts: TCohortPagingSearchParam
  ) => Promise<TServerSideCohortDTApiResult>;
  columns: TableColumn<any>[];
  refresh?: boolean;
  search?: string;
  searchDelay?: number;
  selectableRows?: boolean;
  orderBy?: string;
  orderDirection?: string;
  setProgramme?: (value: any) => void;
  onSort?: (column: any, sortDirection: string, event: any) => void;
  onSelectedRowsChange?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void;
  programmeId: string;
  mapper?: (item: any) => any;
};

export default function ServerSideCohortDataTable(
  props: TServerSideCohortDataTableProps
) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    let timers = setTimeout(
      () => {
        setCurrentPage(1);
        setKeywords(props.search);
      },
      typeof props.searchDelay !== "number" ? 500 : props.searchDelay
    );
    return () => clearTimeout(timers);
  }, [props.search]);

  useEffect(() => {
    setLoading(true);
    props
      .api({
        programmeId: props.programmeId,
        name: props.search,
        orderBy: props.orderBy,
        orderDirection: props.orderDirection,
        page: currentPage,
        perPage: rowPerPage,
      })
      .then((res) => {
        setLoading(false);
        setTotalRows(res.total);
        setItems(props.mapper ? res.data.map(props.mapper) : res.data);
        if (props.setProgramme) {
          props.setProgramme(res.programme);
        }
      })
      .catch((exception) => {
        setLoading(false);
        Notifier.error(exception.message);
      });
  }, [
    keywords,
    currentPage,
    rowPerPage,
    props.refresh,
    props.orderBy,
    props.orderDirection,
  ]);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (rowPerPage: number, page: number) => {
    setRowPerPage(rowPerPage);
    setCurrentPage(page);
  };

  return (
    <div className="basic-rdt" data-comp="ServerSideProgrammeDataTable">
      <DataTable
        data={items}
        defaultSortFieldId={props.orderBy}
        defaultSortAsc={props.orderDirection === `asc`}
        sortServer={true}
        onSort={props.onSort}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowPerPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        columns={props.columns}
        selectableRows={props.selectableRows}
        onSelectedRowsChange={props.onSelectedRowsChange}
      />
      {loading && (
        <div className="ssd__loading">
          <div className="ssd__loading__text">Loading...</div>
        </div>
      )}
    </div>
  );
}
