import { Link, useParams, useLocation } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { ROUTES } from "../../../routes";
import Notifier from "../../../modules/notifier/notifier";
import { MdSave } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";
import Debouncer from "../../../modules/debouncer/debouncer";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import { CONST_SELECT_SUBMISSION_TYPES, SUBMISSION_WL } from "../../../config/constants";
import ApiSubmissionDate from "../../../modules/api/submission-date";
import AppUserContext from "../../../context/app-user-context";
import ModuleValidation from "../../../modules/validation";
import DateRange from "../../../components/form/DateRange";
import FGField from "../../../components/dumb/FGField";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";

export default function PageSubmissionDateEdit() {
  const title = "Edit Submission Date";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const user = useContext(AppUserContext);
  const { programmeId, cohortId, _id } = useParams();
  const isValidProgrammeId = user.programmeIds.includes(programmeId);
  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [found, setFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    type: SUBMISSION_WL,
    from: "",
    to: ""
  });

  useEffect(() => {
    if (!isValidProgrammeId) return;
    ApiSubmissionDate.get(_id).then(res => {
      if (res.status) {
        setFound(true);
        setFormData({
          type: res.data.type,
          from: res.data.from,
          to: res.data.to
        });
      } else {
        setFound(false);
        Notifier.error(res.message);
      }

      setLoading(false);
    })
  }, []);

  const handleOnChange = (e) => {
    setContentChanged(true);
    setFormData(d => ({
      ...d,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Debouncer.execute("UPDATE_DATA", async () => {
      const validation = ModuleValidation.isSubmissionDateValid(formData);

      if (!validation.valid) {
        Notifier.error(validation.message);
        return;
      }

      setSending(true);
      const res = await ApiSubmissionDate.updateById(_id, {
        ...formData,
        modifiedUserId: user.id
      });

      if (res.status) {
        setSending(false);
        setContentChanged(false);
        Notifier.success(res.message);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };

  if (!isValidProgrammeId) {
    return <PagePortalNotFound />
  }
  return <main className="form-generic content-generic">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    <div className="cg__actions hide-mobile-down">
      <Link to={`${ROUTES.PORTAL_SUBMISSION_DATE}/${programmeId}/${cohortId}`} className="btn btn-primary" state={location.state} onClick={(e) => {
        if (contentChanged && !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))) {
          e.preventDefault();
        }
      }}>
        <HiArrowLeft/>{" "}Back
      </Link>
    </div>

    {loading && <p className="alert alert-info">
      Loading data...
    </p>}

    {!loading && !found && <p className="alert alert-info">
      Submission date not found.
    </p>}

    {!loading && found && <form onSubmit={handleSubmit}>

      <FGField variant="normal">
        <DateRange
          default_start_date={new Date(formData.from)}
          default_end_date={new Date(formData.to)}
          onStartEndDateSelected={(start, end) => {
            setFormData(s => ({
              ...s,
              from: start.toISOString(),
              to: end.toISOString()
            }));
          }}
        />
      </FGField>

      <FGDropdownApi
        variant="normal"
        label="Submission Type"
        name="type"
        defaultValue={formData.type}
        options={CONST_SELECT_SUBMISSION_TYPES}
        onChange={handleOnChange}
      />

      <div>
        <button type="submit" className="btn btn-success" disabled={sending}>
          <MdSave/>{" "}Update
        </button>
        {" "}
        <Link to={`${ROUTES.PORTAL_SUBMISSION_DATE}/${programmeId}/${cohortId}`} className="btn btn-primary" state={location.state} onClick={(e) => {
          if (contentChanged && !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))) {
            e.preventDefault();
          }
        }}>
          <HiX/>{" "}Cancel
        </Link>
      </div>

    </form>}

  </main>;
}
