import { useContext, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";
import { ROUTES } from "../../../routes";
import { MdDelete, MdModeEditOutline, MdSearch } from "react-icons/md";
import { Dropdown } from "react-bootstrap";
import Debouncer from "../../../modules/debouncer/debouncer";
import ServerSideGenericDataTable from "../../../components/common/ServerSideGenericDataTable";
import ApiGenericData from "../../../modules/api/generic-data";
import Notifier from "../../../modules/notifier/notifier";
import ApiTrait from "../../../modules/api/trait";
import ModulePopup from "../../../modules/popup/popup";
import AppUserContext from "../../../context/app-user-context";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";
import { HiArrowLeft } from "react-icons/hi";
import { FaFileExport } from "react-icons/fa";
import { BiImport } from "react-icons/bi";
import PopupImportTraits, {
  TPopupImportTraitsRef,
} from "../../../components/popup/PopupImportTraits";

export default function PageTrait() {
  const title = "Manage Trait";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const user = useContext(AppUserContext);
  const { programmeId, cohortId } = useParams();
  const isValidProgrammeId = user.programmeIds.includes(programmeId);
  const refPopupImportTraits = useRef<TPopupImportTraitsRef>();
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState(null);

  const onImportClick = () => {
    refPopupImportTraits.current.show();
  };

  const onExportClick = () => {
    ModulePopup.showAlertConfirm({
      title: "Confirmation",
      description: <p>Do you want to export all traits?</p>,
      onYes: exportTrait,
    });
  };

  const exportTrait = async () => {
    const { data = [], totalCount = 0, cohort } = tableData;
    if (!totalCount) {
      Notifier.warn("There is no trait to export.");
      return;
    }
    let exportedTraits = [];
    if (totalCount === data.length) {
      exportedTraits = data;
    } else {
      const { status, message, data } = await ApiGenericData.getAll(
        `Trait`,
        cohortId
      );
      if (!status) {
        Notifier.error(message);
        return;
      }
      if (!data.length) {
        Notifier.warn("There is no trait to export.");
        return;
      }
      exportedTraits = data;
    }
    exportedTraits = exportedTraits.map((i) => {
      delete i._id;
      return i;
    });
    const blob = new Blob([JSON.stringify(exportedTraits)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${cohort.programme.name ?? ""}_${cohort.name}_Traits.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const getChildData = (data: any) => {
    setTableData(data);
  };

  if (!isValidProgrammeId) {
    return <PagePortalNotFound />;
  }
  return (
    <main className="form-generic content-generic content-generic--fluid">
      <h1 className="fs-title hide-mobile-down">{title}</h1>
      {tableData && tableData.cohort && (
        <p>
          Cohort: <strong>{tableData.cohort.name}</strong>
          <br />
          Programme: <strong>{tableData.cohort.programme?.name ?? ""}</strong>
        </p>
      )}
      <div className="cg__actions">
        <div className="cg__action-buttons">
          <Link
            to={`${ROUTES.PORTAL_COHORTS}/manage/${programmeId}/${cohortId}`}
            className="btn btn-primary"
            state={location.state}
          >
            <HiArrowLeft /> Back
          </Link>
          <Link
            to={`${ROUTES.PORTAL_TRAIT}/add/${programmeId}/${cohortId}`}
            className="btn btn-primary"
            state={location.state}
          >
            Add New Trait
          </Link>{" "}
          <button
            className="btn trait-btn btn-import-trait"
            onClick={onImportClick}
          >
            <BiImport />
            Import{" "}
          </button>
          <button
            className="btn trait-btn btn-export-trait"
            onClick={onExportClick}
          >
            <FaFileExport /> Export
          </button>
          <button
            className="btn btn-outline-primary"
            onClick={() => setRefresh((i) => !i)}
          >
            Refresh
          </button>
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <PopupImportTraits
        ref={refPopupImportTraits}
        cohortId={cohortId}
        onImport={() => setRefresh((i) => !i)}
      />

      <ServerSideGenericDataTable
        refresh={refresh}
        api={ApiTrait.pagingSearch}
        cohortId={cohortId}
        search={search}
        columns={[
          {
            id: "name",
            name: "Name",
            cell: (row) => row.name,
          },
          {
            id: "description",
            name: "Description",
            cell: (row) => row.description,
          },
          {
            name: "Action",
            button: true,
            cell: (row) => (
              <div>
                <Dropdown align={"end"} drop={"down"}>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className="btn-sm"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link
                      className="dropdown-item"
                      to={`${ROUTES.PORTAL_TRAIT}/edit/${programmeId}/${cohortId}/${row._id}`}
                      state={location.state}
                    >
                      <MdModeEditOutline /> Edit
                    </Link>

                    <Dropdown.Divider />

                    <button
                      className="dropdown-item btn-danger"
                      onClick={() => {
                        ModulePopup.showAlertConfirm({
                          title: "Delete Confirmation",
                          description: (
                            <>
                              <p>
                                Are you sure to delete this?
                                <br /> This action can't be undone. All data
                                that belongs to this trait will be broken.
                              </p>
                            </>
                          ),
                          onYes: () =>
                            Debouncer.execute("DELETE_ITEM", async () => {
                              ApiGenericData.remove(row._id).then((res) => {
                                if (res.status) {
                                  setRefresh((i) => !i);
                                  Notifier.success(res.message);
                                } else {
                                  Notifier.error(res.message);
                                }
                              });
                            }),
                        });
                      }}
                    >
                      <MdDelete /> Delete
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ),
          },
        ]}
        getChildData={getChildData}
      />
    </main>
  );
}
