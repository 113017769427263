import { Link } from 'react-router-dom'
import { HiPlus } from 'react-icons/hi'
import { useState, useEffect, useContext } from 'react'
import { ROUTES } from '../../../routes'
import PDChart from '../../../components/portal/PDChart'
import ApiSubmission, { TStatisticResult } from '../../../modules/api/submission'
import ApiSubmissionDate from '../../../modules/api/submission-date'
import useViewport from '../../../hooks/use-viewport'
import LoadingData from '../../../components/portal/LoadingData'
import ReportItemListing from '../../../components/portal/ReportItemListing'
import DashboardNotificationItems from '../../../components/portal/DashboardNotificationItems'
import { LABEL_UNKNOWN_TRAIT } from '../../../config/constants'

type TPageDashboardStudentProps = {
  user: any;
};

export default function PageDashboardStudent(props: TPageDashboardStudentProps) {
  const viewport = useViewport();
  const [loading, setLoading] = useState(true);
  const [nextDeadline, setNextDeadline] = useState({
    wl: "-",
    pd: "-"
  });
  const [statistics, setStatistics] = useState<TStatisticResult>();

  useEffect(() => {
    (async () => {
      if (props.user.activeCohort._id && props.user.activeCohort._id.trim()) {
        const resNextDeadlineApi = await ApiSubmissionDate.getSubmissionReminder(props.user.activeCohort._id, props.user.referenceUserId);
        setNextDeadline(resNextDeadlineApi);

        const resStat = await ApiSubmission.statistics(props.user.activeCohort._id, props.user.referenceUserId);
        setStatistics(resStat);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div className='row' data-comp='PageDashboardStudent'>
      <div className='col-12 col-xxl-9'>
        <div className=' row flex-column flex-lg-row'>

          <div className='col-xl-6 dashboard-container'>
            <div className='d-flex align-items-center justify-content-between header-details'>
              <h1 className='fs-title'>My Reports</h1>
              <Link to={ROUTES.PORTAL_REPORT} className='btn'>See All</Link>
            </div>

            <div className='dashboard-report overflow-visible rounded-2 d-flex flex-column d-xl-flex'>

              <ReportItemListing
                isReportPage={true}
                userId={props.user.referenceUserId}
                cohortId={props.user.activeCohort._id}
                itemsPerPage={3}
                pagination={false}
                className="mb-3"
              />

              <div className='dr__link content-gap'>
                <Link to={ROUTES.PORTAL_REPORT_WL} className='btn report__add-report-button d-flex flex-column justify-content-center align-items-center flex-fill'>
                  <HiPlus className='fs-1'/>
                  <p>Add Workplace {viewport.isMobile && <br/>} Learning Report</p>
                  <span>{nextDeadline.wl}</span>
                </Link>

                <Link to={ROUTES.PORTAL_REPORT_PD} className='btn report__add-report-button d-flex flex-column justify-content-center align-items-center flex-fill'>
                  <HiPlus className='fs-1'/>
                  <p>Add Professional {viewport.isMobile && <br/>} Development Report</p>
                  <span>{nextDeadline.pd}</span>
                </Link>
              </div>
            </div>
          </div>

          <div className='content-gap content-gap__my-progress col-xl-6'>
            <div className='d-flex justify-content-between align-items-center header-details'>
              <h1 className='fs-title'>My Progress</h1>
              <Link to={ROUTES.PORTAL_PROGRESS} className='btn'>See All</Link>
            </div>
            <div className='dashboard-report dr-chart d-flex'>

              <LoadingData show={loading} text="Loading statistics..." />

              {!loading &&  statistics?.items.length === 0 && <>
                <p className="alert alert-not-found">Statistic(s) unavailable.</p>
              </>}
              <div className='drc__container'>
              {!loading && statistics?.parsedTraits.filter(trait => {
                return trait.name !== LABEL_UNKNOWN_TRAIT;
              }).slice(0, 2).map(trait => <div className='dr__chart' key={trait.traitId}>
                <h3 className='fs-5 fw-bold'>{trait.name}</h3>
                <PDChart
                  color={trait.color}
                  labelX={trait.month}
                  scale={trait.scale}
                  scaleNextMonth={trait.scaleNextMonth}
                  goal={trait.scaleEndGoal}
                  goalMaxLine={trait.goalMaxLine}
                  />
              </div>)}
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className='col-12 col-xxl-3 content-gap'>
        <div className='d-flex notification flex-column'>
          <div className='d-flex justify-content-between align-items-center header-details'>
            <h1 className='fs-title'>Notifications</h1>
            <Link to={ROUTES.PORTAL_NOTIFICATION} className='btn'>See All</Link>
          </div>
          <div className='d-flex flex-column gap-3'>
            <DashboardNotificationItems/>
          </div>
        </div>
      </div>
    </div>
  )
}
