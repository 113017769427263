import { Link, useParams, useLocation } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { ROUTES } from "../../../routes";
import BasicDataTable from "../../../components/common/BasicDataTable";
import Utils from "../../../utils/utils";
import DateTime from "../../../components/common/DateTime";
import { Dropdown } from "react-bootstrap";
import { MdDelete, MdModeEditOutline, MdSearch } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from "react";
import { usePortalSettingSet } from "../../../context/portal-context";
import Debouncer from "../../../modules/debouncer/debouncer";
import AppUserContext from "../../../context/app-user-context";
import Notifier from "../../../modules/notifier/notifier";
import ApiSubmissionDate, { TSubmissionDateItem } from "../../../modules/api/submission-date";
import ModulePopup from "../../../modules/popup/popup";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";
import { HiArrowLeft } from "react-icons/hi";

export default function PageSubmissionDate() {
  const title = "Manage Submission Dates";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const { programmeId, cohortId } = useParams();
  const user = useContext(AppUserContext);
  const isValidProgrammeId = user.programmeIds.includes(programmeId);
  const [refresh, setRefresh] = useState(false);
  const refLoading = useRef(false);
  const [cohort, setCohort] = useState(null);
  const [data, setData] = useState<TSubmissionDateItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loadingTable, setLoadingTable] = useState(true);

  useEffect(() => {
    if (!isValidProgrammeId) return;
    if (refLoading.current) return;
    refLoading.current = true;
    setLoadingTable(true);
    ApiSubmissionDate.getByCohortId(cohortId).then(({ data, cohort }) => {
      refLoading.current = false;
      setCohort(cohort);
      setData(data);
      setLoadingTable(false);
    });
  }, [refresh]);

  if (
    !isValidProgrammeId ||
    (cohort && (!cohort.programme || cohort.programme._id !== programmeId))
  ) {
    return <PagePortalNotFound />;
  }
  return <main className="form-generic content-generic content-generic--fluid">

    <h1 className="fs-title hide-mobile-down">{title}</h1>
    {cohort && (
      <p>
        Cohort: <strong>{cohort.name}</strong>
        <br />
        Programme: <strong>{cohort.programme?.name ?? ""}</strong>
      </p>
    )}
    <div className="cg__actions">
      <div className="cg__action-buttons">
        <Link
            to={`${ROUTES.PORTAL_COHORTS}/manage/${programmeId}/${cohortId}`}
            className="btn btn-primary"
            state={location.state}
        >
          <HiArrowLeft /> Back
        </Link>
        <Link to={`${ROUTES.PORTAL_SUBMISSION_DATE_ADD}/${programmeId}/${cohortId}`} className="btn btn-primary" state={location.state}>
          Add New Submission Date
        </Link>
        {" "}
        <button className="btn btn-outline-primary"
          onClick={() => setRefresh(i => !i)}
        >
          Refresh
        </button>
      </div>
    </div>

    <BasicDataTable
      defaultSortFieldId="period"
      defaultSortAsc={false}
      loading={loadingTable}
      columns={[
        {
          id: "period",
          name: "Period",
          cell: (row) => <>
            <DateTime date={row.from} format="DD MMM" />
            {" "}-{" "}
            <DateTime date={row.to} format="DD MMM YYYY" />
          </>,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNatural("to")
        },
        {
          id: "type",
          name: "Submission Type",
          cell: (row) => row.type,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNatural("type")
        },
        {
          id: "modifiedDate",
          name: "Last update",
          cell: (row) => <DateTime date={row.modifiedDate} format="MMM DD, YYYY HH:mm:ss" />,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNumber("modifiedDate")
        },
        {
          id: "createdDate",
          name: "Created on",
          cell: (row) => <DateTime date={row.createdDate} format="MMM DD, YYYY HH:mm:ss" />,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNumber("createdDate")
        },
        {
          name: "Action",
          button: true,
          cell: (row) => <div>
            <Dropdown align={"end"} drop={"down"}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link className="dropdown-item" to={`${ROUTES.PORTAL_SUBMISSION_DATE}/edit/${programmeId}/${cohortId}/${row._id}`} state={location.state}>
                  <MdModeEditOutline/> {" "} Edit
                </Link>

                <Dropdown.Divider />

                <button className="dropdown-item btn-danger" onClick={() => {
                  ModulePopup.showAlertConfirm({
                    title: "Delete Confirmation",
                    description: <>
                      <p>Are you sure to delete this submission date?
                        <br/>This action can't be undone.</p>
                      <p className="mb-0 text-danger"><em><strong>Deleting submission date might possibly making unintended behaviour. Please be careful.</strong></em></p>
                    </>,
                    onYes: () => Debouncer.execute("DELETE_SUBMISSION_DATE", async () => {
                      const res = await ApiSubmissionDate.remove(row._id);

                      if (res.status) {
                        setRefresh(i => !i);
                        Notifier.success(res.message);
                      } else {
                        Notifier.error(res.message);
                      }
                    })
                  });
                }}>
                  <MdDelete/> {" "} Delete
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      ]}
      data={data}
      selectableRows={false}
      onSelectedRowsChange={(e) => {
        setSelectedRows(e.selectedRows);
      }}
    />

  </main>;
}
