import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ApiMessage, { TNotificationItem } from "../../modules/api/message";
import UserImage from "./UserImage";
import DateTime from "../common/DateTime";
import { Link, useLocation } from "react-router-dom";
import AppUserContext from "../../context/app-user-context";
import { ROUTES } from "../../routes";
import Utils from "../../utils/utils";
import ModuleNotification from "../../modules/notification";
import PopupPeerFeedback, { TPopupPeerFeedbackRef } from "../popup/PopupPeerFeedback";
import Debouncer from "../../modules/debouncer/debouncer";
import Notifier from "../../modules/notifier/notifier";
import { PortalContext } from "../../context/portal-context";

export type TNotifItemListingProps = {
  className?: string;
  /** taken from `user.referenceUserId` */
  userId: string;
  /**
   * if set to false, no need to show pagination (used e.g. for dashboard where
   * we only show 2-3 items only). */
  pagination: boolean;
  itemsPerPage: number;
  /** what is current page? starts from 1. */
  currentPage: number;
  /** how many pages to show? */
  maxPages: number;
};

NotifItemListing.defaultProps = {
  className: "",
  pagination: false,
  itemsPerPage: 10,
  currentPage: 1,
  maxPages: 5,
  status: ""
};

export default function NotifItemListing(props: TNotifItemListingProps) {
  const location = useLocation();
  const submissionState = { from: location.pathname };
  const user = useContext(AppUserContext);
  const { setNotifCount } = useContext(PortalContext);
  const refPopupPeerFeedback = useRef<TPopupPeerFeedbackRef>();
  const [notifications, setNotifications] = useState<TNotificationItem[]>([]);
  const [notificationsTotal, setNotificationsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const [loading, setLoading] = useState(true);
  const pages = useMemo(() => {
    const totalPages = Math.ceil(notificationsTotal / props.itemsPerPage);
    const maxPages = props.maxPages;
    let startPage = Math.max(currentPage - Math.floor(maxPages / 2), 1);
    let endPage = Math.min(startPage + maxPages - 1, totalPages);

    if (endPage - startPage < maxPages - 1) {
      startPage = Math.max(endPage - maxPages + 1, 1);
    }

    const range = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
      <>
        {range.map((page) => (
          <button
            type='button'
            key={page}
            className={classNames("page-link btn-primary shadow-none", { active: currentPage === page })}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        ))}
      </>
    );
  }, [notificationsTotal, props.itemsPerPage, props.maxPages, currentPage]);

  const notificationsUi = useMemo(() => {
    return <>
      {notifications.map(notification => {
        const {
          avatar, date, title, description, isFeedback, isReminder, isForSupervisor, type,
          submissionId, traitId, submissionBaseUrl, feedbackDetail, feedbackSent
        } = ModuleNotification.parse(notification);

        return <React.Fragment key={notification._id}>
          <div className="notif-item">
            <div className="ni__image">
              <UserImage url={avatar} />
            </div>
            <div className="ni__content">
              <div className={`ni__date ${notification.status === "Unread" ? "ni__date--unread" : ""}`}>
                <DateTime date={date} format="DD/MM/YY" />
              </div>
              <div className="ni__title">{title}</div>
              <div className="ni__desc">
                <p>{description}</p>

                {isForSupervisor && <p>
                  {feedbackDetail.traitCount > 0 ? <span className={`${feedbackDetail.traitFeedbackGiven === 0 ? "label-status-fail" : "label-status-sent"}`}>You have given {feedbackDetail.traitFeedbackGiven} out of {feedbackDetail.traitCount} trait's feedback.</span>
                                                 : <span className={`${feedbackSent ? "label-status-sent" : "label-status-fail"}`}>
                                                      {feedbackSent ? "Feedback given" : "You have not given submission feedback"}
                                                   </span>}
                </p>}

                {isForSupervisor && <p>
                  <Link to={`${submissionBaseUrl}/${submissionId}`} state={submissionState} className="btn btn-secondary">View Submission</Link>
                </p>}

                {isFeedback && <p>
                  <Link to={`${submissionBaseUrl}/${submissionId}?tab=${traitId}&feedback=1`} state={submissionState} className="btn btn-secondary">View Feedback</Link>
                </p>}

                {type === "Invite Peer" && !notification.feedbackSent && notification.traitId && <p>
                  <button className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      refPopupPeerFeedback.current.show({
                        owner: {
                          avatar: notification.senderAvatar,
                          name: notification.senderName
                        },
                        data: {
                          notificationId: notification._id,
                          receiverId: notification.senderId,
                          submissionId: notification.submissionId,
                          traitId: notification.traitId
                        }
                      })
                    }}
                  >Give Feedback</button>
                </p>}

                {type === "Invite Peer" && !notification.feedbackSent && !notification.traitId && <p>
                  <span className="label-status-fail">Invalid request.</span>
                </p>}

                {type === "Invite Peer" && notification.feedbackSent && <p>
                  <span className="label-status-sent">Feedback sent to {notification.senderName}.</span>
                </p>}

                {isReminder && <p>
                  <Link to={ROUTES.PORTAL_REPORT} className="btn btn-sm btn-outline-primary">Open My Reports</Link>
                </p>}
              </div>
            </div>
          </div>
        </React.Fragment>;
      })}
    </>
  }, [notifications, refPopupPeerFeedback]);

  const setFeedbackSent = (notificationId) => {
    setNotifications(n => n.map(notif => {
      if (notif._id === notificationId) {
        notif.feedbackSent = true;
      }

      return notif;
    }));
  };

  useEffect(() => {
    (async () => {
      if (!setNotifCount) return;

      setLoading(true);
      const res = await ApiMessage.getNotifications(props.userId, currentPage - 1, props.itemsPerPage);
      setNotifications(res.data);
      setNotificationsTotal(res.total);
      setNotifCount(res.totalUnread);
      setLoading(false);
    })();
  }, [props.userId, setNotifCount, currentPage, props.itemsPerPage]);

  // set read status
  useEffect(() => {
    if (notifications.length === 0) return;

    const notificationIds = notifications.filter(item => item.status === "Unread")
      .map(item => item._id);

    if (notificationIds.length === 0) return;

    Utils.sleep(1000).then(() => {
      ApiMessage.setReadStatus(notificationIds.join(","));
    });
  }, [notifications]);

  useEffect(() => {
    // just in case there's issue about notifications response, just navigate
    // back user to page 1
    if (notificationsTotal === 0 || notifications.length === 0) {
      setCurrentPage(1);
    }
  }, [notifications, notificationsTotal]);

  return <div className={classNames([
    "report-item-listing",
    {[props.className]: !!props.className}
  ])} data-comp="NotifItemListing">
    <PopupPeerFeedback ref={refPopupPeerFeedback}
      onSubmit={(formData, setDisabled) => {
        Debouncer.execute("SUBMIT_PEER_FEEDBACK", async () => {
          setDisabled(true);
          const res = await ApiMessage.submit({
            createdUserId: user.id,
            messageType: "STUDENT",
            message: formData.feedback,
            traitId: formData.traitId,
            anonymous: formData.anonymous,
            receiverId: formData.receiverId,
            senderId: user.referenceUserId,
            status: "Unread",
            submissionId: formData.submissionId
          });

          if (res.status) {
            Notifier.success("Feedback submitted");
            refPopupPeerFeedback.current.hide();
            setFeedbackSent(formData.notificationId);
          } else {
            Notifier.error(res.message);
          }

          setDisabled(false);
        });
      }}
    />

    <div className="">
      {loading && <p className="alert alert-info">
        Loading notification. Please wait...
      </p>}

      {!loading && notificationsUi}

      {!loading && notifications.length === 0 && notificationsTotal === 0 && <p className="alert alert-not-found">
        Notification not available.
      </p>}

      {props.pagination && notificationsTotal > 0 && (
        <nav aria-label="Page navigation ril-pagination" className="my-4">
          <ul className="pagination justify-content-center">
            <li className={classNames("page-item", { disabled: currentPage === 1 })}>
              <button
                type='button'
                className="page-link btn-primary shadow-none"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {pages}
            <li
              className={classNames("page-item", {
                disabled: currentPage === Math.ceil(notificationsTotal / props.itemsPerPage),
              })}
            >
              <button
                type='button'
                className="page-link btn-primary shadow-none"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  </div>;
}
