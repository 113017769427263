/* eslint-disable react-hooks/exhaustive-deps */
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { Accordion } from "react-bootstrap";
import UserAccordListSimple from "../../../components/portal/UserAccordListSimple";
import ProfileBox from "../../../components/portal/ProfileBox";
import AppUserContext from "../../../context/app-user-context";
import { useContext, useEffect, useState } from "react";
import Utils from "../../../utils/utils";
import Config from "../../../config/config";
import AppSettingsList from "../../../components/portal/AppSettingsList";
import ApiUser from "../../../modules/api/user";
import ROLE from "../../../modules/role";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import { useLocation } from "react-router-dom";

const showLabel = (txt) => {
  const ret = Utils.ifEmptyOrUndefinedThen(txt, Config.CHAR_EMPTY);
  // eslint-disable-next-line eqeqeq
  return ret == "0" ? Config.CHAR_EMPTY : ret;
};

interface IOptionItem {
  label: string;
  value: string;
}

export default function PageProfile() {
  const title = "My Profile";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const location = useLocation();
  const user = useContext(AppUserContext);
  const isSupervisorRole =
    user?.role === ROLE.SUPERVISOR || user?.role === ROLE.INDUSTRY_SUPERVISOR;
  const isStudentRole = user?.role === ROLE.STUDENT;
  const [state, setState] = useState({});
  const [myGroup, setMyGroup] = useState<any[]>([]);
  const [myGroupLoading, setMyGroupLoading] = useState(true);
  const [programmeId, setProgrammeId] = useState("");
  const [programmeOptions, setProgrammeOptions] = useState<IOptionItem[]>([]);
  const [cohortId, setCohortId] = useState("");
  const [cohortOptions, setCohortOptions] = useState<IOptionItem[]>([]);
  const [groupId, setGroupId] = useState("");
  const [groupOptions, setGroupOptions] = useState<IOptionItem[]>([]);
  const [cohortGroupByProgramme, setCohortGroupByProgramme] = useState({});

  useEffect(() => {
    if (!programmeId || !cohortId || !groupId) return;
    setState({
      programmeId: programmeId,
      cohortId: cohortId,
      groupId: groupId,
      from: location.pathname,
    });
  }, [programmeId, cohortId, groupId]);

  useEffect(() => {
    ApiUser.myPeers(
      user.referenceUserId,
      user.activeGroup._id,
      user.role === ROLE.STUDENT ? ROLE.STUDENT : "__"
    ).then((students) => {
      setMyGroupLoading(false);
      setMyGroup(students);
    });
  }, []);

  useEffect(() => {
    if (user?.groupHash && isSupervisorRole) {
      const cohortGroupByProgramme = getCohortGroupByProgramme();
      setCohortGroupByProgramme(cohortGroupByProgramme);
    }
  }, [user, isSupervisorRole]);

  useEffect(() => {
    if (cohortGroupByProgramme && isSupervisorRole) {
      const programmeOptions: IOptionItem[] = getProgrammeOptions(
        cohortGroupByProgramme
      );
      setProgrammeOptions(programmeOptions);
      const programme =
        programmeOptions.find(
          (option) =>
            location.state &&
            location.state.programmeId &&
            option.value === location.state.programmeId
        ) ?? programmeOptions[0];
      if (programme) {
        setProgrammeId(programme.value);
      }
    }
  }, [cohortGroupByProgramme, isSupervisorRole]);

  useEffect(() => {
    if (programmeId && isSupervisorRole) {
      const cohortOptions = getCohortOptions(programmeId);
      setCohortOptions(cohortOptions);
      const cohort =
        cohortOptions.find(
          (option) =>
            location.state &&
            location.state.cohortId &&
            option.value === location.state.cohortId
        ) ?? cohortOptions[0];
      if (cohort) {
        setCohortId(cohort.value);
      }
    }
  }, [programmeId, isSupervisorRole]);

  useEffect(() => {
    let groupOptions: IOptionItem[] = [];
    if (isSupervisorRole && cohortId) {
      groupOptions = getGroupOptions(cohortId);
    } else {
      groupOptions = getGroupOptions(null);
    }
    setGroupOptions(groupOptions);
    const group =
      groupOptions.find(
        (option) =>
          location.state &&
          location.state.groupId &&
          option.value === location.state.groupId
      ) ?? groupOptions[0];
    if (group) {
      setGroupId(group.value);
    }
  }, [cohortId, isSupervisorRole, user?.groupHash]);

  const getCohortGroupByProgramme = () => {
    const cohortGroupByProgramme = {};
    if (!user || !user.groupHash) {
      return cohortGroupByProgramme;
    }
    Object.keys(user.groupHash).forEach((group) => {
      var programme = user.groupHash[group]?.cohort?.programme;
      if (!programme) {
        return;
      }
      const cohort = { ...user.groupHash[group].cohort };
      delete cohort.programme;
      if (cohortGroupByProgramme[programme._id]) {
        if (!cohort) {
          return;
        }
        if (
          !cohortGroupByProgramme[programme._id].cohorts.some(
            (c) => c._id === cohort._id
          )
        ) {
          cohortGroupByProgramme[programme._id].cohorts.push(cohort);
        }
      } else {
        cohortGroupByProgramme[programme._id] = {
          name: programme.name,
          cohorts: cohort ? [cohort] : [],
        };
      }
    });
    return cohortGroupByProgramme;
  };

  const getProgrammeOptions = (cohortGroupByProgramme) => {
    return Object.keys(cohortGroupByProgramme ?? {}).map((programmeId) => ({
      label: cohortGroupByProgramme[programmeId].name,
      value: programmeId,
    }));
  };

  const getCohortOptions = (programmeId) => {
    return cohortGroupByProgramme[programmeId]?.cohorts.map((cohort) => ({
      label: cohort.name,
      value: cohort._id,
    }));
  };

  const getGroupOptions = (cohortId: string | null) => {
    const groupOptions: IOptionItem[] = [];
    Object.keys(user?.groupHash ?? {}).forEach((groupId) => {
      const group = user?.groupHash[groupId];
      if (!cohortId || group.cohort._id === cohortId) {
        groupOptions.push({
          label: group.name,
          value: groupId,
        });
      }
    });
    return groupOptions;
  };

  return (
    <main
      className="content-generic content-generic--fluid"
      data-comp="PageProfile"
    >
      <ProfileBox />
      <div className="row">
        <div className="col-lg-6">
          <Accordion
            className="profile-accordion"
            defaultActiveKey={["0"]}
            alwaysOpen
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>My Contact Detail</Accordion.Header>
              <Accordion.Body>
                <ol className="contact-detail-list">
                  <li>
                    <div>Email</div>
                    <div>{user.email}</div>
                  </li>
                  <li>
                    <div>Programme</div>
                    <div>{showLabel(user.programmeName)}</div>
                  </li>
                  <li>
                    <div>Job title</div>
                    <div>{showLabel(user.jobDesk)}</div>
                  </li>
                </ol>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion
            className="profile-accordion"
            defaultActiveKey={["0"]}
            alwaysOpen
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>App Settings</Accordion.Header>
              <Accordion.Body>
                <AppSettingsList />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <div className="col-lg-6">
          <Accordion
            className="profile-accordion"
            defaultActiveKey={["0"]}
            alwaysOpen
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>My Group</Accordion.Header>
              <Accordion.Body>
                {myGroupLoading && (
                  <p className="text-center py-3">Loading...</p>
                )}
                {!myGroupLoading && (
                  <>
                    {isSupervisorRole && (
                      <div className="row g-3 mb-3">
                        <div className="col-md-6">
                          <FGDropdownApi
                            label="Filter by programme"
                            variant="normal"
                            options={programmeOptions}
                            value={programmeId}
                            defaultValue={programmeId}
                            onChange={(e: any) => {
                              setProgrammeId(e.target.value);
                            }}
                            className="m-0"
                          />
                        </div>
                        <div className="col-md-6">
                          <FGDropdownApi
                            label="Filter by cohort"
                            variant="normal"
                            options={cohortOptions}
                            value={cohortId}
                            defaultValue={cohortId}
                            onChange={(e: any) => {
                              setCohortId(e.target.value);
                            }}
                            className="m-0"
                          />
                        </div>
                      </div>
                    )}
                    {groupOptions?.length > 1 && !isStudentRole && (
                      <FGDropdownApi
                        label="Filter by group"
                        variant="normal"
                        options={groupOptions}
                        value={groupId}
                        defaultValue={groupId}
                        onChange={(e: any) => {
                          setGroupId(e.target.value);
                        }}
                        className="pb-0"
                      />
                    )}
                    <UserAccordListSimple
                      data={myGroup}
                      selectedGroup={
                        isStudentRole ? user.activeGroup._id : groupId
                      }
                      state={state}
                    />
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </main>
  );
}
