import ApiGenericData, { TGenericItem } from "./generic-data";

export type TIndustryItem = TGenericItem & {};

const ApiOrganization = {
  pagingSearch: ApiGenericData.pagingSearch("Organization"),
  add: ApiGenericData.add<Omit<TIndustryItem, "_id">>("Organization"),
  get: ApiGenericData.get,
  update: ApiGenericData.update<Omit<TIndustryItem, "_id">>("Organization"),
};

export default ApiOrganization;
