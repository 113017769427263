import { Modal } from "react-bootstrap";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineUpload,
  HiOutlineX,
} from "react-icons/hi";
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import Debouncer from "../../modules/debouncer/debouncer";
import Notifier from "../../modules/notifier/notifier";
import ApiTrait from "../../modules/api/trait";
import { ClipLoader } from "react-spinners";

export type TPopupImportTraitsRef = {
  show: () => void;
};

export type TPopupImportTraitsProps = {
  cohortId: string;
  title?: string;
  labelOK?: string;
  backdrop?: boolean;
  keyboard?: boolean;
  onClose?: () => void;
  onOK?: () => void;
  onImport?: () => void;
};

const PopupImportTraits = forwardRef<
  TPopupImportTraitsRef,
  TPopupImportTraitsProps
>((props, ref) => {
  const fileRef = useRef<HTMLInputElement>();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [sending, setSending] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [importedTraits, setImportedTraits] = useState<any[]>([]);

  useEffect(() => {
    if (!importedTraits.length) {
      return;
    }
    if (importedTraits.some((t) => t.success)) {
      props.onImport();
    }
  }, [importedTraits]);

  useEffect(() => {
    setImportedTraits([]);
  }, [uploadedFile]);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
  }));

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];

    if (validateFileType(file)) {
      setError("");
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError("Invalid file type. Please upload a CSV file.");
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (validateFileType(file)) {
      setError("");
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError("Invalid file type. Please upload a CSV file.");
    }
  };

  const handleUploadAreaClick = () => {
    resetFileValue(false);
    document.getElementById("trait-form-upload-file-input")?.click();
  };

  const handleRemoveFile = () => {
    resetFileValue();
    setUploadedFile(null);
    setError("");
  };

  const handleSubmit = () => {
    Debouncer.execute("IMPORT_TRAITS", async () => {
      setSending(true);
      setImportedTraits([]);
      await ApiTrait.addByJson(props.cohortId, uploadedFile)
        .then((responseData) => {
          if (responseData.result === "OK") {
            setImportedTraits(
              Array.isArray(responseData.data) ? responseData.data : []
            );
          } else {
            setError(responseData.message);
          }
        })
        .catch((error) => {
          Notifier.error(error.message);
        });
      setSending(false);
    });
  };

  const validateFileType = (file: File) => {
    const allowedTypes = ["application/json"];
    return allowedTypes.includes(file.type);
  };

  const resetFileValue = (resetUploadedFile = true) => {
    try {
      fileRef.current.value = "";
      if (resetUploadedFile) {
        setUploadedFile(null);
      }
    } catch (ignore) {}
  };

  const onHide = () => {
    setShow(false);
    handleRemoveFile();
    props.onClose();
  };

  return (
    <Modal
      className="modal-generic modal-import"
      show={show}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title ?? "Confirmation"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className={`import-upload-area ${
            isDragging ? "dragging" : ""
          } border rounded col-12 text-center`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleUploadAreaClick}
        >
          <HiOutlineUpload className="upload-icon mb-3 text-secondary" />
          <p className="mb-1 fs-5">Drag & Drop or Choose file to upload</p>
          <p className="text-secondary fs-5">JSON file</p>
          <input
            ref={fileRef}
            type="file"
            accept=".json"
            onChange={handleFileInputChange}
            id="trait-form-upload-file-input"
            className="d-none"
          />
        </div>
        {error && error !== "" && (
          <div className="alert alert-danger mt-3 mb-0" role="alert">
            {error}
          </div>
        )}
        {uploadedFile && (
          <div className="uploaded-file-container mt-3 p-2 rounded">
            <div className="uploaded-file d-flex justify-content-between align-items-center p-2 bg-white rounded">
              <p className="mb-0">
                <span>
                  <HiOutlineDocumentDuplicate className="fs-4 me-2" />
                </span>
                {uploadedFile.name}
              </p>
              <HiOutlineX className="cancel-icon" onClick={handleRemoveFile} />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-mw btn-primary"
          onClick={handleSubmit}
          disabled={!uploadedFile || sending}
        >
          <ClipLoader color="#FFFFFF" size={16} loading={sending} />
          {!sending && (props.labelOK ?? "OK")}
        </button>
        {importedTraits.length > 0 && (
          <>
            {importedTraits.some((t) => !t.success) ? (
              <div className="modal-import-status alert alert-info">
                <h4>Traits Imported Status</h4>
                <p>
                  Below is list of traits imported. Failed means system was
                  unable to create trait.
                </p>
                {importedTraits.map((t, i) => (
                  <div
                    key={i}
                    className={`alert alert-${
                      t.success ? `success` : `danger`
                    }`}
                  >
                    <strong>
                      Row #{i} ({t.success ? `Success` : `Failed`}):{" "}
                    </strong>
                    {t.message}
                  </div>
                ))}
              </div>
            ) : (
              <div className="alert alert-info text-start all-traits-success-info">
                Trait(s) imported successfully
              </div>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
});

PopupImportTraits.defaultProps = {
  title: "Import Trait(s)",
  backdrop: true,
  keyboard: false,
  onClose: () => {},
  onOK: () => {},
  onImport: () => {},
};

export default PopupImportTraits;
