import React, { useEffect, useMemo, useState } from "react";
import ApiSubmission, { TSubmissionListItem } from "../../modules/api/submission";
import ReportItem from "./ReportItem";
import classNames from "classnames";

export type TReportItemListingProps = {
  className?: string;
  cohortId: string;
  /** taken from `user.referenceUserId` */
  userId: string;
  /**
   * if set to false, no need to show pagination (used e.g. for dashboard where
   * we only show 2-3 items only). */
  pagination: boolean;
  itemsPerPage: number;
  /** what is current page? starts from 1. */
  currentPage: number;
  /** how many pages to show? */
  maxPages: number;
  /**
   * filter submission status. typically this is for SIT supervisor / industry
   * supervisor where they only able to see published work ony. so, the value
   * of status can either be "Publish" or "Draft"
   */
  status?: "Publish" | "Draft";
  type: string;
  /**
   * to differentiate endpoint of user, basically just passing out this option
   * the `<ReportItem/>`
   */
  isSupervisor?: boolean;
  isReportPage?: boolean;
};

ReportItemListing.defaultProps = {
  className: "",
  pagination: false,
  itemsPerPage: 10,
  currentPage: 1,
  maxPages: 5,
  isPublishOnly: false,
  isSupervisor: false,
  status: "",
  type: "",
  isReportPage: false
};

export default function ReportItemListing(props: TReportItemListingProps) {
  const [submissions, setSubmissions] = useState<TSubmissionListItem[]>([]);
  const [submissionsTotal, setSubmissionsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const [loading, setLoading] = useState(true);
  const pages = useMemo(() => {
    const totalPages = Math.ceil(submissionsTotal / props.itemsPerPage);
    const maxPages = props.maxPages;
    let startPage = Math.max(currentPage - Math.floor(maxPages / 2), 1);
    let endPage = Math.min(startPage + maxPages - 1, totalPages);

    if (endPage - startPage < maxPages - 1) {
      startPage = Math.max(endPage - maxPages + 1, 1);
    }

    const range = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
      <>
        {range.map((page) => (
          <button
            type='button'
            key={page}
            className={classNames("page-link btn-primary shadow-none", { active: currentPage === page })}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        ))}
      </>
    );
  }, [submissionsTotal, props.itemsPerPage, props.maxPages, currentPage]);

  useEffect(() => {
    (async () => {
      if(!props.cohortId || !props.cohortId.trim()) return;
      setLoading(true);
      const req = await ApiSubmission.getSubmissions(props.cohortId, props.userId, currentPage - 1, props.itemsPerPage, props.status, props.type);
      setSubmissions(req.submissions);
      setSubmissionsTotal(req.total);
      setLoading(false);
    })();
  }, [props.userId, currentPage, props.itemsPerPage, props.status, props.type]);

  useEffect(() => {
    // just in case there's issue about submissions response, just navigate
    // back user to page 1
    if (submissionsTotal === 0 || submissions.length === 0) {
      setCurrentPage(1);
    }
  }, [submissions, submissionsTotal]);

  return <div className={classNames([
    "report-item-listing",
    {[props.className]: !!props.className}
  ])} data-comp="ReportItemListing">

    <div className="d-flex flex-column gap-3">
      {loading && <p className="alert alert-info">
        Loading. Please wait...
      </p>}

      {!loading && submissions.map((item, i) => <ReportItem submission={item} key={item._id} isSupervisor={props.isSupervisor} isReportPage={props.isReportPage} />)}

      {!loading && submissions.length === 0 && submissionsTotal === 0 && <p className="alert alert-not-found">
        {props.isSupervisor
            ? "No content available for this student."
            : "No content available. Please fill out your report."}
      </p>}

      {props.pagination && submissionsTotal > 0 && (
        <nav aria-label="Page navigation ril-pagination" className="my-3">
          <ul className="pagination justify-content-center">
            <li className={classNames("page-item", { disabled: currentPage === 1 })}>
              <button
                type='button'
                className="page-link btn-primary shadow-none"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {pages}
            <li
              className={classNames("page-item", {
                disabled: currentPage === Math.ceil(submissionsTotal / props.itemsPerPage),
              })}
            >
              <button
                type='button'
                className="page-link btn-primary shadow-none"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  </div>;
}
