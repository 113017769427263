import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ChromePicker } from "react-color";
import FGInput from "../dumb/FGInput";
import FGTextarea from "../dumb/FGTextarea";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { HiExclamationCircle } from 'react-icons/hi';
import ModulePopup from '../../modules/popup/popup';
import TraitConfig from "../../config/trait-config";
import TraitHelper from "../../modules/trait-helpers";

export type TPopupConfigureTraitRef = {
  hide: () => void;
  show: () => void;
};

export type TPopupConfigureTraitProps = {
  scaleJson?: any;
  onClose?: () => void;
  onSubmit?: (formData: any) => void;
};

const PopupConfigureTrait = forwardRef<
  TPopupConfigureTraitRef, TPopupConfigureTraitProps
>((props, ref) => {
  const [show, setShow] = useState(false);
  const [jsonData, setJsonData] = useState(props.scaleJson);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [traitColor, setTraitColor] = useState("#3D85F2");
  const [traitDescription, setTraitDescription] = useState("");
  const [scalesData, setScalesData] = useState([]);
  const modalBodyRef = useRef<any>();

  // set state for scalesData
  useEffect(() => {
    if (!jsonData) return;
    setScalesData(TraitHelper.convertJsonToData(JSON.parse(jsonData)));
  }, [jsonData]);

  useEffect(() => {
    if (!show) return;
    if (!props.scaleJson) return;

    let scaleJson = props.scaleJson;
    let parsedJson;

    try {
      parsedJson = JSON.parse(scaleJson);

      if (!$.isPlainObject(parsedJson)) {
        throw new Error();
      }
    } catch (ignore) {
      scaleJson = "";
      parsedJson = {
        color: "",
        description: ""
      }
    }

    setJsonData(scaleJson);
    setTraitColor(parsedJson.color);
    setTraitDescription(parsedJson.description);

    if (scaleJson) {
      setScalesData(TraitHelper.convertJsonToData(JSON.parse(props.scaleJson)));
    } else {
      setScalesData([]);
    }
  }, [jsonData, props.scaleJson, show]);

  useEffect(() => {
    if (show) return;
    handleColorPickerClose();
  }, [show]);

  useImperativeHandle(ref, () => ({
    show() {
      if (props.scaleJson) {
        setScalesData(TraitHelper.convertJsonToData(JSON.parse(props.scaleJson)));
      } else {
        setScalesData([]);
      }
      setShow(true);
    },
    hide() {
      setShow(false);
    },
  }), []);

  const handleColorPickerClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorPickerClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorPickerChange = (traitColor) => {
    setTraitColor(traitColor.hex);
  };

  const handleScaleColorPickerClick = (scaleId) => {
    setScalesData((prevScalesData) => prevScalesData.map((scale) =>
      scale.id === scaleId ? { ...scale, displayColorPicker: !scale.displayColorPicker } : scale
    ));
  };

  const handleScaleColorPickerClose = (scaleId) => {
    setScalesData((prevScalesData) => prevScalesData.map((scale) => (
      scale.id === scaleId ? { ...scale, displayColorPicker: false } : scale)
    ));
  };

  const handleScaleColorPickerChange = (scaleId, scaleColor) => {
    setScalesData((prevScalesData) => prevScalesData.map((scale) => (
      scale.id === scaleId ? { ...scale, scaleColor } : scale)
    ));
  };

  const handleScaleInputRangeChange = (scaleId, scaleRangeValue) => {
    setScalesData((prevScalesData) => prevScalesData.map((scale) => scale.id === scaleId
      ? { ...scale, scaleRange: { min: scaleRangeValue[0], max: scaleRangeValue[1] } }
      : scale
    ));
  };

  const handleAddScale = () => {
    setScalesData((prevScalesData) => [
      ...prevScalesData,
      {
        id: `${prevScalesData.length + 1}`,
        scaleRange: { min: 1, max: 10},
        title: "",
        description: "",
        scaleColor: "#FDD500",
        displayColorPicker: false,
      },
    ]);

    setTimeout(() => {
      try {
        const $div = $(modalBodyRef?.current?.dialog as HTMLDivElement);
        $div.stop(false, true).animate({
          scrollTop: $div[0].scrollHeight
        });
      } catch (ignore) {}
    }, 250);
  };

  const handleDeleteScale = (scaleId) => {
    setScalesData((prevScalesData) => prevScalesData.filter((scale) => scale.id !== scaleId));
  };

  const handleScaleTitleChange = (scaleId, title) => {
    setScalesData((prevScalesData) =>
      prevScalesData.map((scale) => (scale.id === scaleId ? { ...scale, title } : scale))
    );
  };

  const handleScaleDescriptionChange = (scaleId, description) => {
    setScalesData((prevScalesData) =>
      prevScalesData.map((scale) => (scale.id === scaleId ? { ...scale, description } : scale))
    );
  };

  const handleSaveChanges = () => {
    // Check for overlapping scales before saving changes
    const isAnyOverlap = scalesData.some((scale, index) => {
      const currentRange = [parseInt(scale.scaleRange.min, 10), parseInt(scale.scaleRange.max, 10)];
      for (let i = 0; i < scalesData.length; i++) {
        if (i !== index) {
          const otherRange = [parseInt(scalesData[i].scaleRange.min, 10), parseInt(scalesData[i].scaleRange.max, 10)];
          if (
            (currentRange[0] >= otherRange[0] && currentRange[0] <= otherRange[1]) ||
            (currentRange[1] >= otherRange[0] && currentRange[1] <= otherRange[1])
          ) {
            return true;
          }
        }
      }
      return false;
    });

    if (isAnyOverlap) {
      ModulePopup.showAlert({
        title: <><HiExclamationCircle />{" "}Warning!</>,
        description: <p>There are overlapping sliders. Please adjust the ranges to avoid overlaps.</p>
      });
      return;
    }

    const submitData = {
      traitColor,
      traitDescription,
      scalesData,
    };

    const jsonConfig = TraitHelper.convertDataToJson(submitData)
    const validation = TraitConfig.isJSONConfigCorrect(jsonConfig);

    if (!validation.correct) {
      ModulePopup.showAlert({
        title: "Unable to save changes",
        description: <>
          <p>Trait Configuration is not configured properly, please rectify and retry again.</p>
          <p>Additional info: <em>{validation.message}</em></p>
        </>
      });
      return;
    }

    // If no overlapping scales, proceed with saving changes
    props.onSubmit(submitData);
    setShow(false);
  };

  const handleClosePopup = () => {
    ModulePopup.showAlertConfirm({
      title: "Cancel Confirmation",
      description: "Are you sure to cancel? New changes will be discarded.",
      onYes() {
        props.onClose();
        setShow(false);
      },
    });
  };

  return <Modal
    ref={modalBodyRef}
    className="modal-generic modal-configure-trait"
    show={show}
    backdrop="static"
    keyboard={false}
    centered
    onHide={() => {
      props.onClose();
      setShow(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Trait Configuration (User interface mode)</Modal.Title>
    </Modal.Header>
    <Modal.Body className="ts__legend-desc">
      <div className="ctg__content configure-trait-box">

        <div className="mct__field w-100">
          <div>
            <label className='fw-medium'>Trait Color*</label>
            <div className="ctb__label-desc">This color will be used on the students' trait chart(s).</div>
          </div>

          <div className="traitColor-picker input-group">
            <div className="swatch">
              <div
                className="traitColor"
                style={{ background: traitColor }}
              />
            </div>
            <input type="text" className="traitColor-picker__input" value={traitColor} onChange={(e) => setTraitColor(e.target.value)} />
            <button className="btn btn-outline-dark" onClick={handleColorPickerClick}>
              Select color
            </button>
            {displayColorPicker ? (
              <div className="popover">
                <div
                  className="cover"
                  onClick={handleColorPickerClose}
                />
                <ChromePicker
                  color={traitColor}
                  onChange={handleColorPickerChange}
                  width="100%"
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="mct__field w-100">
          <FGTextarea
            variant="normal"
            label="Description"
            placeholder="Description..."
            value={traitDescription}
            onChange={(e) => setTraitDescription(e.target.value)}
          />
        </div>

        <div className="mct__field configure-trait-scales">
          <label className="fw-medium">Scales</label>
          <div className="cts__items">
            {scalesData.map((scale) => (
              <div key={scale.id} className="cts__item">
                <div className='row'>
                  <div className='col'>
                    <FGInput
                      variant="normal"
                      label="Scale Name*"
                      placeholder="Scale Name..."
                      value={scale.title}
                      onChange={(e) => handleScaleTitleChange(scale.id, e.target.value)}
                    />
                    <FGTextarea
                      variant="normal"
                      label="Description"
                      placeholder="Description..."
                      value={scale.description}
                      onChange={(e) => handleScaleDescriptionChange(scale.id, e.target.value)}
                    />
                  </div>
                  <div className='col'>
                    <label className="mb-2">Scale Color*</label>
                    <div className="traitColor-picker input-group mb-4">
                      <div className="swatch">
                        <div
                          className="traitColor"
                          style={{ background: scale.scaleColor }}
                        />
                      </div>
                      <input type="text" className="traitColor-picker__input" value={scale.scaleColor}
                      onChange={(e) => handleScaleColorPickerChange(scale.id, e.target.value)}
                      />
                      <button
                        className="btn btn-outline-dark"
                        onClick={() => handleScaleColorPickerClick(scale.id)}
                      >
                        Select color
                      </button>
                      {scale.displayColorPicker ? (
                        <div className="popover">
                          <div
                            className="cover"
                            onClick={() => handleScaleColorPickerClose(scale.id)}
                          />
                          <ChromePicker
                            color={scale.scaleColor}
                            onChange={(color) => handleScaleColorPickerChange(scale.id, color.hex)}
                            width="100%"
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className='pe-2' >
                      <label className='my-2 mb-4 '>Range</label>
                      <Slider
                        value={[scale.scaleRange.min, scale.scaleRange.max]}
                        min={1}
                        max={10}
                        step={1}
                        dots
                        range={true}
                        count={1}
                        onChange={(value) => handleScaleInputRangeChange(scale.id, value)}
                        dotStyle={{
                          display: 'none',
                        }}
                        activeDotStyle={{
                          borderColor: '#D23638',
                          backgroundColor: '#D23638',
                        }}
                        trackStyle={[{
                          backgroundColor: '#D23638',
                        }]}
                        railStyle={{
                          backgroundColor: '#E5E5E5',
                        }}
                        marks={{
                          1: '1',
                          2: '2',
                          3: '3',
                          4: '4',
                          5: '5',
                          6: '6',
                          7: '7',
                          8: '8',
                          9: '9',
                          10: '10',
                        }}
                        // tipFormatter={value => `${value}`}
                      />
                    </div>
                  </div>
                  <div className='text-center'>
                    <button type='button' className='btn btn-outline-primary mt-0 px-5' onClick={() => {
                      ModulePopup.showAlertConfirm({
                        title: "Delete Confirmation",
                        description: "Are you sure to delete this scale?",
                        onYes() {
                          handleDeleteScale(scale.id);
                        }
                      })
                    }}>
                      Delete Scale
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button type='button' className='btn btn-secondary' onClick={handleAddScale}>Add Scale</button>
          </div>
        </div>

      </div>
    </Modal.Body>
    <Modal.Footer>
      <button
        className="btn btn-mw btn-outline-dark"
        onClick={() => handleClosePopup()}
      >
        Cancel
      </button>
      <button className="btn btn-mw btn-primary" onClick={(e) => {
        e.preventDefault();
        handleSaveChanges();
      }}>
        Save changes
      </button>
    </Modal.Footer>
  </Modal>;
});

PopupConfigureTrait.defaultProps = {
  scaleJson: {},
  onClose: () => {},
  onSubmit: () => {},
};

export default PopupConfigureTrait;
