import ROLE from "../../modules/role";
import { Tab, Tabs } from "react-bootstrap";
import { MdArrowBack } from "react-icons/md";
import SubmissionCard from "./SubmissionCard";
import { HiOutlineUser } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import FGDropdownApi from "../form/FGDropdownApi";
import { useMemo, useState, useEffect } from "react";
import ApiSubmission from "../../modules/api/submission";
import {
  CONST_SELECT_SUBMISSION_TYPES,
  SUBMISSION_PD,
} from "../../config/constants";
import LoadingData from "./LoadingData";

export type TMyGroupDetaiProps = {
  programmeId?: string;
  cohortId?: string;
  group: any;
  user: any;
  onExit?: () => void;
  showOnDashboard?: boolean;
};

export default function MyGroupDetail(props: TMyGroupDetaiProps) {
  const tabList = useMemo(() => ["All", "Submitted", "Late", "Not Yet"], []);
  const submissionTypes = useMemo(() => {
    if (!props.user) {
      return [];
    }
    if (props.showOnDashboard || props.user.role === ROLE.INDUSTRY_SUPERVISOR) {
      return CONST_SELECT_SUBMISSION_TYPES.filter(
        (i) => i.value === SUBMISSION_PD
      );
    } else {
      return CONST_SELECT_SUBMISSION_TYPES;
    }
  }, [props.user]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const [submissions, setSubmissions] = useState<any[]>([]);
  const [submissionType, setSubmissionType] = useState(
    submissionTypes[0]?.value
  );
  const state =
    props.programmeId && props.cohortId
      ? {
          programmeId: props.programmeId,
          cohortId: props.cohortId,
          groupId: props.group._id,
          submissionType: submissionType,
        }
      : null;

  useEffect(() => {
    if (
      location.state &&
      location.state.submissionType &&
      submissionTypes.some((t) => t.value === location.state.submissionType)
    ) {
      setSubmissionType(location.state.submissionType);
    }
  }, []);

  useEffect(() => {
    if (!submissionType) return;
    ApiSubmission.submissionStatus(
      props.cohortId ?? props.group?.cohort?._id,
      props.group._id,
      submissionType
    )
      .then((data) => {
        setSubmissions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, [submissionType]);

  const exit = () => {
    if (props.onExit) {
      props.onExit();
    }
  };

  return (
    <div data-comp="MyGroupDetail">
      {!props.showOnDashboard ? (
        <>
          <h1 className="fs-title">
            <label
              className="text-primary p-0 me-2 cursor-pointer"
              onClick={exit}
            >
              <MdArrowBack />
            </label>
            Group Detail
          </h1>
          <div className="group-info bg-white p-3">
            <div className="group-info-field">
              <strong>Group Name</strong>
              <label>{props.group.name}</label>
            </div>
            <div className="group-info-field">
              <strong>Cohort</strong>
              <label>{props.group.cohort.name}</label>
            </div>
            <div className="group-info-field">
              <strong>Programme</strong>
              <label>{props.group.cohort.programme.name}</label>
            </div>
          </div>
          {props.user?.role === ROLE.SUPERVISOR && (
            <div className="group-filter">
              <FGDropdownApi
                options={submissionTypes}
                className="m-0"
                value={submissionType}
                defaultValue={submissionType}
                onChange={(e: any) => setSubmissionType(e.target.value)}
              />
            </div>
          )}
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => {
              setActiveTab(k);
            }}
            className="pwl__tabs"
          >
            {!loading &&
              tabList.map((tabName, i) => {
                const filteredSubmission =
                  tabName === "All"
                    ? submissions.map((item) => item)
                    : submissions.filter(
                        (item) =>
                          item.status.toLowerCase() === tabName.toLowerCase()
                      );
                return (
                  <Tab
                    key={i}
                    eventKey={tabName}
                    className=""
                    title={
                      <span className="d-flex justify-content-center align-items-center gap-2">
                        {tabName} {filteredSubmission.length}
                        <HiOutlineUser />
                      </span>
                    }
                  >
                    <div className="row g-3 row-cols-1 row-cols-xl-2 py-1">
                      {filteredSubmission.map((submission, i) => (
                        <div className="col" key={i}>
                          <SubmissionCard
                            key={i}
                            groupId={props.group._id}
                            userId={submission._id}
                            avatar={submission.avatar}
                            user={submission.name}
                            status={submission.status}
                            to={submission.to}
                            submissionType={submissionType}
                            state={state}
                          />
                        </div>
                      ))}
                    </div>
                  </Tab>
                );
              })}
          </Tabs>
        </>
      ) : (
        <div className="row g-3 row-cols-1 row-cols-xl-2 py-1">
          {submissions.map((submission, i) => (
            <div className="col" key={i}>
              <SubmissionCard
                key={i}
                groupId={props.group._id}
                userId={submission._id}
                avatar={submission.avatar}
                user={submission.name}
                status={submission.status}
                to={submission.to}
                submissionType={submissionType}
                state={state}
              />
            </div>
          ))}
        </div>
      )}
      <LoadingData show={loading} />
      {!loading && submissions.length === 0 && (
        <p
          className="alert alert-info"
          style={{ marginTop: props.showOnDashboard ? 0 : 10 }}
        >
          Submission group status unavailable.
        </p>
      )}
    </div>
  );
}
