import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";
import AppUserContext from "../../../context/app-user-context";
import { ROUTES } from "../../../routes";
import { MdDelete, MdModeEditOutline, MdSearch } from "react-icons/md";
import { Dropdown } from "react-bootstrap";
import Debouncer from "../../../modules/debouncer/debouncer";
import ServerSideGenericDataTable from "../../../components/common/ServerSideGenericDataTable";
import ApiGenericData from "../../../modules/api/generic-data";
import Notifier from "../../../modules/notifier/notifier";
import ApiOrganization from "../../../modules/api/organization";
import ModulePopup from "../../../modules/popup/popup";

export default function PageOrganization() {
  const title = "Manage Organizations";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  return (
    <main className="form-generic content-generic content-generic--fluid">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions">
        <div className="cg__action-buttons">
          <Link to={ROUTES.PORTAL_ORGANIZATION_ADD} className="btn btn-primary">
            Add New Organization
          </Link>{" "}
          <button
            className="btn btn-outline-primary"
            onClick={() => setRefresh((i) => !i)}
          >
            Refresh
          </button>
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch />
          </span>
          <input
            type="text"
            placeholder="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <ServerSideGenericDataTable
        refresh={refresh}
        api={ApiOrganization.pagingSearch}
        search={search}
        columns={[
          {
            id: "name",
            name: "Name",
            cell: (row) => row.name,
          },
          {
            id: "description",
            name: "Description",
            cell: (row) => row.description,
          },
          {
            name: "Action",
            button: true,
            cell: (row) => (
              <div>
                <Dropdown align={"end"} drop={"down"}>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className="btn-sm"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link className="dropdown-item" to={`edit/${row._id}`}>
                      <MdModeEditOutline /> Edit
                    </Link>

                    <Dropdown.Divider />

                    <button
                      className="dropdown-item btn-danger"
                      onClick={() => {
                        ModulePopup.showAlertConfirm({
                          title: "Delete Confirmation",
                          description: (
                            <>
                              <p>
                                Are you sure to delete this?
                                <br /> This action can't be undone.
                              </p>
                            </>
                          ),
                          onYes: () =>
                            Debouncer.execute("DELETE_ITEM", async () => {
                              ApiGenericData.remove(row._id).then((res) => {
                                if (res.status) {
                                  setRefresh((i) => !i);
                                  Notifier.success(res.message);
                                } else {
                                  Notifier.error(res.message);
                                }
                              });
                            }),
                        });
                      }}
                    >
                      <MdDelete /> Delete
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ),
          },
        ]}
      />
    </main>
  );
}
