import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Config, { publicDir } from "../../config/config";
import { useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../modules/api/base";
import Notifier from "../../modules/notifier/notifier";
import AppUser from "../../modules/app-user/app-user";
import useAuthUser from "../../hooks/use-auth-user";
import { ROUTES } from "../../routes";
import usePageTitle from "../../hooks/use-page-title";

export default function PageAuth() {
  usePageTitle("Login ADFS");
  const auth = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => loginAdfs(), []);

  const loginAdfs = () => {
    if (!token) return;
    const authHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(apiUrl(`/apiauth/adfs/login`), {}, authHeader)
      .then((res) => {
        if (res.status === 200) {
          const responseData = res.data;
          if (responseData.result === "OK") {
            const user = responseData.data;
            AppUser.setInfo(
              Object.assign(
                {
                  lastUpdate: Date.now(),
                  id: user._id,
                  logged: true,
                  token: user.authToken,
                  just_logged: true,
                  isAdfsUser: true,
                },
                user
              )
            ).then(() => navigate(ROUTES.PORTAL_DASHBOARD));
          } else {
            Notifier.error(
              `Login ADFS by token failed. ${responseData.message ?? ``}`
            );
          }
        } else {
          Notifier.error(
            `Login ADFS by token failed. Status code ${res.status}`
          );
        }
      })
      .catch((err) => {
        Notifier.error(`Login ADFS by token failed. ${err.message}`);
      });
  };

  return (
    <div className="PageAuth" data-comp="PageAuth">
      <div className="PA__row">
        <div className="PA__col PA__col--desc">
          <div className="PA__col--desc-inner">
            <img
              className="PA__logo mx-auto"
              src={publicDir("/assets/images/logo/logo.png")}
              alt="SIT Logo"
            />
            <h1>Welcome to GROW-Pro</h1>
            <h2>
              Your place to manage coaching conversation for your professional
              growth
            </h2>
            <img
              className="PA__img mx-auto"
              src={publicDir("/assets/images/auth-image.png")}
              alt=""
            />
          </div>
        </div>
        <div className="PA__col PA__col--adfs">
          {!token && (
            <div className="PA__main">
              <Outlet />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
