import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { useEffect, useState } from "react";
import AppUser from "../../../modules/app-user/app-user";
import MyGroupDetail from "../../../components/portal/MyGroupDetail";
import DashboardNotificationItems from "../../../components/portal/DashboardNotificationItems";

interface PageDashboardSPVProps {
  user: any;
}

export default function PageDashboardSPV(props: PageDashboardSPVProps) {
  const [firstGroup, setFirstGroup] = useState(null);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await AppUser.refreshUser(["avatar"]);
    const user = await AppUser.getInfo();
    if (!user.groupHash || typeof user.groupHash !== "object") return;
    const groupIds = Object.keys(user.groupHash ?? {});
    if (!groupIds.length) return;
    setFirstGroup({
      _id: groupIds[0],
      ...user.groupHash[groupIds[0]],
    });
  };

  return (
    <div className="row" data-comp="PageDashboardSPV">
      <div className="col-5 flex-grow-1 mb-3">
        <div className="d-flex justify-content-between align-items-center header-details">
          <h1 className="fs-title">Notifications</h1>
          <Link to={ROUTES.PORTAL_NOTIFICATION} className="btn">
            View All
          </Link>
        </div>
        <div className="d-flex flex-column gap-3">
          <DashboardNotificationItems />
        </div>
      </div>

      <div>
        <div className="mt-3 d-flex justify-content-between align-items-center header-details">
          <h1 className="fs-title">
            Latest Submission from {firstGroup?.name}
          </h1>
          <Link to={ROUTES.PORTAL_MY_GROUP} className="btn">
            See All
          </Link>
        </div>
        {firstGroup && (
          <MyGroupDetail
            group={firstGroup}
            user={props.user}
            onExit={() => setFirstGroup(null)}
            showOnDashboard
          />
        )}
      </div>
    </div>
  );
}
