import { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';
import { HiOutlineSearch, HiOutlineMenuAlt2, HiChevronDown, HiOutlineFolderAdd, HiOutlineTrash } from "react-icons/hi";
import HeaderTools from '../../../components/portal/HeaderTools';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
// import axios from "axios";
import dummyUsers from '../../../data-dummy';
import { usePortalSettingSet } from '../../../context/portal-context';
import usePageTitle from '../../../hooks/use-page-title';

const nestedMenuItems = [
  {
    label: 'Move to',
    disabled: true
  },
  {
    separator: true
  },
  {
    label: 'Batch 1',
    command: () => console.log('Batch 1 clicked')
  },
  {
    label: 'Batch 2',
    command: () => console.log('Batch 2 clicked')
  },
  {
    label: 'Batch 3',
    command: () => console.log('Batch 3 clicked')
  },
  {
    label: 'Batch 4',
    command: () => console.log('Batch 4 clicked')
  },
  {
    separator: true
  },
  {
    label: '2019',
    command: () => console.log('2019 clicked')
  },
  {
    label: '2020',
    command: () => console.log('2020 clicked')
  },
  {
    label: '2021',
    command: () => console.log('2021 clicked')
  },
  {
    label: '2022',
    command: () => console.log('2022 clicked')
  },
  {
    separator: true
  },
  {
    label: 'Student',
    command: () => console.log('Student clicked')
  },
  {
    label: 'SIT Supervisor',
    command: () => console.log('SIT Supervisor clicked')
  },
  {
    label: 'Industry Supervisor',
    command: () => console.log('Industry Supervisor clicked')
  },
  {
    label: 'Super Admin',
    command: () => console.log('Super Admin clicked')
  }
];

const menuItems = [
  {
    icon: <HiOutlineSearch/>,
    label: 'View Profile',
    command: () => console.log('Item 1 clicked')
  },
  {
    icon: <HiOutlineFolderAdd/>,
    label: 'Manage Account',
    command: () => console.log('Item 2 clicked'),
    items: nestedMenuItems
  },
  {
    icon: <HiOutlineTrash/>,
    label: 'Delete User',
    command: () => console.log('Item 3 clicked')
  }
];

interface SelectableButtonProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const SelectableButton = ({ label, selected, onClick }: SelectableButtonProps) => {
  return (
    <button
      type="button"
      className={`sort-button ${selected ? "btn--primary" : ""}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default function PageUserListOld() {
  const title = "Manage Users";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const [selectedButton, setSelectedButton] = useState("Student");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedContextUser, setSelectedContextUser] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const cm = useRef(null);

  useEffect(() => {
    setLoading(true);
    const filteredUsers = dummyUsers.filter(user => user.account_type === selectedButton);
    const sortedUsers = filteredUsers.sort((a, b) => {
      if (a[selectedSort] < b[selectedSort]) {
        return -1;
      }
      if (a[selectedSort] > b[selectedSort]) {
        return 1;
      }
      return 0;
    });
    setUsers(sortedUsers);
    setLoading(false);
  }, [selectedButton, selectedSort]);

  useEffect(() => {
    const filteredUsers = dummyUsers.filter(user => user.account_type === selectedButton && user.name.toLowerCase().includes(searchQuery.toLowerCase()));
    const sortedUsers = filteredUsers.sort((a, b) => {
      if (a[selectedSort] < b[selectedSort]) {
        return -1;
      }
      if (a[selectedSort] > b[selectedSort]) {
        return 1;
      }
      return 0;
    });
    setUsers(sortedUsers);
  }, [selectedButton, selectedSort, searchQuery]);

  const handleButtonClick = (label: string) => {
    setSelectedButton(label);
  };

  const handleSearchChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const handleSortChange = (selectedSort: string) => {
    setSelectedSort(selectedSort);
  };

  return <main className="content-generic content-generic--fluid" data-comp="PageUserList">
    <div className="row">
      <h1 className='fs-title hide-mobile-down'>{title}</h1>
      <HeaderTools
        selectableButtons={[
          {
            label: "Student",
            selected: selectedButton === "Student",
            onClick: () => handleButtonClick("Student"),
          },
          {
            label: "Industry Supervisor",
            selected: selectedButton === "Industry Supervisor",
            onClick: () => handleButtonClick("Industry Supervisor"),
          },
          {
            label: "SIT Supervisor",
            selected: selectedButton === "SIT Supervisor",
            onClick: () => handleButtonClick("SIT Supervisor"),
          }
        ]}
        onSearchChange={handleSearchChange}
        selectedSort={selectedSort}
        onSortChange={handleSortChange}
        buttonLink="/portal/users/register"
        buttonLinkName="Register New Account"
        register
      />
      <div className="col-12">
        <ContextMenu model={menuItems} ref={cm} onHide={() => setSelectedUser(null)} />
        <DataTable
          value={users}
          loading={loading}
          className="user-list__table"
          emptyMessage="No data found"
          selectionMode='multiple'
          selection={selectedUser}
          onSelectionChange={(e) => setSelectedUser(e.value)}
          dataKey="id"
          height={500}
          onContextMenu={(e) => cm.current.show(e.originalEvent)}
          contextMenuSelection={selectedContextUser}
          onContextMenuSelectionChange={(e) => setSelectedContextUser(e.value)}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
        >
          <Column selectionMode="multiple" style={{ width: "3rem" }} header={<HiChevronDown className='ms-2 fs-4'/>}/>
          <Column field="image" header="" style={{ width: '3rem' }} body={(rowData) => <img src='/assets/images/dummy/profile-picture/male.png' width={40} alt={rowData.name} className="user-list__image rounded-circle" />} />
          <Column field="name" header="Name" />
          <Column field="year" header="Year" />
          <Column field="study_program" header="Programme" bodyClassName='fw-normal'/>
          <Column field="batch" header="Batch" body={(rowData) => <span className='fw-normal'>Batch {rowData.batch}</span>}/>
          <Column field="email" header="Email" bodyClassName='fw-normal'/>
        </DataTable>
      </div>
    </div>
  </main>;
}
