import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "../../hooks/use-auth-user";
import usePageTitle from "../../hooks/use-page-title";
import AppUser from "../../modules/app-user/app-user";
import { ROUTES } from "../../routes";

export default function PageLogout() {
  usePageTitle("Logout");

  const auth = useAuthUser();
  const navigate = useNavigate();
  const loginRoute = auth.user.isAdfsUser
    ? ROUTES.AUTH_LOGIN_ADFS
    : ROUTES.AUTH_LOGIN;

  useEffect(() => {
    if (!auth.isLoading) {
      if (!auth.user.logged) {
        navigate(loginRoute);
      } else {
        AppUser.logout();
      }
    }
  }, [auth]);

  return (
    <>
      <div>
        <h1>Logout</h1>
        <p>You have been logged out successfully.</p>
        <p>
          <Link to={loginRoute}>Click here to log-in back</Link>.
        </p>
      </div>
    </>
  );
}
