import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import { DEFAULT_SELECT_OPTION } from "../../config/constants";

type TOptionItem = {
  label: string;
  value: any;
};

export type TProgrammePagingSearchParam = {
  programAdminId?: string;
  name?: string;
  page?: number;
  perPage?: number;
};

export type TProgrammeCreateParam = {
  name: string;
  programAdminId?: string;
  description?: string;
  createdUserId?: string;
};

export type TProgrammeEditParam = {
  name: string;
  programAdminId?: string;
  description?: string;
  modifiedUserId?: string;
};

const pagingSearch = async (param: TProgrammePagingSearchParam) => {
  const ret = {
    data: [],
    total: 0,
  };

  try {
    const { programAdminId, name, page = 1, perPage = 10 } = param;
    let targetUrl = "/apiprogramme";
    if (programAdminId && programAdminId.trim()) {
      targetUrl += `/byprogramadmin/${programAdminId}`;
    }
    targetUrl += `?page=${page}&perPage=${perPage}`;
    if (name && name.trim()) {
      targetUrl += `&name=${name}`;
    }

    const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
    const res = req.data;

    if (
      !(
        res.result === "OK" &&
        Array.isArray(res.data) &&
        typeof res.totalCount === "number"
      )
    )
      throw new Error();

    ret.data = res.data;
    ret.total = res.totalCount;
  } catch (ignore) {}

  return ret;
};

const getOptions = async () => {
  let options: TOptionItem[] = [DEFAULT_SELECT_OPTION];
  try {
    const req = await axios.get(
      apiUrl(`/apiprogramme/options`),
      await getAuthHeader()
    );
    const responseData = req.data;
    if (req.status === 200 && Array.isArray(responseData.data)) {
      options = options.concat(responseData.data);
    }
  } catch (ignore) {}
  return options;
};

const getById = async (id: string) => {
  try {
    const req = await axios.get(
      apiUrl(`/apiprogramme/${id}`),
      await getAuthHeader()
    );
    return req.data;
  } catch (ignore) {
    throw ignore;
  }
};

const create = async (param: TProgrammeCreateParam) => {
  try {
    const req = await axios.post(
      apiUrl(`/apiprogramme`),
      param,
      await getAuthHeader()
    );
    return req.data;
  } catch (ignore) {
    throw ignore;
  }
};

const update = async (id: string, param: TProgrammeEditParam) => {
  try {
    const req = await axios.patch(
      apiUrl(`/apiprogramme/${id}`),
      param,
      await getAuthHeader()
    );
    return req.data;
  } catch (ignore) {
    throw ignore;
  }
};

const deleteById = async (id: string) => {
  try {
    const req = await axios.delete(
      apiUrl(`/apiprogramme/${id}`),
      await getAuthHeader()
    );
    return req.data;
  } catch (ignore) {
    throw ignore;
  }
};

const deleteByIds = async (ids: Array<string>) => {
  try {
    const req = await axios.delete(apiUrl(`/apiprogramme/bulk`), {
      ...(await getAuthHeader()),
      data: ids,
    });
    return req.data;
  } catch (ignore) {
    throw ignore;
  }
};

const ApiProgramme = {
  pagingSearch,
  getOptions,
  getById,
  create,
  update,
  deleteById,
  deleteByIds,
};

export default ApiProgramme;
