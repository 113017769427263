import { useState, useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";
import AppUserContext from "../../../context/app-user-context";
import { ROUTES } from "../../../routes";
import { MdDelete, MdModeEditOutline, MdSearch } from "react-icons/md";
import { Dropdown } from "react-bootstrap";
import Debouncer from "../../../modules/debouncer/debouncer";
import ServerSideGenericDataTable from "../../../components/common/ServerSideGenericDataTable";
import ApiGenericData from "../../../modules/api/generic-data";
import Notifier from "../../../modules/notifier/notifier";
import ApiGroup from "../../../modules/api/group";
import { HiArrowLeft, HiUserGroup } from "react-icons/hi";
import ModulePopup from "../../../modules/popup/popup";
import { publicDir } from "../../../config/config";
import PagePortalNotFound from "../PagePortalNotFound/PagePortalNotFound";

const ICON_STUDENT = publicDir("/assets/images/icon/icon-student.svg")
const ICON_SUPERVISOR = publicDir("/assets/images/icon/icon-sit-supervisor.svg")
const ICON_INDUSTRY = publicDir("/assets/images/icon/icon-industry-supervisor.svg")

type TMemberCountItemsProps = {
  memberCount: { industry: number; sit: number; student: number; total: number; }
};

function MemberCountItems(props: TMemberCountItemsProps) {
  const memberCount = {
    industry: 0,
    sit: 0,
    student: 0,
    total: 0,
    ...props.memberCount
  };

  return <div className="member-count-items">
    <span title="Number of Student(s)">
      <img width={24} src={ICON_STUDENT} alt="icon student" />
      {" "}{memberCount.student}
    </span>
    <span title="Number of SIT Supervisor(s)">
      <img width={24} src={ICON_SUPERVISOR} alt="icon student" />
      {" "}{memberCount.sit}
    </span>
    <span title="Number of Industry Supervisor(s)">
      <img width={24} src={ICON_INDUSTRY} alt="icon student" />
      {" "}{memberCount.industry}
    </span>
  </div>
}

export default function PageGroupList() {
  const title = "Manage Group";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);
  const location = useLocation();
  const { programmeId, cohortId } = useParams();
  const user = useContext(AppUserContext);
  const isValidProgrammeId = user.programmeIds.includes(programmeId);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);

  const getChildData = (data: any) => {
    setData(data);
  };

  if (
    !isValidProgrammeId ||
    (data &&
      data.cohort &&
      (!data.cohort.programme || data.cohort.programme._id !== programmeId))
  ) {
    return <PagePortalNotFound />;
  }
  return <main className="form-generic content-generic content-generic--fluid" data-comp="PageGroupList">

    <h1 className="fs-title hide-mobile-down">{title}</h1>
    {data && data.cohort && (
      <p>
        Cohort: <strong>{data.cohort.name}</strong>
        <br />
        Programme:{" "}
        <strong>{data.cohort.programme?.name ?? ""}</strong>
      </p>
    )}
    <div className="cg__actions">
      <div className="cg__action-buttons">
        <Link
          to={`${ROUTES.PORTAL_COHORTS}/manage/${programmeId}/${cohortId}`}
          className="btn btn-primary"
          state={location.state}
        >
          <HiArrowLeft /> Back
        </Link>
        <Link to={`${ROUTES.PORTAL_GROUPS}/add/${programmeId}/${cohortId}`} className="btn btn-primary" state={location.state}>
          Add New Group
        </Link>
        {" "}
        <button className="btn btn-outline-primary"
          onClick={() => setRefresh(i => !i)}
        >
          Refresh
        </button>
      </div>
      <div className="cg__action-search">
        <span>
          <MdSearch/>
        </span>
        <input type="text" placeholder="search" onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <ServerSideGenericDataTable
      refresh={refresh}
      api={ApiGroup.pagingSearch}
      cohortId={cohortId}
      search={search}
      columns={[
        {
          id: "name",
          name: "Name",
          cell: (row) => row.name
        },
        {
          id: "countUser",
          name: "Member Count",
          cell: (row) => <>
            <MemberCountItems memberCount={{ ...row.memberCount, total: row.countUser }} />
          </>
        },
        {
          id: "description",
          name: "Description",
          cell: (row) => row.description
        },
        {
          name: "Action",
          button: true,
          cell: (row) => <div>
            <Dropdown align={"end"} drop={"down"}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link className="dropdown-item" to={`${ROUTES.PORTAL_GROUPS}/edit/${programmeId}/${cohortId}/${row._id}`} state={location.state}>
                  <MdModeEditOutline/> {" "} Edit
                </Link>

                <Link className="dropdown-item" to={`${ROUTES.PORTAL_GROUPS}/manage-member/${programmeId}/${cohortId}/${row._id}`} state={location.state}>
                  <HiUserGroup/> {" "} Manage Member(s)
                </Link>

                <Dropdown.Divider />

                <button className="dropdown-item btn-danger" onClick={() => {
                  ModulePopup.showAlertConfirm({
                    title: "Delete Confirmation",
                    description: <>
                      <p>Are you sure to delete this?
                        <br/> This action can't be undone.
                      </p>
                    </>,
                    onYes: () => Debouncer.execute("DELETE_ITEM", async () => {
                      ApiGenericData.remove(row._id).then(res => {
                        if (res.status) {
                          setRefresh(i => !i);
                          Notifier.success(res.message);
                        } else {
                          Notifier.error(res.message);
                        }
                      });
                    })
                  });
                }}>
                  <MdDelete/> {" "} Delete
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      ]}
      getChildData={getChildData}
    />

  </main>;
}
