import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";

export type TCohortPagingSearchParam = {
  programmeId: string;
  name?: string;
  orderBy?: string;
  orderDirection?: string;
  page?: number;
  perPage?: number;
};

export type TCohortCreateParam = {
  name: string;
  startDate: number;
  endDate: number;
  description?: string;
  programme: string;
  timezone?: string;
  createdUserId?: string;
};

export type TCohortEditParam = {
  name: string;
  startDate: number;
  endDate: number;
  description?: string;
  timezone?: string;
  modifiedUserId?: string;
};

const pagingSearch = async (param: TCohortPagingSearchParam) => {
  const ret = {
    data: [],
    total: 0,
    programme: null,
  };

  const {
    programmeId,
    name,
    orderBy = `name`,
    orderDirection = `asc`,
    page = 1,
    perPage = 10,
  } = param;
  let targetUrl = `/apicohort/byprogramme/${programmeId}?name=${encodeURIComponent(
    name
  )}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&perPage=${perPage}`;

  const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
  const res = req.data;

  if (
    !(
      res.result === "OK" &&
      Array.isArray(res.data) &&
      typeof res.totalCount === "number"
    )
  ) {
    throw new Error("Could not get cohort. An error occurred");
  }

  ret.data = res.data;
  ret.total = res.totalCount;
  ret.programme = res.programme;

  return ret;
};

const get = async (id: string, queryProgramme: boolean = false) => {
  const req = await axios.get(
    apiUrl(`/apicohort/${id}?queryProgramme=${queryProgramme}`),
    await getAuthHeader()
  );
  return req.data;
};

const create = async (param: TCohortCreateParam) => {
  const req = await axios.post(
    apiUrl(`/apicohort`),
    param,
    await getAuthHeader()
  );
  return req.data;
};

const update = async (id: string, param: TCohortEditParam) => {
  const req = await axios.patch(
    apiUrl(`/apicohort/${id}`),
    param,
    await getAuthHeader()
  );
  return req.data;
};

const deleteById = async (id: string) => {
  const req = await axios.delete(
    apiUrl(`/apicohort/${id}`),
    await getAuthHeader()
  );
  return req.data;
};

const deleteByIds = async (ids: Array<string>) => {
  const req = await axios.delete(apiUrl(`/apicohort/bulk`), {
    ...(await getAuthHeader()),
    data: ids,
  });
  return req.data;
};

const ApiCohort = {
  pagingSearch,
  get,
  create,
  update,
  deleteById,
  deleteByIds,
};

export default ApiCohort;
