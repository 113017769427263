import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ModulePopup from "../../modules/popup/popup";
import TraitScale from "../portal/TraitScale";
import TraitConfig from "../../config/trait-config";
import { Modal } from "react-bootstrap";
import PDChart from "../portal/PDChart";
import { MONTH_ARR_SHORT } from "../../config/constants";

export type TPopupPreviewTraitRef = {
  open: () => void;
};

export type TPopupPreviewTraitProps = {
  /** JSON config of the trait */
  traitConfig: string;
};

const PopupPreviewTrait = forwardRef<
  TPopupPreviewTraitRef, TPopupPreviewTraitProps
>((props, ref) => {
  const currentConfig = useRef<string>("");
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [traitConfig, setTraitConfig] = useState<any>({});

  useEffect(() => {
    currentConfig.current = props.traitConfig;
  }, [props.traitConfig]);

  useImperativeHandle(ref, () => ({
    open() {
      const validation = TraitConfig.isJSONConfigCorrect(currentConfig.current);

      if (!validation.correct) {
        ModulePopup.showAlert({
          title: "Unable to preview",
          description: <>
            <p>Trait Configuration is not configured properly, please rectify and retry again.</p>
            <p>Additional info: <em>{validation.message}</em></p>
          </>
        });
        return;
      }

      const traitConfig = {
        "config": currentConfig.current,
        "_id": "6450858468076e25d07bb9b8",
        "name": "Test Preview Trait",
        "description": "",
        "parsedConfig": TraitConfig.parse(currentConfig.current)
      };

      setValue("");
      setTraitConfig(traitConfig);
      console.log(traitConfig);
      setShow(true);
    }
  }), []);

  return <>
    <Modal className="modal-generic alert-simple" show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      onHide={() => {
        setShow(false);
      }}

    >
      <Modal.Header closeButton>
        <Modal.Title>Trait Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mw-500px lh-1-5">
          <em>
            This is how the trait's scale looks on student's side when creating or
            editing a report (We can also click each scale to simulate the selection):
          </em>
        </p>
        <TraitScale
          onChange={(e) => setValue(e.target.value)}
          value={value}
          trait={traitConfig}
        />
        <hr />
        <p><em>This is how the trait's chart looks on student's side:</em></p>
        <div className="mw-500px mx-auto">
          <PDChart
            color={traitConfig?.parsedConfig?.color}
            labelX={MONTH_ARR_SHORT.slice(1, 6)}
            scale={[1, 3, 3, 4, 7]}
            scaleNextMonth={[null, 3, 3, 5, 7]}
            goal={[null, null, null, null, 8]}
            goalMaxLine={[null, null, null, null, 10]}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-mw btn-outline-primary" onClick={() => {
          setShow(false);
        }}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  </>;
});

export default PopupPreviewTrait;
