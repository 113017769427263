const convertDataToJson = (data) => {
  const json = {
    color: data.traitColor,
    description: data.traitDescription,
    scales: {},
  };

  data.scalesData.forEach((scale) => {
    const scaleRangeKey = `${scale.scaleRange.min}-${scale.scaleRange.max}`;
    json.scales[scaleRangeKey] = {
      color: scale.scaleColor,
      title: scale.title,
      description: scale.description,
    };
  });

  return JSON.stringify(json, null, 2);
};

const convertJsonToData = (json) => {
  const scalesData = [];
  const scales = json.scales;
  for (const scaleRangeKey in scales) {
    const id = Number((Math.random() * 1000000).toFixed(0));
    const scaleRange = scaleRangeKey.split("-");
    const scaleColor = scales[scaleRangeKey].color;
    const title = scales[scaleRangeKey].title;
    const description = scales[scaleRangeKey].description;
    scalesData.push({
      id,
      scaleRange: {
        min: scaleRange[0],
        max: scaleRange[1],
      },
      scaleColor,
      title,
      description,
    });
  }
  return scalesData;
};

const TraitHelper = {
  convertDataToJson,
  convertJsonToData
};

export default TraitHelper;
