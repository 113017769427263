import ROLE from "../../../modules/role";
import Utils from "../../../utils/utils";
import Notifier from "../../../modules/notifier/notifier";
import Debouncer from "../../../modules/debouncer/debouncer";
import AppUserContext from "../../../context/app-user-context";
import { HiOutlinePlusCircle, HiRefresh } from "react-icons/hi";
import FeedbackItems from "../../../components/portal/FeedbackItems";
import ApiMessage, { TMessageItem } from "../../../modules/api/message";
import { useContext, useMemo, SetStateAction, Dispatch, useRef } from "react";
import PopupSupervisorFeedback, {
  TPopupSupervisorFeedbackRef,
} from "../../../components/popup/PopupSupervisorFeedback";

interface PageProgressTabReportWLProps {
  submission: any;
  messages?: TMessageItem[];
  isRefreshingMessages?: boolean;
  refreshMessages?: Dispatch<SetStateAction<boolean>>;
  setMessages?: Dispatch<SetStateAction<TMessageItem[]>>;
}

const PageProgressTabReportWL: React.FC<PageProgressTabReportWLProps> = ({
  submission,
  messages,
  isRefreshingMessages,
  refreshMessages,
  setMessages,
}) => {
  const user = useContext(AppUserContext);
  const isSupervisor = useMemo(() => user.role === ROLE.SUPERVISOR, [user]);
  const refPopupFeedback = useRef<TPopupSupervisorFeedbackRef>();

  const handleWriteFeedbackClick = (e: any) => {
    e.preventDefault();
    let opts: any;
    const existingMessage = messages.filter(
      (f) => f.senderId === user.referenceUserId
    );
    if (existingMessage.length > 0) {
      opts = {
        messageId: existingMessage[0]._id,
        feedback: existingMessage[0].message,
      };
    }
    refPopupFeedback.current.show(opts);
  };

  const supervisorActions = useMemo(() => {
    if (!user || !refPopupFeedback) return <></>;

    return (
      <div className="tf-actions">
        <button
          className="btn btn-icon btn-secondary"
          onClick={handleWriteFeedbackClick}
        >
          Write Feedback <HiOutlinePlusCircle />
        </button>
      </div>
    );
  }, [refPopupFeedback, user, submission, messages]);

  return (
    <>
      {isSupervisor && (
        <PopupSupervisorFeedback
          ref={refPopupFeedback}
          submission={submission}
          onSubmit={(formData, setDisabled, isDraft) => {
            Debouncer.execute("SUBMIT_FEEDBACK", async () => {
              const isEditMode = formData.messageId !== "";
              setDisabled(true);
              let res: any;

              if (!isEditMode) {
                res = await ApiMessage.submit({
                  createdUserId: user.id,
                  messageType: "SIT SUPERVISOR",
                  message: formData.feedback,
                  traitId: undefined,
                  receiverId: submission.studentId,
                  senderId: user.referenceUserId,
                  status: isDraft ? "Draft" : "Unread",
                  submissionId: submission._id,
                });
              } else {
                res = await ApiMessage.update(formData.messageId, {
                  message: formData.feedback,
                  status: isDraft ? "Draft" : "Unread",
                });
              }

              if (res.status) {
                if (isEditMode) {
                  if (isDraft) {
                    Notifier.success("Feedback saved");
                  } else {
                    Notifier.success("Feedback updated");
                  }

                  setMessages((messages) => {
                    return messages.map((message) => {
                      if (message._id === formData.messageId) {
                        message.message = formData.feedback;
                        message.status = isDraft ? "Draft" : "Unread";
                      }

                      return message;
                    });
                  });
                } else {
                  if (isDraft) {
                    Notifier.success("Feedback saved");
                  } else {
                    Notifier.success("Feedback submitted");
                  }

                  Utils.sleep(1000).then(() => refreshMessages((v) => !v));
                }

                refPopupFeedback.current.hide();
              } else {
                Notifier.error(res.message);
              }

              setDisabled(false);
            });
          }}
        />
      )}

      <div className="report-info-wrap" data-comp="PageProgressTabReportWL">
        <div className="report-info">
          <h1>
            <strong>Summary of Key Activities</strong>
          </h1>
          <div
            className="report-info__content"
            dangerouslySetInnerHTML={{
              __html: submission?.summaryKeyActivities ?? "",
            }}
          ></div>
        </div>

        <hr />

        <div className="report-info">
          <h1>
            <strong>Details of Learning</strong>
          </h1>
          <div
            className="report-info__content"
            dangerouslySetInnerHTML={{
              __html: submission?.detailsLearning ?? "",
            }}
          ></div>
        </div>

        <hr />

        <div className="report-info">
          <h1>
            <strong>Reflection</strong>
          </h1>
          <div
            className="report-info__content"
            dangerouslySetInnerHTML={{
              __html: submission?.reflection ?? "",
            }}
          ></div>
        </div>
      </div>

      {
        <div className="trait-feedback" id={`feedback-${submission._id}`}>
          <h1>Feedback(s)</h1>
          <div className="tf__actions">
            <button
              className="btn btn-primary"
              disabled={isRefreshingMessages}
              onClick={(e) => {
                e.preventDefault();
                refreshMessages((v) => !v);
              }}
            >
              <HiRefresh /> Refresh
            </button>
          </div>
          <div
            className={`feedback-list-wrapper ${
              isRefreshingMessages ? "flw__loading" : ""
            }`}
          >
            <h2>SIT Supervisor</h2>
            {isSupervisor && supervisorActions}
            <FeedbackItems
              messages={messages}
              labelUnavailable="Feedback not available from SIT Supervisor"
              refreshMessages={refreshMessages}
              onClickEdit={handleWriteFeedbackClick}
              hideTraitLabel
            />
          </div>
        </div>
      }
    </>
  );
};

export default PageProgressTabReportWL;
