import { useEffect, useState } from "react";
import MyGroupDetail from "../../../components/portal/MyGroupDetail";
import { useLocation } from "react-router-dom";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import AppUser, { TAppUser } from "../../../modules/app-user/app-user";

export default function PageMyGroup() {
  const location = useLocation();
  const [user, setUser] = useState<TAppUser>();
  const [programmes, setProgrammes] = useState({});
  const [programmeOptions, setProgrammeOptions] = useState([]);
  const [programmeId, setProgrammeId] = useState(``);
  const [cohorts, setCohorts] = useState([]);
  const [cohortOptions, setCohortOptions] = useState([]);
  const [cohortId, setCohortId] = useState(``);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!user || !user.groupHash) return;
    const programmes = getProgrammes();
    setProgrammes(programmes);
  }, [user]);

  useEffect(() => {
    if (!programmes) return;
    var programmeIds = Object.keys(programmes);
    if (programmeIds.length <= 0) return;
    if (
      location.state &&
      location.state.programmeId &&
      programmeIds.includes(location.state.programmeId)
    ) {
      setProgrammeId(location.state.programmeId);
    } else {
      setProgrammeId(programmeIds[0]);
    }
    setProgrammeOptions(
      Object.keys(programmes ?? {}).map((programmeId) => ({
        label: programmes[programmeId].name,
        value: programmeId,
      }))
    );
  }, [programmes]);

  useEffect(() => {
    if (!programmeId) return;
    setCohorts(programmes[programmeId].cohorts);
  }, [programmeId]);

  useEffect(() => {
    const items = Array.isArray(cohorts) ? cohorts : [];
    if (items.some((c) => c._id === group?.cohort?._id)) {
      setCohortId(group.cohort._id);
    } else if (items.some((c) => c._id === location.state?.cohortId)) {
      setCohortId(location.state.cohortId);
    } else if (items.length > 0) {
      setCohortId(items[0]._id);
    }
    setCohortOptions(
      items.map((c) => ({
        label: c.name,
        value: c._id,
      }))
    );
  }, [cohorts]);

  useEffect(() => {
    if (!cohortId) return;
    const groups = [];
    const groupsByCohort = [];
    const groupHash = user?.groupHash;
    Object.keys(groupHash ?? {}).forEach((groupId) => {
      const group = groupHash[groupId];
      if (!group.cohort) return;
      groups.push({
        _id: groupId,
        ...group,
      });
      if (group.cohort._id === cohortId) {
        groupsByCohort.push({
          _id: groupId,
          ...group,
        });
      }
    });
    setGroups(groupsByCohort);
    if (group?.cohort?._id === cohortId) return;
    if (location.state?.groupId) {
      const selectedGroup =
        groupsByCohort.find((g) => g._id === location.state.groupId) ??
        groups.find((g) => g._id === location.state.groupId);
      if (selectedGroup) {
        setGroup(selectedGroup);
      }
    }
  }, [cohortId]);

  useEffect(() => {
    if (!group?.cohort?._id || !group?.cohort?.programme?._id) return;
    if (programmeId !== group.cohort.programme._id) {
      setProgrammeId(group.cohort.programme._id);
    } else if (cohortId !== group.cohort._id) {
      setCohortId(group.cohort._id);
    }
  }, [group]);

  const init = async () => {
    await AppUser.refreshUser(["avatar"]);
    const user = await AppUser.getInfo();
    setUser(user);
  };

  const getProgrammes = () => {
    const programmes = {};
    if (!user || !user.groupHash) {
      return programmes;
    }
    Object.keys(user.groupHash).forEach((group) => {
      var programme = user.groupHash[group]?.cohort?.programme;
      if (!programme) {
        return;
      }
      const cohort = { ...user.groupHash[group].cohort };
      delete cohort.programme;
      if (programmes[programme._id]) {
        if (!cohort) {
          return;
        }
        if (
          !programmes[programme._id].cohorts.some((c) => c._id === cohort._id)
        ) {
          programmes[programme._id].cohorts.push(cohort);
        }
      } else {
        programmes[programme._id] = {
          name: programme.name,
          cohorts: cohort ? [cohort] : [],
        };
      }
    });

    return programmes;
  };

  return !group ? (
    <main
      className="content-generic content-generic--fluid"
      data-comp="MyGroup"
    >
      <div className="row g-3 row-cols-xl-5 m-0">
        <div className="col-md-6 m-0 filter-group-control">
          <FGDropdownApi
            options={programmeOptions}
            value={programmeId}
            defaultValue={programmeId}
            onChange={(e: any) => {
              location.state = null;
              setProgrammeId(e.target.value);
            }}
            className="m-0"
          />
        </div>
        <div className="col-md-6 m-0 filter-group-control">
          <FGDropdownApi
            options={cohortOptions}
            value={cohortId}
            defaultValue={cohortId}
            onChange={(e: any) => {
              location.state = null;
              setCohortId(e.target.value);
            }}
            className="m-0"
          />
        </div>
      </div>
      <div className="row g-3 row-cols-1 row-cols-xl-2 py-1 m-0">
        {groups.map((g, i) => (
          <div className="col" key={i}>
            <div className="d-flex bg-white p-3 my-group-item align-items-center">
              <div className="flex-grow-1">{g.name}</div>
              <div
                className="btn btn-primary text-nowrap px-4 py-2"
                onClick={() => setGroup(g)}
              >
                View Details
              </div>
            </div>
          </div>
        ))}
      </div>
    </main>
  ) : (
    <MyGroupDetail
      programmeId={programmeId}
      cohortId={cohortId}
      group={group}
      user={user}
      onExit={() => {
        location.state = null;
        setGroup(null);
      }}
    />
  );
}
