
import { template, isPlainObject } from 'lodash-es';
import Config from '../config/config';
import { Strings } from './strings';

const trim = Strings.trimString;
const baseUrl = Config.BASE_URL;

export namespace Generics {

  /**
   * This function is based on `lodash.template` -- Creating HTML template from `htmlString` based on supplied `data` object.
   *
   * E.g. calling:
   * `createTemplate('<h1>My name is <%= name %></h1>', { name: 'Faisal' })`
   *
   * It will produce:
   * `<h1>My name is Faisal</h1>`
   *
   * @param {String} htmlString
   * @param {Object} data
   * @returns Compiled HTML string
   */
  export function createTemplate(htmlString = '', data = {}) {
    return createTemplateCache(htmlString)(data);
  }

  export function createTemplateCache(htmlString) {
    let temp = template(htmlString, {});

    return function (data) {
      return temp(Object.assign({
        baseUrl
      }, data))
    };
  }

  export function toId(str: string) {
    return str
      .trim()
      .toLowerCase()
      .replace(/[^0-9a-z\-\s]*/gm, "")
      .replace(/\s*\-+\s*/gm, "-")
      .replace(/\s+/gm, "-");
  }

  export function pad(str: string | number, char: string = '0', maxChars: number = 2): string {
    let type = typeof str;

    if (type != 'string' && type != 'number') {
      return '';
    }

    if (typeof str == 'number') {
      str = str.toString();
    }

    let len = str.length;
    let remaining = maxChars - len;

    while (remaining > 0) {
      str = char + str;
      remaining -= 1;
    }

    return str;
  }

  export function ifEmptyOrUndefinedThen(value: any, ifEmpty: string = '') {
    let type = typeof value;

    return type === 'undefined' || (type === 'string' && value.trim() === '')
      ? ifEmpty
      : value;
  }

  export function ifNotStringThen(value: any, ifNotString: any = '') {
    return typeof value != 'string' ? ifNotString : value;
  }

  export function ifUndefinedThen(value: any, ifUndefined: any) {
    return typeof value == 'undefined' ? ifUndefined : value;
  }

  export function loadImage(src: string): Promise<string> {
    return new Promise(function (resolve, reject) {
      let x = document.createElement('img') as any;
      x.onerror = function () {
        x = null;
        resolve('');
      }
      x.onload = function () {
        x = null;
        resolve(src);
      }
      x.src = src;
    });
  }

  /**
   * @param ms {number} in milliseconds
   * @returns
   */
  export function sleep(ms: number): Promise<void> {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  /**
   * @param callback {Function} to be executed on timeout's execution
   * @param ms {number} in milliseconds
   * @returns
   */
  export function timeoutPromise(callback: Function, ms: number): Promise<void> {
    return new Promise(function (resolve, reject) {
      setTimeout(() => {
        callback();
        resolve();
      }, ms);
    });
  }

  export function valueBetween(number: any, from: number, to: number) {
    // try to convert first
    number = parseInt(number, 10);

    return typeof number == 'number'
      ? number >= from && number <= to
      : false;
  }

  export function isEmptyString(str: any) {
    return typeof str == 'string' ? str.length === 0 : true;
  }

  export function nl2br(str: string = '') {
    return str.replace(/\r?\n/g, '<br>');
  }

  export function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  export interface IUtilWindowSize {
    width: number;
    height: number;
  }

  // @credit https://stackoverflow.com/a/11744120
  export function getWindowSize(): IUtilWindowSize {
    let win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName('body')[0],
      x = win.innerWidth || docElem.clientWidth || body.clientWidth,
      y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;

    return {
      width: x,
      height: y
    };
  }

  // @credit https://stackoverflow.com/a/9310752
  export function escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  export function parseJson(jsonString: any, defaultReturnValue = {}) {
    let ret = defaultReturnValue;
    let typ = typeof jsonString;

    if (typ == 'undefined') {
      return ret;
    }

    // assume that it's object already
    if (typ == 'object') {
      return jsonString;
    }

    try {
      ret = JSON.parse(jsonString);
    } catch(ignore) {}

    return ret;
  }

  interface ILoopOptions {
    array?: undefined|Array<any>;
    object?: undefined|Object;
    from?: number;
    to?: number;
  }

  export function loop(userOptions: ILoopOptions, userCallback?: Function) {
    let options: ILoopOptions = Object.assign({
      array: undefined,
      object: undefined,
      from: 0,
      to: 0
    }, userOptions);
    let from = options.from;
    let to = options.to;
    let callback = typeof userCallback == 'function' ? userCallback : function () {};
    let isArray =  Array.isArray(options.array);
    let isObject = isPlainObject(options.object);

    if (isArray) {
      options.array.forEach(v => callback(v));
    } else if (isObject) {
      Object.keys(options.object).forEach(key => {
        callback(options.object[key]);
      });
    } else {
      if (from == 0 && to != 0) {
        from = 1;
      }

      if (from > to) {
        to = from;
      }

      for (; from <= to; from += 1) {
        callback(from);
      }
    }
  }

  export function ucwords(str) {
    return str.replace(/[a-z]/g, function (matchedChar, position) {
      return (position == 0 || /\s/.test(str[position - 1])) ? matchedChar.toUpperCase() : matchedChar;
    });
  }

  export function isMatch(str, arrValue) {
    let satisfied = false;
    let lowercasedStr = trim(str).toLowerCase();

    arrValue.forEac(v => {
      if (trim(v).toLowerCase() == lowercasedStr) {
        satisfied = true;
      }
    });

    return satisfied;
  }

  /**
   * @param {string} link
   * @param {string} baseUrl - "https://www.fxmweb.com"
   * @example
   * //we are here
   * "http://localhost/index.html"
   *
   * redirect('/sub-page.html', 'http://localhost/)
   *
   * //now we are move to
   * "http://localhost/sub-page.html"
   *
   * another Example
   *
   * //we are here
   * "http://127.0.0.1:5500/tests/index.html"
   *
   * redirect('/#about', 'https://fxmweb.com/');
   *
   * //now we are direct to the page
   * "https://fxmweb.com/#about"
   */
  export function redirect(link = '', baseUrl = '') {
    if (link == '' || link == '#' || /^javascript/.test(link)) {
      return;
    }

    if (/^\//.test(link) && !isEmptyString(baseUrl)) {
      link = baseUrl + link.substring(1);
    }

    window.location.href = link;
  }

  export function timeToSeconds(time) {
    if (isFinite(time)) {
      return time;
    }

    let isTime = /^(\d+:)?\d{1,2}:\d{1,2}$/;
    let times = time.replace(/^\s+|\s+$/g, "").split(":");

    if (!isTime.test(time) || (times.length != 3 && times.length != 2)) {
      return 0;
    }

    return times.length == 3 ?
      (~~times[0] * 3600) + (~~times[1] * 60) + ~~times[2] :
      (~~times[0] * 60) + ~~times[1];
  }

  /**
   * @description
   * this will convert minutes to hours
   * @param {number} numbers minutes
   *
   * @example
   * intToTime(60);
   * //will return ==> 01:00
   *
   * intToTime(120);
   * //will return ==> 02:00
   *
   */
  export function intToTime(numbers): string {
    let value = Math.round(numbers);
    let minutes = Math.floor(value / 60);
    let seconds = value % 60;
    let result = "";
    let padMe = (v) => pad(v, '0', 2);

    if (minutes > 59) {
      let hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      result = padMe(hours) + ":" + padMe(minutes) + ":" + padMe(seconds);
    } else {
      result = padMe(minutes) + ":" + padMe(seconds);
    }

    return result;
  }

  export async function promisify(callback: (fn: Function) => void) {
    return new Promise((res, rej) => {
      try {
        callback(res);
      } catch (err) {
        rej(err);
      }
    });
  }

  export function throttle(interval = 100, enableAutoExecution: boolean = true) {
    let prevExecutionTime = 0;
    let timeout;
    let latestCallback = () => {};

    return (callback: () => void) => {
      let currTime = Date.now();

      if (prevExecutionTime == 0) {
        prevExecutionTime = currTime;
      }

      let remaining = currTime - prevExecutionTime;
      latestCallback = callback;

      clearTimeout(timeout);

      if (remaining === 0 || remaining > interval) {
        prevExecutionTime = currTime;
        latestCallback();
        return;
      }

      if (enableAutoExecution) {
        let nextTimeout = interval - remaining;
        timeout = setTimeout(() => {
          prevExecutionTime = currTime + nextTimeout;
          latestCallback();
        }, nextTimeout);
      }
    };
  }

  export function getUrlPathAndParams() {
    const loc = window.location;
    return loc.href.replace(loc.origin, "");
  }

  export function ellipsis(str: string, maxChars = 100) {
    if (typeof str !== "string") {
      return "";
    }

    let decrement = 0;

    if (/[.!?]$/.test(str)) {
      decrement += 1;
    }

    if ((str.length - decrement) > maxChars) {
      str = str.substring(0, maxChars).trim().replace(/[,]$/, "") + "...";
    }

    return str;
  }

  export function sortByObjectPropNatural(prop) {
    return (a, b) => {
      return a[prop].localeCompare(b[prop], undefined, {
        numeric: true,
        sensitivity: "base"
      });
    };
  }

  export function sortByObjectPropNumber(prop) {
    return (rowA, rowB) => {
      const a = rowA[prop];
      const b = rowB[prop];

      if (a > b) {
          return 1;
      }

      if (b > a) {
          return -1;
      }

      return 0;
    };
  }

  export function getBase64FromFile(file: File): Promise<string> {
    return new Promise((res, rej) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          res(reader.result as string);
        }
        reader.onerror = function () {
          res("");
        };
      } catch (ignore) {
        res("");
      }
    });
  }

  export function html2Text(htmlString: string) {
    let $div = $(`<div></div>`);
    $div.html(htmlString);
    let text = $div.text().trim();
    $div.remove();
    $div = null;
    return text;
  }

}
