import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import ApiGenericData, { TGenericItem } from "./generic-data";

export type TGroupItem = TGenericItem & {
  /** how many user(s) in each group? */
  countUser: number;
};

async function getUsersByGroupId(_id: string): Promise<any[]> {
  let data = [];

  try {
    const req = await axios.get(apiUrl(`/apiuser/queryByGroupId/${_id}`), await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Error getting list of users");
    data = req.data.data;
  } catch (ignore) {};

  return data;
}

async function getAllGroups() {
  let data = [];
  
  try {
    const req = await axios.get(apiUrl(`/apigenericdata/queryall/Group`), await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Error getting list of groups");
    data = req.data.data;
  } catch (ignore) {};

  return data;
}

async function removeMember(groupId, commaSeparatedUserIds) {
  try {
    const req = await axios.patch(apiUrl(`/apiuser/unassigngroup/${groupId}`), {
      userIds: commaSeparatedUserIds
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to remove from group's member");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function addMember(groupId, commaSeparatedUserIds) {
  try {
    const req = await axios.patch(apiUrl(`/apiuser/assigngroup/${groupId}`), {
      userIds: commaSeparatedUserIds
    }, await getAuthHeader());
    return {
      status: req.data.result === "OK" ? true : false,
      message: req.data.message,
      errors: Array.isArray(req.data.errors) ? req.data.errors : [],
    };
  } catch (err) {
    return {
      status: false,
      message: err.message,
      errors: [],
    };
  }
}

const ApiGroup = {
  getUsersByGroupId,
  getAllGroups,
  pagingSearch: ApiGenericData.pagingSearch("Group"),
  add: ApiGenericData.add<Omit<TGroupItem, "_id" | "countUser">>("Group"),
  get: ApiGenericData.get,
  update: ApiGenericData.update<Omit<TGroupItem, "_id" | "countUser">>("Group"),
  removeMember,
  addMember
};

export default ApiGroup;
