import { isDebugMode } from "../config/config";
import { LABEL_UNKNOWN_TRAIT, MONTH_ARR_SHORT } from "../config/constants";
import { TRawStatisticResult, TStatisticItem } from "./api/submission";
import ApiTrait, { TGetAllTraitItem } from "./api/trait";

/**
 * if `range`: We use `RANGE_FROM` and `RANGE_TO`. submission that is not within
 *             range will be ignored.
 * if `max_months`: We use first submission date to `MAX_MONTHS
 */
type CONVERT_MODE = "range" | "max_months";

const MAX_MONTHS = 12;
const RANGE_FROM = new Date("2023-06-15T00:00:00+0800");
const RANGE_TO = new Date("2024-06-15T00:00:00+0800");

const MicroBench = {
  startDate: -1,
  start() {
    if (!isDebugMode) return;
    this.startDate = Date.now();
  },
  stop() {
    if (!isDebugMode) return;
    console.log(`Statistic converter takes: ${Date.now() - this.startDate}ms`);
  },
};

/**
 * @param index
 * @param trait
 * @param monthName
 * @param year
 */
function inject(index: number, trait: any, monthName: string) {
  trait.goalHistory.splice(index, 0, null);
  trait.monthSubmitted.splice(index, 0, false);
  trait.scale.splice(index, 0, null);
  trait.scaleEndGoal.splice(index, 0, null);
  trait.scaleNextMonth.splice(index, 0, null);

  trait.month.splice(index, 0, monthName);
}

function getMonthName(submissionDate: any) {
  const month = MONTH_ARR_SHORT[submissionDate.month];
  const monthName = `${month} ${submissionDate.year}`;
  return monthName;
}

function getNextMonthName(monthName: string) {
  const parts = monthName.split(" ");
  const month = parts[0];
  const year = parseInt(parts[1]);
  const shortMonths = [...MONTH_ARR_SHORT];
  shortMonths.shift();
  const index = shortMonths.findIndex((m) => m === month);
  const nextIndex = index === shortMonths.length - 1 ? 0 : index + 1;

  const nextMonth = shortMonths[nextIndex];
  const nextYear = index === shortMonths.length - 1 ? year + 1 : year;
  return `${nextMonth} ${nextYear}`;
}

function getCohortMonthNames(cohort: any) {
  let months = [];

  let startDate = new Date(cohort.startDate);
  let endDate = new Date(cohort.endDate);
  let date = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

  while (date <= endDate) {
    months.push(
      date.toLocaleString("default", { month: "short", year: "numeric" })
    );
    date.setMonth(date.getMonth() + 1);
  }

  let endDateValue = endDate.toLocaleString("default", {
    month: "short",
    year: "numeric",
  });

  if (endDateValue !== months[months.length - 1]) {
    months.push(endDateValue);
  }
  return months;
}

export default async function SubmissionStatisticConverter(
  submissions: TStatisticItem[],
  cohort: any,
  mode: CONVERT_MODE = "max_months"
): Promise<TRawStatisticResult> {
  // don't remove this
  MicroBench.start();
  const ret: TRawStatisticResult = {
    parsedTraits: [],
  };
  if (!cohort || !cohort._id || !cohort._id.trim()) {
    return ret;
  }
  // process nothing when no submissions exists
  if (!submissions.length) {
    return ret;
  }
  const monthNames = getCohortMonthNames(cohort);
  const traits = {};

  const traitsInfoData = await ApiTrait.getAll(cohort._id);
  const traitsInfo = (() => {
    const data = traitsInfoData.reduce((prev, current) => {
      return {
        ...prev,
        [current._id]: current,
      };
    }, {}) as { [key: string]: TGetAllTraitItem };

    return {
      getLabel(traitId: string) {
        const label = data[traitId]?.name;
        return typeof label === "string" ? label : LABEL_UNKNOWN_TRAIT;
      },
      getColor(traitId: string) {
        const label = data[traitId]?.parsedConfig.color;
        return typeof label === "string" ? label : "";
      },
    };
  })();

  submissions.forEach((submission) => {
    const monthName = getMonthName(submission.submissionDate);

    Array.isArray(submission.traits) &&
      submission.traits.forEach((itemTrait) => {
        // skip the trait if current submission for this specific trait is paused
        if (itemTrait.paused) return;

        const {
          traitId,
          scale,
          scaleEndGoal,
          scaleNextMonth,
          scaleEndGoalChangeReason,
        } = itemTrait;
        const trait = traits[traitId] ?? {};

        trait.traitId = traitId;

        if (typeof trait.name != "string")
          trait.name = traitsInfo.getLabel(traitId);
        if (typeof trait.color != "string")
          trait.color = traitsInfo.getColor(traitId);
        if (typeof trait.goal != "number") trait.goal = 0;
        if (typeof trait.goalChangeReason != "string")
          trait.goalChangeReason = "";

        if (!Array.isArray(trait.monthSubmitted)) trait.monthSubmitted = [];
        if (!Array.isArray(trait.month)) trait.month = [];
        if (!Array.isArray(trait.goalHistory)) trait.goalHistory = [];
        if (!Array.isArray(trait.scale)) trait.scale = [];
        if (!Array.isArray(trait.scaleNextMonth)) trait.scaleNextMonth = [];
        if (!Array.isArray(trait.scaleEndGoal)) trait.scaleEndGoal = [];

        if (scaleEndGoal > 0) {
          trait.goal = scaleEndGoal;
          trait.goalChangeReason = scaleEndGoalChangeReason;
        }

        trait.monthSubmitted.push(true);
        trait.month.push(monthName);
        trait.scale.push(scale);
        trait.scaleNextMonth.push(scaleNextMonth);
        trait.scaleEndGoal.push(null);
        trait.goalHistory.push(scaleEndGoal);

        traits[traitId] = trait;
      });
  });

  const markNext = (() => {
    let cb;

    return {
      store(_cb) {
        cb = _cb;
      },
      execute() {
        if (typeof cb === "function") {
          cb();
        }

        cb = undefined;
      },
    };
  })();

  function fillMissing(trait: any) {
    const months = trait.month;

    if (!Array.isArray(months)) {
      return;
    }

    // stop the recursive call once `MAX_MONTHS` reached
    if (months.length === 0 || months.length >= monthNames.length) {
      return;
    }

    const firstMonthName = monthNames[0];

    if (trait.month[0] !== firstMonthName) {
      inject(0, trait, firstMonthName);
      fillMissing(trait);
      return;
    }

    let from = 0;
    let to = trait.month.length;

    if (monthNames.length > to) {
      to = monthNames.length;
    }

    for (; from < to - 1; from += 1) {
      const currMonthName = trait.month[from];
      const nextMonthName = trait.month[from + 1];

      const next = getNextMonthName(currMonthName);

      if (nextMonthName !== next) {
        inject(from + 1, trait, next);
        fillMissing(trait);
        break;
      }
    }
  }

  ret.parsedTraits = Object.keys(traits).map((traitId) => {
    const trait = traits[traitId];

    // shift the "next month scale"
    if (
      Array.isArray(trait.scaleNextMonth) &&
      trait.scaleNextMonth.length > 0
    ) {
      trait.scaleNextMonth.unshift(null);
      trait.scaleNextMonth.pop();
    }

    // console.log(`BeforeConvert(${trait.name})`, JSON.parse(JSON.stringify(trait)));

    // fill-in missing info
    fillMissing(trait);

    // set last index to `trait.goal`
    if (
      trait.goal >= 0 &&
      Array.isArray(trait.scaleEndGoal) &&
      trait.scaleEndGoal.length > 0
    ) {
      trait.scaleEndGoal[trait.scaleEndGoal.length - 1] = trait.goal;
      trait.goalMaxLine = Array.from(trait.scaleEndGoal);
      trait.goalMaxLine[trait.goalMaxLine.length - 1] = 10; // 10 is maximum score for Trait
    }

    // console.log(`AfterConvert(${trait.name})`, JSON.parse(JSON.stringify(trait)));

    return trait;
  });

  MicroBench.stop();
  return ret;
}
